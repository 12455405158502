import { Dialog, DialogContent } from '@material-ui/core';

import React from 'react';

function ModalForm({ open, setOpen, formikComponent: FormikComponent, oldValues, title, ...rest }) {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      {...rest}
    >
      <div
        style={{
          fontSize: '20px',
          color: '#3E3F42',
          lineHeight: '1.125rem',
          fontWeight: '500',
          padding: 20,
        }}
      >
        <h2>{title}</h2>
        <DialogContent>
          <FormikComponent oldValues={oldValues} setOpen={setOpen} />
        </DialogContent>
      </div>
    </Dialog>
  );
}
export default ModalForm;
