export const getClickableLink = link => {
  return link ? (link.startsWith('http://') || link.startsWith('https://') ? link : `http://${link}`) : '';
};

export function getFormattedDate(unixTimestamp) {
  const dateObject = new Date(unixTimestamp * 1);
  return dateObject.toLocaleString('tr-TR').slice(0, -3); //remove seconds
}

export function fileChangedHandler(input, photoSetter, fileSetter) {
  if (input.target.files[0]?.type?.split('/')[0] !== 'image') {
    alert('Lütfen geçerli bir resim yükleyin');
    return;
  }
  if (input.target.files[0]?.size > 5242880) {
    alert("Resim Boyutu 5mb'ı geçemez");
    return;
  }

  const reader = new FileReader();

  reader.onloadend = function(e) {
    photoSetter(reader.result);
  };
  if (fileSetter && input.target.files[0]) {
    reader.readAsDataURL(input.target.files[0]);
    fileSetter(input.target.files[0]);
  }
}

export function fileChangedHandlerArray(input, photoArray, photoArraySetter, fileArray, fileArraySetter) {
  if (input.target.files[0]?.type?.split('/')[0] !== 'image') {
    alert('Lütfen geçerli bir resim yükleyin');
    return;
  }
  if (input.target.files[0]?.size > 5242880) {
    alert("Resim Boyutu 5mb'ı geçemez");
    return;
  }

  const reader = new FileReader();

  reader.onloadend = function(e) {
    photoArraySetter([...photoArray, reader.result]);
  };

  if (fileArraySetter && input.target.files[0]) {
    reader.readAsDataURL(input.target.files[0]);
    fileArraySetter([...fileArray, input.target.files[0]]);
  }
}

export function getTrNameFromSlug(slug) {
  if (new RegExp(/\d/).test(slug)) {
    return 'Profil';
  }

  switch (slug) {
    case '/users':
      return 'Kullanıcılar';
    case '/mentor':
      return 'Mentörler';
    case '/groups':
      return 'Gruplar';
    case '/jobs':
      return 'İşler';
    case '/calendar':
      return 'Takvim';
    case '/panel':
      return 'Panel';
    case '/adminUsers':
      return 'Kullanıcılar';
    default:
      return 'Anasayfa';
  }
}
