import * as React from 'react';

import { Link, NavLink, withRouter } from 'react-router-dom';
import { UikNavLink, UikNavPanel, UikNavSection, UikNavSectionTitle } from '@uik';

import menuFavourites from '../Header/assets/menuFavourites';
import menuLinks from '../Header/assets/menuLinks';
import { useTranslation } from 'react-i18next';

const SocialNavigation = props => {
  const { t, i18n } = useTranslation();
  return (
    <UikNavPanel>
      <UikNavSection>
        <UikNavSectionTitle>Menü</UikNavSectionTitle>
        {menuLinks.map(({ count, ...link }) => (
          <UikNavLink
            key={link.text}
            onClick={() => props.setActiveContent('Home')}
            Component={NavLink}
            highlighted
            rightEl={count ? <span>{count}</span> : null}
            {...link}
            to={link.to}
          >
            {t(`${link.text}`)}
          </UikNavLink>
        ))}
      </UikNavSection>
      <UikNavSection>
        <UikNavSectionTitle>{t('Panel')}</UikNavSectionTitle>
        {menuFavourites.map(({ count, ...link }) => (
          <UikNavLink to={link.to} key={link.text} Component={Link} highlighted {...link}>
            {t(link.text)}
          </UikNavLink>
        ))}
      </UikNavSection>
    </UikNavPanel>
  );
};

export default withRouter(SocialNavigation);
