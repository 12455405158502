import { Dialog, DialogActions, DialogContent } from '@material-ui/core';

import ModifiableSnackbar from './ModifiableSnackbar';
import { UikButton } from '@uik';
import { useState } from 'react';

function CriticalMutation({ title, text, mutation, options, closeComponent, children, ...rest }) {
  const [open, setOpen] = useState(true);

  async function handleMutation() {
    if (!mutation) return;

    const { data, error } = await mutation(options || {});
    if (error) {
      console.error(error);
      setOpen(false);
    } else {
      setOpen(false);
    }

    if (closeComponent) closeComponent();
  }

  //handlers
  function handleExit() {
    setOpen(false);
    if (closeComponent) closeComponent();
  }

  return (
    <Dialog
      open={open}
      onClose={handleExit}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      {...rest}
    >
      <div
        style={{
          fontSize: '20px',
          color: '#3E3F42',
          lineHeight: '1.125rem',
          fontWeight: '500',
          padding: 20,
        }}
      >
        <h2>{title}</h2>
        <DialogContent>
          {children ? children : <h3>{text}</h3>}
          <DialogActions>
            <UikButton error onClick={handleMutation}>
              {'Evet'}
            </UikButton>
            <UikButton onClick={handleExit}>{'Vazgeç'}</UikButton>
          </DialogActions>
        </DialogContent>
      </div>
    </Dialog>
  );
}

export default CriticalMutation;
