import { gql } from '@apollo/client';

//-Auth
export const CHECK_ID_WITH_TOKEN_QUERY = gql`
  query checkIdWithToken {
    checkToken {
      id
      profilePhoto
    }
  }
`;

//-User Detail
//--about
export const USER_DETAIL_MY_PROFILE_QUERY = gql`
  query UserDetailAbout {
    myProfile {
      id
      firstName
      lastName
      phoneNumber
      city {
        id
        title
      }
      country {
        id
        title
      }
      career {
        company
        jobTitle
        startDate
        endDate
        isPresent
      }

      profilePhoto
      thumbnail
      companyLogo
      website
      socialLinkedIn

      getFriendRequests {
        id
        firstName
        lastName
        profilePhoto
      }
    }
  }
`;

export const GET_FRIENDSHIP_STATUS = gql`
  query getFriendshipStatus($friendId: ID!) {
    getFriendshipStatus(friendId: $friendId)
  }
`;
export const USER_DETAIL_PUBLIC_PROFILE_QUERY = gql`
  query getUserPublicProfile($id: ID!) {
    getUserPublicProfile(id: $id) {
      id
      firstName
      lastName
      profilePhoto
      thumbnail
      companyLogo
      socialLinkedIn
      biography
      website
      country {
        id
        title
      }
      city {
        id
        title
      }
      career {
        company
        jobTitle
        startDate
        endDate
        isPresent
      }
    }
  }
`;
//--groups
export const USER_DETAIL_PUBLIC_GROUPS_QUERY = gql`
  query getUserPublicProfile($id: ID!) {
    getUserPublicProfile(id: $id) {
      id
      getGroups {
        id
        title
        profilePhoto
      }
    }
  }
`;
export const USER_DETAIL_MANAGED_GROUPS_QUERY = gql`
  query UserDetailAbout {
    myProfile {
      id
      managedGroups {
        id
        title
        profilePhoto
      }
      getGroups {
        id
        title
      }
    }
  }
`;
//--friends
export const GET_FRIENDS_QUERY = gql`
  query getFriends($id: ID!) {
    getFriends(userId: $id) {
      id
      firstName
      lastName
      profilePhoto
    }
  }
`;

//-GroupList
export const ALL_GROUPS_QUERY = gql`
  query allGroups($filterTitle: String!) {
    allGroups(filterTitle: $filterTitle) {
      id
      isPublic
      title
      description
      category
      profilePhoto
      getMembers {
        id
      }
    }
  }
`;

//-Group Category
export const GET_JOBS_INDUSTRY = gql`
  query filterJobsByIndustry($industry: String!) {
    filterJobsByIndustry(industry: $industry) {
      id
      title
      industry
      companyName
      logo
      createdAt
    }
  }
`;

export const GET_GROUP_CATEGORY = gql`
  query filterGroupsByCategory($category: String!) {
    filterGroupsByCategory(category: $category) {
      id
      isPublic
      title
      description
      profilePhoto
      thumbnail
      category
      getMembers {
        id
      }
    }
  }
`;

export const GROUP_DETAIL_QUERY = gql`
  query getGroupProfile($id: ID!) {
    getGroupProfile(id: $id) {
      id
      title
      description
      company
      phoneNumber
      profilePhoto
      thumbnail
      website
      isPublic
      admin {
        id
        firstName
        lastName
      }
      getMembers {
        id
        firstName
        lastName
        profilePhoto
      }
      getPendingMembers {
        id
        firstName
        lastName
        profilePhoto
      }
    }
  }
`;
//-GroupDetail
export const IS_USER_JOINED_QUERY = gql`
  query isUserJoined($groupId: ID!) {
    isUserJoined(groupId: $groupId)
  }
`;

//-Users
export const ALL_USERS_QUERY = gql`
  query allUsers($filterQuery: String!) {
    allUsers(filterQuery: $filterQuery) {
      id
      firstName
      lastName
      profilePhoto
    }
  }
`;

//-JobList
export const ALL_JOBS_QUERY = gql`
  query allJobs($filterTitle: String!) {
    allJobs(filterTitle: $filterTitle) {
      id
      title
      description
      thumbnail
      companyName
      logo
      createdAt
      startDate
      endDate
    }
  }
`;

//-JobDetail
export const GET_JOB_DETAİL = gql`
  query getJobDetail($id: ID!) {
    getJobDetail(id: $id) {
      id
      applyWithEmail
      applyWithWeb
      companyName
      city {
        id
        title
      }
      country {
        id
        title
      }
      description
      endDate
      industry
      level
      logo
      startDate
      title
      workType
      type
    }
  }
`;

//Feed
export const GET_PERSONAL_FEED = gql`
  query getPersonalFeed($limit: Int) {
    getPersonalFeed(limit: $limit) {
      id
      content
      images
      likes {
        id
      }
      user {
        id
        firstName
        lastName
        profilePhoto
        career {
          jobTitle
          company
        }
      }
      isJobPost
      jobPost {
        id
        title
        companyName
        logo
      }
      postedAt
    }
  }
`;

export const GET_UPDATE_WITH_LIKES_AND_COMMENTS = gql`
  query getLikesAndComments($id: ID!) {
    getLikesAndComments(id: $id) {
      likes {
        id
        user {
          id
          firstName
          lastName
          profilePhoto
        }
      }
      updates {
        id
        content
        user {
          id
          firstName
          lastName
          profilePhoto
        }
        postedAt
        images
      }
    }
  }
`;
