import { Field, Form, Formik } from 'formik';
import { UikAvatar, UikButton, UikContentTitle, UikFormInputGroup, UikInput, UikWidgetContent, Uikon } from '@uik';

import { EDIT_GROUP_MUTATION } from '../../graphql/mutations';
import { GROUP_DETAIL_QUERY } from '../../graphql/queries';
import cls from './forms.module.scss';
import { editGroupFormYup } from 'utils/yupSchemas';
import { fileChangedHandler } from 'utils/helpers';
import { useMutation } from '@apollo/client';
import { useState } from 'react';

export function EditGroupFormik({ oldValues, setOpen }) {
  const [pp, setPp] = useState('');
  const [ppFile, setPpFile] = useState('');
  const [thumbnail, setThumbnail] = useState('');
  const [thumbnailFile, setThumbnailFile] = useState('');

  const [editGroup] = useMutation(EDIT_GROUP_MUTATION, {
    variables: { groupId: oldValues?.id },
    refetchQueries: [{ query: GROUP_DETAIL_QUERY, variables: { id: oldValues?.id } }],
  });

  const initalValues = {
    title: oldValues?.title,
    company: oldValues?.company || '',
    description: oldValues.description || '',
    telephone: oldValues?.phoneNumber,
    website: oldValues?.website,
  };

  const submitFunction = async (values, { setSubmitting }) => {
    const { error, data } = await editGroup({
      variables: {
        title: values.title || '',
        company: values.company || '',
        description: values.description || '',
        phoneNumber: values.telephone || '',
        profilePhoto: ppFile || '',
        thumbnail: thumbnailFile || '',
        website: values.website || '',
      },
    });
    if (error) {
      console.log(error);
      alert('Lütfen tekrar deneyin!');
    } else {
      setOpen(false);
    }
    setSubmitting(false);
  };

  return (
    <Formik initialValues={initalValues} validationSchema={editGroupFormYup} onSubmit={submitFunction}>
      {formik => (
        <Form>
          <UikWidgetContent>
            <UikFormInputGroup>
              <UikFormInputGroup direction="horizontal">
                <input
                  type="file"
                  name="avatar"
                  onChange={input => fileChangedHandler(input, setPp, setPpFile)}
                  style={{ display: 'none' }}
                  id="profilePhoto"
                />
                <label htmlFor="profilePhoto">
                  {
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      <span className="uik-content-title__wrapper">Profİl Fotoğrafı</span>
                      <UikAvatar
                        style={{ cursor: 'pointer', justifyContent: 'center' }}
                        imgUrl={pp || oldValues?.profilePhoto || null}
                        avatarPlaceholder={{
                          content: <Uikon className={cls.iconCamera}>camera</Uikon>,
                        }}
                        size="jumbo"
                      />
                    </div>
                  }
                </label>
                <input
                  type="file"
                  name="avatar"
                  onChange={input => fileChangedHandler(input, setThumbnail, setThumbnailFile)}
                  style={{ display: 'none' }}
                  id="thumbnail"
                />
                <label htmlFor="thumbnail">
                  {
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      <span className="uik-content-title__wrapper">Kapak Fotoğrafı</span>
                      <UikAvatar
                        style={{ cursor: 'pointer', justifyContent: 'center' }}
                        imgUrl={thumbnail || oldValues?.thumbnail || null}
                        avatarPlaceholder={{
                          content: <Uikon className={cls.iconCamera}>camera</Uikon>,
                        }}
                        size="jumbo"
                      />
                    </div>
                  }
                </label>
              </UikFormInputGroup>
              <UikFormInputGroup direction="horizontal">
                <Field errorMessage={formik.errors.title} as={UikInput} label={'Grup Adı'} name="title" />
                <Field errorMessage={formik.errors.company} as={UikInput} label={'Şirket/Okul Adı'} name="company" />
              </UikFormInputGroup>
              <UikFormInputGroup direction="horizontal">
                <Field
                  errorMessage={formik.errors.telephone}
                  as={UikInput}
                  label={'Telefon Numarası'}
                  name="telephone"
                />
                <Field errorMessage={formik.errors.website} as={UikInput} label={'Websitesi'} name="website" />
              </UikFormInputGroup>
            </UikFormInputGroup>
            <UikFormInputGroup>
              <UikContentTitle style={{ marginTop: 4 }}>{'Detaylı Açıklama (500 karakter)'}</UikContentTitle>
              <div className={cls.message}>
                <Field
                  errorMessage={formik.errors.description}
                  maxLength="500"
                  as="textarea"
                  name="description"
                  style={{ minHeight: '150px' }}
                  rows="2"
                  id="commentArea"
                />
              </div>
            </UikFormInputGroup>
          </UikWidgetContent>
          <UikWidgetContent style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <UikButton success type="submit" disabled={formik.isSubmitting}>
              Kaydet
            </UikButton>
          </UikWidgetContent>
        </Form>
      )}
    </Formik>
  );
}
