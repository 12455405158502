import { Field, Form, Formik } from 'formik';
import {
  UikAvatar,
  UikButton,
  UikCheckbox,
  UikContentTitle,
  UikFormInputGroup,
  UikInput,
  UikWidgetContent,
  Uikon,
} from '@uik';

import { EDIT_MY_SETTINGS_MUTATION } from '../../graphql/mutations';
import { USER_DETAIL_MY_PROFILE_QUERY } from '../../graphql/queries';

import cls from '../../views/Home/components/PostJob/buildings-user.module.scss';
import { editMySettingsYup } from 'utils/yupSchemas';
import { fileChangedHandler } from 'utils/helpers';
import { useMutation } from '@apollo/client';
import { useState } from 'react';

export function EditMySettingsFormik({ oldValues, setOpen }) {
  const [pp, setPp] = useState('');
  const [ppFile, setPpFile] = useState('');
  const [thumbnail, setThumbnail] = useState('');
  const [thumbnailFile, setThumbnailFile] = useState('');
  const [companyLogo, setCompanyLogo] = useState('');
  const [companyLogoFile, setCompanyLogoFile] = useState('');

  const [editMySettings] = useMutation(EDIT_MY_SETTINGS_MUTATION, {
    refetchQueries: [{ query: USER_DETAIL_MY_PROFILE_QUERY }],
  });

  const initalValues = {
    firstName: oldValues?.firstName || '',
    lastName: oldValues?.lastName || '',
    biography: oldValues?.biography || '',
    website: oldValues?.website || '',
    phoneNumber: oldValues?.phoneNumber || '',
    company: oldValues?.career?.company || '',
    jobTitle: oldValues?.career?.jobTitle || '',
    startDate: oldValues?.career?.startDate || '',
    endDate: oldValues?.career?.endDate || '',
    isPresent: oldValues?.career?.isPresent || '',
    companyLogo: oldValues?.companyLogo || '',
  };

  const submitFunction = async (values, { setSubmitting }) => {
    const { error, data } = await editMySettings({
      variables: {
        firstName: values?.firstName || '',
        lastName: values?.lastName || '',
        profilePhoto: ppFile || '',
        thumbnail: thumbnailFile || '',
        biography: values?.biography || '',
        website: values?.website || '',
        phoneNumber: values?.phoneNumber || '',
        company: values?.company || '',
        jobTitle: values?.jobTitle || '',
        startDate: values?.startDate || '',
        endDate: values?.endDate || '',
        isPresent: values?.isPresent || false,
        companyLogo: companyLogoFile || '',
      },
    });
    if (error) {
      console.log(error);
      alert('Lütfen tekrar deneyin!');
    } else {
      setOpen(false);
    }
    setSubmitting(false);
  };

  return (
    <Formik initialValues={initalValues} validationSchema={editMySettingsYup} onSubmit={submitFunction}>
      {formik => (
        <Form>
          <UikWidgetContent>
            <UikFormInputGroup>
              <UikFormInputGroup direction="horizontal">
                <input
                  type="file"
                  name="avatar"
                  onChange={input => fileChangedHandler(input, setPp, setPpFile)}
                  style={{ display: 'none' }}
                  id="profilePhoto"
                />
                <label htmlFor="profilePhoto">
                  {
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      <span className="uik-content-title__wrapper">Profİl Fotoğrafı</span>
                      <UikAvatar
                        style={{ cursor: 'pointer', justifyContent: 'center' }}
                        imgUrl={pp || oldValues?.profilePhoto || null}
                        avatarPlaceholder={{
                          content: <Uikon className={cls.iconCamera}>camera</Uikon>,
                        }}
                        size="jumbo"
                      />
                    </div>
                  }
                </label>

                <input
                  type="file"
                  name="avatar"
                  onChange={input => fileChangedHandler(input, setThumbnail, setThumbnailFile)}
                  style={{ display: 'none' }}
                  id="thumbnail"
                />
                <label htmlFor="thumbnail">
                  {
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      <span className="uik-content-title__wrapper">Kapak Fotoğrafı</span>
                      <UikAvatar
                        style={{ cursor: 'pointer', justifyContent: 'center' }}
                        imgUrl={thumbnail || oldValues?.thumbnail || null}
                        avatarPlaceholder={{
                          content: <Uikon className={cls.iconCamera}>camera</Uikon>,
                        }}
                        size="jumbo"
                      />
                    </div>
                  }
                </label>
                <input
                  type="file"
                  name="avatar"
                  onChange={input => fileChangedHandler(input, setCompanyLogo, setCompanyLogoFile)}
                  style={{ display: 'none' }}
                  id="companyLogo"
                />
                <label htmlFor="companyLogo">
                  {
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      <span className="uik-content-title__wrapper">Şirket/Okul Logosu</span>
                      <UikAvatar
                        style={{ cursor: 'pointer', justifyContent: 'center' }}
                        imgUrl={companyLogo || oldValues?.companyLogo || null}
                        avatarPlaceholder={{
                          content: <Uikon className={cls.iconCamera}>camera</Uikon>,
                        }}
                        size="jumbo"
                      />
                    </div>
                  }
                </label>
              </UikFormInputGroup>
              <UikFormInputGroup direction="horizontal">
                <Field errorMessage={formik.errors.firstName} as={UikInput} label={'İsİm'} name="firstName" />
                <Field errorMessage={formik.errors.lastName} as={UikInput} label={'Soyİsİm'} name="lastName" />
              </UikFormInputGroup>

              <UikFormInputGroup direction="horizontal">
                <Field
                  errorMessage={formik.errors.phoneNumber}
                  as={UikInput}
                  label={'Telefon Numarası'}
                  name="phoneNumber"
                />
                <Field errorMessage={formik.errors.website} as={UikInput} label={'Websİtesİ'} name="website" />
              </UikFormInputGroup>

              <UikFormInputGroup direction="horizontal">
                <Field
                  errorMessage={formik.errors.company}
                  as={UikInput}
                  label={'Çalıştığınız Şİrket'}
                  name="company"
                />
                <Field errorMessage={formik.errors.jobTitle} as={UikInput} label={'Mesleğİnİz'} name="jobTitle" />
              </UikFormInputGroup>

              <UikFormInputGroup direction="horizontal">
                <Field
                  errorMessage={formik.errors.startDate}
                  as={UikInput}
                  label={'Başlangıç Tarİhİnİz'}
                  type="date"
                  name="startDate"
                />
                <Field
                  errorMessage={formik.errors.startDate}
                  as={UikInput}
                  label={'Çıkış Tarİhİnİz'}
                  type="date"
                  name="endDate"
                />
              </UikFormInputGroup>
              <Field
                as={UikCheckbox}
                value={oldValues?.career?.isPresent || false}
                label={'Hala Çalışıyor Musunuz?'}
                name="isPresent"
              />
              <UikFormInputGroup>
                <UikContentTitle style={{ marginTop: 4 }}>{'Yazılı Özgeçmİş'}</UikContentTitle>
                <div className={cls.message}>
                  <Field
                    errorMessage={formik.errors.biography}
                    as="textarea"
                    name="description"
                    style={{ minHeight: '150px' }}
                    rows="2"
                    id="commentArea"
                  />
                </div>
              </UikFormInputGroup>
            </UikFormInputGroup>
          </UikWidgetContent>
          <UikWidgetContent style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <UikButton success type="submit" disabled={formik.isSubmitting}>
              Kaydet
            </UikButton>
          </UikWidgetContent>
        </Form>
      )}
    </Formik>
  );
}
