import { UikAvatar, Uikon } from '@uik';

import { CREATE_UPDATE } from 'graphql/mutations';
import { GET_UPDATE_WITH_LIKES_AND_COMMENTS } from 'graphql/queries';
import { Grid } from '@material-ui/core';
import ModifiableSnackbar from 'utils/ModifiableSnackbar';
import cls from './social-post-create.module.scss';
import { duser } from '@exampleAssets';
import { fileChangedHandlerArray } from 'utils/helpers';
import { useMutation } from '@apollo/client';
import { useState } from 'react';

const SocialPostWriteComment = ({ parentUpdateId, myProfilePhoto }) => {
  const [content, setContent] = useState('');
  const [imagePreviews, setImagePreviews] = useState([]);
  const [imageFiles, setImageFiles] = useState([]);
  const [postAlert, setPostAlert] = useState('');

  //gql
  const [createUpdate] = useMutation(CREATE_UPDATE, {
    variables: { parentUpdateId: parentUpdateId },
    refetchQueries: [{ query: GET_UPDATE_WITH_LIKES_AND_COMMENTS, variables: { id: parentUpdateId } }],
  });

  //helpers
  function handleImageUpload(input) {
    if (imagePreviews.length >= 10) {
      setPostAlert('Max');
      return;
    }
    fileChangedHandlerArray(input, imagePreviews, setImagePreviews, imageFiles, setImageFiles);
  }

  const handleKeyDown = async event => {
    if (event.key !== 'Enter') return;
    event.preventDefault();

    if (content === '') return;

    const { data, error } = await createUpdate({ variables: { content: content, images: imageFiles } });
    if (data?.createUpdate) {
      setPostAlert('Success');
    } else if (error) {
      setPostAlert('Error');
      console.error(error);
    } else {
      console.error(data);
    }

    setContent('');
    setImagePreviews([]);
    setImageFiles([]);
  };

  const handleImageDelete = index => {
    setImagePreviews(imagePreviews.filter((el, idx) => idx !== index));
    setImageFiles(imageFiles.filter((el, idx) => idx !== index));
  };

  return (
    <>
      <div className={cls.message}>
        <UikAvatar imgUrl={myProfilePhoto || duser} />
        <textarea
          value={content}
          id="subCommentArea"
          placeholder={'Gönderi hakkında bir yorum yapın.'}
          rows="1"
          onChange={() => setContent(event.target.value)}
          onKeyDown={event => handleKeyDown(event)}
        />

        <div className={cls.actions}>
          <div>
            <input
              type="file"
              name="avatar"
              onChange={input => handleImageUpload(input)}
              style={{ display: 'none' }}
              id="sub-comment-create"
            />
            <label htmlFor="sub-comment-create">
              <Uikon className={cls.iconPhoto} style={{ cursor: 'pointer' }}>
                camera
              </Uikon>
            </label>
          </div>
        </div>
      </div>
      <Grid container style={{ paddingLeft: 70, paddingRight: '20px' }}>
        {imagePreviews.map((img, index) => (
          <Grid key={index} item md={3} xs={7} style={{ padding: 5, position: 'relative' }}>
            <Grid
              className={cls.photo}
              style={{
                backgroundImage: `url(${img})`,
                opacity: '0.4',
                width: '100%',
              }}
            />

            <span
              className={cls.cross}
              style={{
                cursor: 'pointer',

                position: 'absolute',
              }}
              onClick={() => handleImageDelete(index)}
            >
              <Uikon
                style={{
                  transform: 'rotate(45deg)',
                }}
              >
                add
              </Uikon>
            </span>
          </Grid>
        ))}
      </Grid>
      <ModifiableSnackbar condition={postAlert === 'Success'} setter={setPostAlert} severity="success">
        Paylaşımınız Yayına Alınmıştır!
      </ModifiableSnackbar>
      <ModifiableSnackbar condition={postAlert === 'Error'} setter={setPostAlert} severity="error">
        Paylaşımınız Maalesef Yayınlanamadı.
      </ModifiableSnackbar>
      <ModifiableSnackbar condition={postAlert === 'Max'} setter={setPostAlert} severity="error">
        En fazla 10 fotoğraf yükleyebilirsiniz.
      </ModifiableSnackbar>
    </>
  );
};

export default SocialPostWriteComment;
