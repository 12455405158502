import { getBaseInitials, getStudentInitials } from './Forms/initalValues';
import { registerAlumniYup, registerBaseYup, registerStudentYup } from './Forms/yupRegisterSchemas';

export default function(step, isAlumni, isMasters) {
  switch (step) {
    case 'Base':
      return {
        currInitalValues: getBaseInitials(isAlumni),
        currValidationSchema: isAlumni ? registerAlumniYup : registerBaseYup,
      };
    case 'Student':
      return { currInitalValues: getStudentInitials(isMasters), currValidationSchema: registerStudentYup };
    case 'MailApproval':
      return { currInitalValues: null, currValidationSchema: null };
    default:
      return { currInitalValues: null, currValidationSchema: null };
  }
}
