import * as React from 'react';
import * as images from '@exampleAssets';

import { UikMapPinCircle, Uikon } from '@uik';

import classnames from 'classnames';
import cls from './buildings-map.module.scss';

// @flow
type BuildingsListingMapProps = {
  className?: String,
};
const BuildingsListingMap = ({ className }: BuildingsListingMapProps) => (
  <div className={classnames(cls.wrapper, className)} style={{ height: 385, backgroundImage: `url("${images.map}")` }}>
    <UikMapPinCircle className={cls.pin4} color="violet">
      <Uikon>profile_card</Uikon>
    </UikMapPinCircle>
    <UikMapPinCircle className={cls.pin3} color="green">
      <Uikon>profile_card</Uikon>
    </UikMapPinCircle>
    <UikMapPinCircle className={cls.pin1} color="green">
      <Uikon>profile_card</Uikon>
    </UikMapPinCircle>
    <UikMapPinCircle className={cls.pin2} color="violet">
      <Uikon>profile_card</Uikon>
    </UikMapPinCircle>
  </div>
);

BuildingsListingMap.defaultProps = {
  className: null,
};

export default BuildingsListingMap;
