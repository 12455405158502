import { GET_UPDATE_WITH_LIKES_AND_COMMENTS } from 'graphql/queries';
import PostActions from 'views/Home/components/PostActions';
import PostComment from 'views/Home/components/PostComment';
import PostCommentWrite from 'views/Home/components/PostCommentWrite';
import React from 'react';
import { UikDivider } from '@uik';
import { useQuery } from '@apollo/client';

function PostFooter({ update, me }) {
  const myId = me?.id;

  const { data, loading, error } = useQuery(GET_UPDATE_WITH_LIKES_AND_COMMENTS, { variables: { id: update?.id } });
  if (error) console.error(error);

  const likes = data?.getLikesAndComments?.likes || [];
  const comments = data?.getLikesAndComments?.updates || [];
  const myLike = likes.filter(like => like.user?.id === myId)[0] || null;

  return (
    <>
      <PostActions updateId={update?.id} myId={myId} myLike={myLike} likes={likes} commentCount={comments?.length} />
      <UikDivider />

      {comments?.length > 0 &&
        comments.map(comm => <PostComment parentUpdateId={update?.id} currentUserId={myId} comm={comm} />)}
      <PostCommentWrite myProfilePhoto={me?.profilePhoto} parentUpdateId={update?.id} />
    </>
  );
}

export default PostFooter;
