export default [
  {
    exact: true,
    text: 'Anasayfa',
    to: '/',
  },
  {
    exact: true,
    text: 'Kullanıcılar',
    to: '/users',
  },
  {
    exact: true,
    text: 'Mentörler',
    to: '/mentor',
  },
  {
    exact: true,
    text: 'İşler',
    to: '/jobs',
    /*   count:
      typeof window !== "undefined"
        ? JSON.parse(localStorage.getItem("jobs")).length
        : 0 */
  },

  {
    exact: true,
    text: 'Gruplar',
    to: '/groups',
  },
  {
    exact: true,
    text: 'Takvim',
    to: '/events',
  },
];
