// Libraries
//GQL

import {
    UikAvatar,
    UikContainerHorizontal,
    UikContainerVertical,
    UikInput,
    UikLayoutMain,
    UikWidget,
    UikWidgetHeader,
    Uikon
} from '@uik';

import { ALL_USERS_QUERY } from '../../graphql/queries';
import { Link } from 'react-router-dom';
import React from 'react';
import UikSocialFriendList from 'components/UikSocialFriendList';
import classnames from 'classnames';
import cls from './socialio.module.scss';
import { duser } from '@exampleAssets';
import { get } from 'lodash';
import { useLazyQuery } from '@apollo/client';

const Users = () => {
  //states
  const searchInputEl = React.useRef(null);
  const [searchedTerm, setSearchTerm] = React.useState('');
  //initial query
  React.useEffect(() => {
    searchQuery();
  }, []);

  //gql
  const [searchQuery, { loading, called, error, data }] = useLazyQuery(ALL_USERS_QUERY, {
    variables: { filterQuery: searchedTerm },
  });
  const allUsers = data?.allUsers;
  //handlers
  function handleSearch() {
    const str = get(searchInputEl, 'current.input.value', '');
    setSearchTerm(str.charAt(0).toLocaleUpperCase('tr-TR') + str.slice(1) || '');
    searchQuery();
  }

  return (
    <UikContainerVertical>
      <UikContainerHorizontal
        className={classnames(cls.contentContainer, {
          [cls['Menu']]: 'Menu',
        })}
      >
        <div className={cls.content}>
          <UikLayoutMain contentCenterMedium>
            <UikWidget margin>
              <UikWidgetHeader>{'Tüm Kullanıcılar'}</UikWidgetHeader>
              <div style={{ padding: '30px 30px 0px' }}>
                <UikInput
                  icon={<Uikon>search_left</Uikon>}
                  placeholder={'Kullanıcı Ara'}
                  ref={searchInputEl}
                  onChange={event => handleSearch(event)}
                />
              </div>
              <UikSocialFriendList>
                {allUsers?.map(item => (
                  <React.Fragment key={item?.id}>
                    <Link to={`/user/${item?.id}`}>
                      <div
                        style={{
                          borderRadius: '4px',
                          border: '1px solid #EAEDF3',
                          padding: '20px',
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        <UikAvatar
                          highlighted
                          imgUrl={item.profilePhoto || duser}
                          name={<div style={{ color: '#3E3F42' }}>{`${item.firstName} ${item.lastName}`}</div>}
                          size="large"
                          //textBottom={item.textBottom}
                        />
                      </div>
                    </Link>
                  </React.Fragment>
                ))}
              </UikSocialFriendList>
            </UikWidget>
          </UikLayoutMain>
        </div>
      </UikContainerHorizontal>
    </UikContainerVertical>
  );
};

export default Users;
