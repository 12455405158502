// Libraries
//gql

import {
  CHECK_ID_WITH_TOKEN_QUERY,
  GROUP_DETAIL_QUERY,
  IS_USER_JOINED_QUERY,
  USER_DETAIL_MY_PROFILE_QUERY,
} from '../../graphql/queries';
import { JOIN_GROUP_MUTATION, LEAVE_GROUP_MUTATION } from '../../graphql/mutations';
import {
  UikButton,
  UikContainerHorizontal,
  UikContainerVertical,
  UikLayoutMain,
  UikSocialWidgetAbout,
  UikTabContainer,
  UikTabItem,
  UikWidget,
} from '@uik';
import { dgroup2, duser } from '@exampleAssets';
import { useMutation, useQuery } from '@apollo/client';

import { EditGroupFormik } from 'utils/FormikForms/EditGroupFormik';
import Members from './components/Members';
import MuiAlert from '@material-ui/lab/Alert';
import PendingMembers from './components/PendingMembers';
import React from 'react';
import { Snackbar } from '@material-ui/core';
import UikSocialFriendList from 'components/UikSocialFriendList';
import UikSocialHeader from 'components/UikSocialHeader';
import classnames from 'classnames';
import cls from './socialio.module.scss';
import { get } from 'lodash';
import getAbout from './getAbout';
import { useParams } from 'react-router-dom';

//edit form

//mutations

//queries

const GroupDetail = () => {
  //states and react
  const [clickText, setClickText] = React.useState('');
  const [successAlert, setSuccessAlert] = React.useState(false);
  const [errorAlert, setErrorAlert] = React.useState(false);
  const [text, setText] = React.useState('Üyeler'); // default selected
  const { id: paramId } = useParams();

  //GQL
  //QUERIES
  //fetch my_id
  const { data: CheckIdData } = useQuery(CHECK_ID_WITH_TOKEN_QUERY);
  //fetch group details
  const { data } = useQuery(GROUP_DETAIL_QUERY, {
    variables: { id: paramId },
  });

  //MY_ID
  const myId = get(CheckIdData, 'checkToken.id', null);
  const isAdmin = myId == data?.getGroupProfile?.admin?.id;
  const isPublic = data?.getGroupProfile?.isPublic;
  //ISADMIN WILL BE NEEDED WHEN WE IMPLEMENT GROUP DATA EDITING

  //fetch isUserJoined bool
  const { data: isUserJoinedData } = useQuery(IS_USER_JOINED_QUERY, {
    skip: !myId,
    variables: { groupId: paramId },
  });

  //MUTATIONS
  const [joinGroup, { loading: joinLoading }] = useMutation(JOIN_GROUP_MUTATION, {
    variables: { groupId: paramId },
    refetchQueries: [
      { query: IS_USER_JOINED_QUERY, variables: { groupId: paramId } },
      { query: GROUP_DETAIL_QUERY, variables: { id: paramId } },
      { query: USER_DETAIL_MY_PROFILE_QUERY },
    ],
  });
  const [leaveGroup, { loading: leaveLoading }] = useMutation(LEAVE_GROUP_MUTATION, {
    variables: { groupId: paramId },
    refetchQueries: [
      { query: IS_USER_JOINED_QUERY, variables: { groupId: paramId } },
      { query: GROUP_DETAIL_QUERY, variables: { id: paramId } },
      { query: USER_DETAIL_MY_PROFILE_QUERY },
    ],
  });

  //handlers
  function handleClick(text) {
    setClickText(text);
  }

  function handleSnackbarClose(event, reason) {
    if (reason === 'clickaway') return;
    setSuccessAlert(false);
    setErrorAlert(false);
  }

  async function handleJoin() {
    const { error, data } = await joinGroup();
    if (data.joinGroup) {
      setSuccessAlert(true);
    } else {
      setErrorAlert(true);
    }
  }
  async function handleExit() {
    const { data } = await leaveGroup();
    if (data.leaveGroup) {
      setSuccessAlert(true);
    } else {
      setErrorAlert(true);
    }
  }

  //helpers
  function getCorrectUsersArray() {
    return text === 'Üyeler' ? data?.getGroupProfile?.getMembers : data?.getGroupProfile?.getPendingMembers;
  }
  function amIPending() {
    return data?.getGroupProfile?.getPendingMembers?.filter(m => m.id == myId).length === 1;
  }
  const about = getAbout(data, isAdmin);
  const defaultProfilePhoto = duser;

  let groupPhoto = dgroup2;
  if (data?.getGroupProfile?.profilePhoto) {
    groupPhoto = data?.getGroupProfile?.profilePhoto;
  }

  return (
    <UikContainerVertical>
      <UikContainerHorizontal
        className={classnames(cls.contentContainer, {
          [cls['Menu']]: 'Menu',
        })}
      >
        <UikLayoutMain contentCenterMedium>
          <UikSocialHeader
            canEdit={isAdmin}
            formikComponent={EditGroupFormik}
            oldValues={data?.getGroupProfile}
            handleClick={handleClick}
            editFormTitle="Grup Bilgilerini Düzenle"
            tabs={[
              {
                text: 'Hakkında',
                extra: null,
              },
              {
                text: 'Üyeler',
                extra: get(data, 'getGroupProfile.getMembers.length', null) || '0',
              },
            ]}
            button={
              isUserJoinedData &&
              (isUserJoinedData?.isUserJoined ? (
                <UikButton
                  disabled={leaveLoading}
                  onClick={handleExit}
                  className={cls.btnUpdateCover}
                  dark
                  Component="span"
                >
                  {'Gruptan Çık'}
                </UikButton>
              ) : isPublic || !amIPending() ? (
                <UikButton
                  disabled={joinLoading}
                  onClick={handleJoin}
                  className={cls.btnUpdateCover}
                  success
                  Component="span"
                >
                  {'Katıl'}
                </UikButton>
              ) : (
                <UikButton disabled className={cls.btnUpdateCover} dark Component="span">
                  {'Onay Bekleniyor'}
                </UikButton>
              ))
            }
            handleButtonClick={handleJoin}
            avatar={{
              imgUrl: groupPhoto,
            }}
            imgUrl={data?.getGroupProfile?.thumbnail}
            margin
            name={data?.getGroupProfile?.title || ''}
          />
          {clickText === 'Üyeler' ? (
            <UikWidget margin>
              <UikTabContainer>
                {[
                  {
                    text: 'Üyeler',
                    extra: get(data, 'getGroupProfile.getMembers.length', null) || '0',
                  },

                  isAdmin &&
                    !isPublic && {
                      text: 'Onay Bekleyenler',
                      extra: get(data, 'getGroupProfile.getPendingMembers.length', null) || '0',
                    },
                ].map(item => (
                  <UikTabItem
                    onClick={() => setText(item.text)}
                    key={item.text}
                    className={item.text === text && 'active'}
                    {...item}
                  />
                ))}
              </UikTabContainer>
              <UikSocialFriendList>
                {text === 'Üyeler' ? (
                  <Members array={getCorrectUsersArray()} photo={defaultProfilePhoto} />
                ) : (
                  <PendingMembers
                    array={getCorrectUsersArray()}
                    photo={defaultProfilePhoto}
                    groupId={paramId}
                    setSuccessAlert={setSuccessAlert}
                    setErrorAlert={setErrorAlert}
                  />
                )}
              </UikSocialFriendList>
            </UikWidget>
          ) : (
            <UikSocialWidgetAbout data={about} margin />
          )}
        </UikLayoutMain>
        <Snackbar open={successAlert} autoHideDuration={6000} onClose={handleSnackbarClose}>
          <MuiAlert onClose={handleSnackbarClose} severity="success">
            İşlem Başarılı!
          </MuiAlert>
        </Snackbar>
        <Snackbar open={errorAlert} autoHideDuration={6000} onClose={handleSnackbarClose}>
          <MuiAlert onClose={handleSnackbarClose} severity="error">
            İşlem Başarısız!
          </MuiAlert>
        </Snackbar>
      </UikContainerHorizontal>
    </UikContainerVertical>
  );
};

export default GroupDetail;
