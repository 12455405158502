import { Button, FormLabel, Grid } from '@material-ui/core';
import { UikCheckbox, UikFormInputGroup, UikInput } from '@uik';

import { Field } from 'formik';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import SchoolIcon from '@material-ui/icons/School';
import cls from '../../buildings-signup.module.scss';

export default function BaseForm({ isAlumni, setIsAlumni, errors }) {
  return (
    <>
      <UikFormInputGroup direction="horizontal" className={cls.inputGroup} style={{ marginTop: '0', width: '95%' }}>
        <Grid item xs={12} sm={6}>
          <FormLabel className={cls.label1} component="legend">
            Ad
          </FormLabel>

          <Field
            as={UikInput}
            className={cls.eposta}
            errorMessage={errors.firstName}
            name="firstName"
            placeholder="İsminiz"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormLabel className={cls.label2} component="legend">
            Soyad
          </FormLabel>

          <Field
            as={UikInput}
            className={cls.sifre}
            errorMessage={errors.lastName}
            name="lastName"
            placeholder="Soyisminiz"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormLabel className={cls.label1} component="legend">
            TC Kimlik No
          </FormLabel>

          <Field
            as={UikInput}
            className={cls.eposta}
            errorMessage={errors.tc}
            name="tc"
            type="text"
            pattern="[0-9]{1,12}"
            placeholder="1021324354"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormLabel className={cls.label2} component="legend">
            E-Posta
          </FormLabel>

          <Field
            as={UikInput}
            className={cls.sifre}
            errorMessage={errors.email}
            name="email"
            type="email"
            placeholder="ornek@email.com"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormLabel className={cls.label1} component="legend">
            Parola
          </FormLabel>

          <Field
            as={UikInput}
            className={cls.eposta}
            errorMessage={errors.password}
            name="password"
            placeholder="·····"
            type="password"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormLabel className={cls.label2} component="legend">
            Parola(tekrar)
          </FormLabel>

          <Field
            as={UikInput}
            className={cls.sifre}
            errorMessage={errors.confirmPassword}
            name="confirmPassword"
            placeholder="·····"
            type="password"
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <div className={cls.uyelikTipi}>Üyelik Tipi</div>
        </Grid>
        <Grid item xs={6} sm={4}>
          <Button
            onClick={() => setIsAlumni(false)}
            className={isAlumni ? cls.uyelikBtn2 : cls.uyelikBtn1}
            startIcon={<MenuBookIcon />}
          >
            Öğrenci
          </Button>
        </Grid>
        <Grid item xs={6} sm={4}>
          <Button
            onClick={() => setIsAlumni(true)}
            className={!isAlumni ? cls.uyelikBtn2 : cls.uyelikBtn1}
            startIcon={<SchoolIcon />}
          >
            Mezun
          </Button>
        </Grid>
      </UikFormInputGroup>
      {isAlumni && (
        <div>
          <UikFormInputGroup direction="horizontal" className={cls.inputGroup} style={{ marginTop: '0', width: '95%' }}>
            <Grid item xs={12} sm={6}>
              <FormLabel className={cls.label1} component="legend">
                Mezun Olunan Bölüm
              </FormLabel>

              <Field
                as={UikInput}
                className={cls.eposta}
                errorMessage={errors.gradLicense}
                name="gradLicense"
                placeholder="·····"
                type="text"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormLabel className={cls.label2} component="legend">
                Mezuniyet Tarihi
              </FormLabel>

              <Field
                as={UikInput}
                className={cls.sifre}
                errorMessage={errors.gradDate}
                name="gradDate"
                placeholder="·····"
                type="date"
              />
            </Grid>
          </UikFormInputGroup>
          <div style={{ display: 'flex', alignItems: 'center', marginTop: '30px' }}>
            <Field as={UikCheckbox} errorMessage={errors.tos} name="tos" />
            <label htmlFor="tos" className={cls.tos}>
              <a>Kullanım Koşullarını</a>, <a>Site Koşullarını</a> ve <a>Gizlilik Politikasını</a> onaylıyorum.
            </label>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', marginTop: '20px' }}>
            <span style={{ color: 'red', textAlign: 'center' }}>{errors.tos}</span>
          </div>
        </div>
      )}
    </>
  );
}
