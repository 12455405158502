//gql

import * as images from '@exampleAssets';

import {
  UikAvatar,
  UikButton,
  UikContainerHorizontal,
  UikContainerVertical,
  UikInput,
  UikLayoutMain,
  UikWidget,
  UikWidgetHeader,
  Uikon,
} from '@uik';

import { ALL_GROUPS_QUERY } from '../../graphql/queries';
import { CreateGroupFormik } from '../../utils/FormikForms/CreateGroupFormik';
import FormikModal from '../../components/FormikModal';
import { Link } from 'react-router-dom';
import React from 'react';
import UikSocialFriendList from 'components/UikSocialFriendList';
import classnames from 'classnames';
import cls from './socialio.module.scss';
import { get } from 'lodash';
import { useLazyQuery } from '@apollo/client';

function GroupList(props) {
  //states
  const searchInputEl = React.useRef(null);
  const [searchedTerm, setSearchTerm] = React.useState('');
  const [open, setOpen] = React.useState(false);

  //initial query
  React.useEffect(() => {
    searchQuery();
  }, []);

  //handlers
  function handleSearch() {
    setSearchTerm(get(searchInputEl, 'current.input.value', '') || '');
    searchQuery();
  }

  //gql
  const [searchQuery, { loading, called, error, data }] = useLazyQuery(ALL_GROUPS_QUERY, {
    variables: { filterTitle: searchedTerm },
  });

  const allGroups = data?.allGroups;
  const defaultGroupPhoto = images.dgroup2;
  return (
    <UikContainerVertical>
      <UikContainerHorizontal
        className={classnames(cls.contentContainer, {
          [cls['Menu']]: 'Menu',
        })}
      >
        <div className={cls.content} style={{ display: 'flex' }}>
          <UikLayoutMain contentCenterMedium>
            <UikWidget className={cls.base}>
              <div container className={cls.containerMedia}>
                <div className={cls.gruplar}>{'Gruplar'}</div>
                <div className={cls.flex}>
                  <div style={{ width: '100%' }}>
                    <UikInput
                      className={cls.Rectangle}
                      onChange={handleSearch}
                      ref={searchInputEl}
                      placeholder={'Grup ara...'}
                    />
                  </div>

                  <Uikon className={cls.Oval}>search_left</Uikon>
                </div>
                <div className={cls.wrapper}>
                  <UikWidgetHeader
                    rightEl={
                      <UikButton onClick={() => setOpen(true)} className={cls.BaseCopy8} Component="span">
                        <div className={cls.ValueCopy5}>GRUP OLUŞTUR</div>
                      </UikButton>
                    }
                  />
                </div>
              </div>

              <FormikModal
                open={open}
                setOpen={setOpen}
                formikComponent={CreateGroupFormik}
                title="Yeni Bir Grup Oluşturun"
              />
              <div className={cls.Divider} />
              <div container className={cls.TmGruplar}>
                Tüm gruplar
              </div>
              <div container>
                <UikSocialFriendList style={{ flexDirection: 'column', marginLeft: '0', marginTop: '0' }}>
                  {error && `Hata! ${error}`}
                  {allGroups &&
                    [...allGroups]
                      .sort((group1, group2) => {
                        return group1?.getMembers?.length < group2?.getMembers?.length ? 1 : -1;
                      })
                      .map(item => (
                        /* handled UikSocialFriendList search problem ! dont change */
                        <React.Fragment key={item.id}>
                          <Link
                            to={`/group/${item?.id}`}
                            style={{
                              alignItems: 'center',
                              borderRadius: '4px',
                              display: 'flex',
                              margin: 0,
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            <UikAvatar
                              highlighted
                              style={{ width: '79px', height: '79px', margin: '0px 30px 20px 0px' }}
                              imgUrl={item?.profilePhoto || defaultGroupPhoto}
                              size="large"
                            />
                            <div style={{ flexDirection: 'column' }}>
                              <div className={cls.avatarTitle}>{item?.title}</div>
                              <div className={cls.avatarUye}>
                                {item?.getMembers?.length}
                                {' Üye'}
                              </div>
                              <div className={cls.avatarDescription}>{item?.description}</div>
                            </div>
                          </Link>
                          <div className={cls.avatarDivider} style={{ flexBasis: 'auto' }} />
                        </React.Fragment>
                      ))}
                </UikSocialFriendList>
              </div>
            </UikWidget>
          </UikLayoutMain>
        </div>
      </UikContainerHorizontal>
    </UikContainerVertical>
  );
}

export default GroupList;
