import * as React from 'react';

import classnames from 'classnames';
import cls from './social-friend-list.module.scss';

// @flow



const UikSocialFriendList = ({ className, Component, children, ...rest }) => (
  <Component {...rest} className={classnames(cls.wrapper, className)}>
    {children}
  </Component>
);

UikSocialFriendList.defaultProps = {
  Component: 'div',
  children: null,
  className: null,
};

export default UikSocialFriendList;
