/* eslint-disable max-len */

import * as React from 'react';

import { Link, Redirect } from 'react-router-dom';
import {
    UikFormInputGroup,
    UikInput,
    UikWidget,
    UikWidgetContent
} from '@uik';

import { AUTHENTICATE_USER_MUTATION } from '../../graphql/mutations';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import cls from './buildings-signup.module.scss';
import { get } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import { useMutation } from '@apollo/client';

const useStyles = makeStyles(theme => ({
  root2: {
    flexGrow: 1,
    backgroundColor: '#000',
  },
}));

function PasswordReset2(props) {
  //States
  const [loading, setLoading] = React.useState(false);
  //Refs
  const emailInputEl = React.useRef(null);
  const passwordInputEl = React.useRef(null);
  //GQL
  const [authenticateUser, { data, error }] = useMutation(AUTHENTICATE_USER_MUTATION);
  //Handlers
  const handleOnClick = async () => {
    try {
      setLoading(true);
      const email = get(emailInputEl, 'current.input.value', null);
      const password = get(passwordInputEl, 'current.input.value', null);
      const json = await authenticateUser({ variables: { email: email, password: password } });
      const token = get(json, 'data.authenticate.token', null);
      const id = get(json, 'data.authenticate.user.id', null);
      if (token) {
        localStorage.setItem('login', true);
        localStorage.setItem('x-auth-token', token);
        localStorage.setItem('my-id', id);
        window.dispatchEvent(new Event('storage'));
      }

      /*
      window.setTimeout(() => {
        if (localStorage.getItem('login')) {
          props.history.push('/');
        } else {
          setLoading(false);
        }
      }, 8000);
      */
    } catch (err) {
      console.error(err);
    }
    setLoading(false);
  };
  const classes = useStyles();

  return (
    <div>
      {localStorage.getItem('x-auth-token') && <Redirect to="/" />}
      <UikWidget className={cls.widgetWrapper}>
        <div className={cls.content}>
          <div className={cls.right}>
            <div className={cls.top}>
              <div className={cls.logo} />
            </div>
            <h3 className={cls.headline}>Yaşar Üniversitesi </h3>
          </div>
          <UikWidgetContent className={cls.left}>
            <Grid container direction="row" justify="center" alignItems="center">
              <Container maxWidth="sm">
                <div className={cls.root2}>
                  <Link to="/login">
                    <Button className={cls.forgotLink} startIcon={<ArrowBackIcon />}>
                      Geri dön
                    </Button>
                  </Link>
                  <h1 className={cls.title}>Şifreni Yenile</h1>

                  <Grid container spacing={0}>
                    <UikFormInputGroup
                      direction="horizontal"
                      className={cls.inputGroup}
                      style={{ marginTop: '0', width: '100%' }}
                    >
                      <Grid item>
                        <FormLabel className={cls.label1} component="legend">
                          E-Posta
                        </FormLabel>

                        <UikInput
                          className={cls.eposta}
                          errorMessage={error == 'Error: No such user.' && `${error}`}
                          ref={emailInputEl}
                          //label="E-Posta"
                          name="email"
                          placeholder="your@emails.com"
                        />
                      </Grid>
                    </UikFormInputGroup>
                    <div className={cls.forgotPassword}>
                      Şifrenizi yenilemek için kullanmakta olduğunuz e-postanızı girin. Size göndereceğimiz bağlantıya
                      tıklayarak şifrenizi değiştirin.
                    </div>
                    <Button
                      className={loading ? cls.btnDisable : cls.btnAction + ' ' + cls.giris}
                      isLoading={loading}
                      onClick={() => {
                        handleOnClick();
                      }}
                    >
                      Gönder
                    </Button>
                  </Grid>
                </div>
              </Container>
            </Grid>
          </UikWidgetContent>
        </div>
      </UikWidget>
    </div>
  );
}

export default PasswordReset2;
