import {
    UikTabContainer,
    UikTabItem,
    UikWidget
} from '@uik';

import FriendsList from './FriendsList';
import PendingFriends from './PendingFriends';
import React from 'react';
import UikSocialFriendList from 'components/UikSocialFriendList';
import { duser as defaultProfilePhoto } from '@exampleAssets';

function FriendsTab({ user_id, isThisMyProfile, friendRequests, setSuccessAlert, setErrorAlert }) {
  const [text, setText] = React.useState('Arkadaşlar');
  return (
    <UikWidget margin>
      <UikTabContainer>
        {[
          {
            text: 'Arkadaşlar',
            extra: null,
          },
          isThisMyProfile && {
            text: 'Arkadaşlık İstekleri',
            extra: null,
          },
        ].map(item => (
          <UikTabItem
            onClick={() => setText(item.text)}
            key={item.text}
            className={item.text === text && 'active'}
            {...item}
          />
        ))}
      </UikTabContainer>
      <UikSocialFriendList>
        {text === 'Arkadaşlar' ? (
          <FriendsList user_id={user_id} photo={defaultProfilePhoto} />
        ) : (
          isThisMyProfile && (
            <PendingFriends
              user_id={user_id}
              photo={defaultProfilePhoto}
              setSuccessAlert={setSuccessAlert}
              setErrorAlert={setErrorAlert}
              friendRequests={friendRequests}
            />
          )
        )}
      </UikSocialFriendList>
    </UikWidget>
  );
}

export default FriendsTab;
