import * as images from '@exampleAssets';

import { USER_DETAIL_MANAGED_GROUPS_QUERY, USER_DETAIL_PUBLIC_GROUPS_QUERY } from '../../../../graphql/queries';
import { UikTabContainer, UikTabItem, UikWidget, UikWidgetHeader } from '@uik';

import React from 'react';
import UikSocialFriendList from 'components/UikSocialFriendList';
import UikSocialFriendListItem from 'components/UikSocialFriendListItem';
import { get } from 'lodash';
import myClassData from '../../assets/myclass';
import { useQuery } from '@apollo/client';

function Groups({ user_id, isThisMyProfile }) {
  const [text, setText] = React.useState('Gruplarım'); // default selected
  //fetches all groups for every user-profile page

  const { loading: publicGroupsloading, error: publicGroupsError, data: publicGroupsData } = useQuery(
    USER_DETAIL_PUBLIC_GROUPS_QUERY,
    {
      variables: { id: user_id },
    },
  );
  const publicGroupsFormattedData = get(publicGroupsData, 'getUserPublicProfile.getGroups', null)?.map(item => {
    return {
      linkTo: `/group/${item.id}`,
      imgUrl: item?.profilePhoto || images.dgroup2,
      name: item?.title,
      //textBottom: get(item, 'getMembers?.length', null) || '0',
    };
  });

  //fetches only managed groups only for my profile page
  const { loading: managedGroupsLoading, error: managedGroupsError, data: managedGroupsData } = useQuery(
    USER_DETAIL_MANAGED_GROUPS_QUERY,
    {
      skip: !isThisMyProfile,
    },
  );

  const managedGroupsFormattedData = get(managedGroupsData, 'myProfile.managedGroups', null)?.map(item => {
    return {
      linkTo: `/group/${item.id}`,
      imgUrl: item?.profilePhoto || images.dgroup2,
      name: item?.title,
    };
  });
  return (
    <UikWidget margin>
      <UikWidgetHeader noDivider>{'Gruplar'}</UikWidgetHeader>
      <UikTabContainer>
        {[
          {
            text: 'Gruplarım',
            extra: get(publicGroupsData, 'getUserPublicProfile.getGroups.length', null) || '0',
          },

          isThisMyProfile && {
            text: 'Yönettiğim Gruplar',
            extra: get(managedGroupsData, 'myProfile.managedGroups.length', null) || '0',
          },
        ].map(item => (
          <UikTabItem
            onClick={() => setText(item.text)}
            key={item.text}
            className={item.text === text && 'active'}
            {...item}
          />
        ))}{' '}
      </UikTabContainer>

      {text === 'Gruplarım' ? (
        <div>
          <UikSocialFriendList>
            {publicGroupsFormattedData &&
              publicGroupsFormattedData.map(item => <UikSocialFriendListItem key={item.id} data={item} />)}
          </UikSocialFriendList>
        </div>
      ) : text === 'Yönettiğim Gruplar' && isThisMyProfile ? (
        <UikSocialFriendList>
          {managedGroupsFormattedData &&
            managedGroupsFormattedData.map(item => <UikSocialFriendListItem key={item.id} data={item} />)}
        </UikSocialFriendList>
      ) : (
        //my class is deactive for now
        <UikSocialFriendList>
          {myClassData.map(item => (
            <UikSocialFriendListItem key={item.name} data={item} />
          ))}
        </UikSocialFriendList>
      )}
    </UikWidget>
  );
}

export default Groups;
