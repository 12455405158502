import * as images from "@exampleAssets";

export default [
  {
    imgUrl: images.a03,
    name: "Sarper Akış",
    status: "uik_online",
    textBottom: "1 ortak arkadaş"
  },
  {
    imgUrl: images.a01,
    name: "Cihan Akarpınar",
    status: "uik_online",
    textBottom: "5 ortak arkadaş"
  },
  {
    imgUrl: images.a02,
    name: "Çilem Akçay",
    status: "uik_online",
    textBottom: "14 ortak arkadaş"
  },

  {
    imgUrl: images.a02,
    name: "Çilem Akçay",
    status: "uik_online",
    textBottom: "14 ortak arkadaş"
  },
  {
    imgUrl: images.a03,
    name: "Sarper Akış",
    status: "uik_online",
    textBottom: "1 ortak arkadaş"
  },
  {
    imgUrl: images.a04,
    name: "Ahmet Polat Akman",
    status: "12m",
    textBottom: "2 ortak arkadaş"
  },
  ,
  {
    imgUrl: images.a05,
    name: "Maude Valdez",
    status: "5m",
    textBottom: "5 ortak arkadaş"
  },
  {
    imgUrl: images.a06,
    name: "Ecem Hatice Akova",
    status: "uik_online",
    textBottom: "4 ortak arkadaş"
  },
  {
    imgUrl: images.a05,
    name: "Maude Valdez",
    status: "5m",
    textBottom: "5 ortak arkadaş"
  },
  {
    imgUrl: images.a06,
    name: "Ecem Hatice Akova",
    status: "uik_online",
    textBottom: "4 ortak arkadaş"
  },
  {
    imgUrl: images.a07,
    name: "Senem Aksoy",
    status: "uik_online",
    textBottom: "2 ortak arkadaş"
  },
  {
    imgUrl: images.a08,
    name: "Ömer Alparslan",
    status: "46m",
    textBottom: "1 ortak arkadaş"
  },
  {
    imgUrl: images.a09,
    name: "Halit Mert",
    status: "uik_online",
    textBottom: "7 ortak arkadaş"
  },
  {
    imgUrl: images.a10,
    name: "Hasan Muratoğlu",
    status: "22m",
    textBottom: "8 ortak arkadaş"
  }
];
