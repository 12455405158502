//gql

import { UikButton, UikDivider, UikFormInputGroup, UikInput, UikWidget, UikWidgetContent, Uikon } from '@uik';

import { FORGOT_PASSWORD_MUTATION } from '../../graphql/mutations';
import MuiAlert from '@material-ui/lab/Alert';
import React from 'react';
import { Snackbar } from '@material-ui/core';
import cls from './buildings-signup.module.scss';
import { get } from 'lodash';
import { useMutation } from '@apollo/client';

function PasswordReset() {
  //const TIMEOUTVAR = 90;
  //States
  const [loading, setLoading] = React.useState(false);
  //const [resendMailTimeout, setResendMailTimeout] = React.useState(TIMEOUTVAR);
  const [alertBoolen, setAlertBoolen] = React.useState(false);

  //refs
  const emailInputEl = React.useRef(null);

  //gql
  const [startForgotPassword, { data, error }] = useMutation(FORGOT_PASSWORD_MUTATION);

  //Handlers
  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setAlertBoolen(false);
  };

  const handleOnSubmitClick = async () => {
    //setResendMailTimeout(TIMEOUTVAR);
    try {
      setLoading(true);
      const email = get(emailInputEl, 'current.input.value', null);
      const json = await startForgotPassword({ variables: { email: email } });
      const forgotPasswordBoolen = get(json, 'data.startForgotPassword', null);
      if (forgotPasswordBoolen == true) {
        setAlertBoolen(true);
      }
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
    //timers
    /*
    let time = resendMailTimeout;
    const timer = setInterval(() => {
      if (time <= 0) {
        clearInterval(timer);
      }
      setResendMailTimeout(resendMailTimeout => resendMailTimeout - 1);
      time--;
    }, 1000);

    setTimeout(() => {
      setLoading(false);
    }, TIMEOUTVAR * 1000);
    */
  };

  return (
    <div className={cls.pageWrapper}>
      <UikWidget className={cls.widgetWrapper}>
        <div className={cls.content}>
          <UikWidgetContent className={cls.left}>
            <div className={cls.logo} />

            <UikFormInputGroup style={{ marginTop: 24 }}>
              <UikInput ref={emailInputEl} label="E-mail" name="email" placeholder="your@email.com" />
              <Snackbar open={alertBoolen} autoHideDuration={6000} onClose={handleSnackbarClose}>
                <MuiAlert onClose={handleSnackbarClose} severity="success">
                  Mail Başarıyla Gönderildi.
                </MuiAlert>
              </Snackbar>
            </UikFormInputGroup>
            <UikDivider margin />

            <UikButton
              className={loading ? cls.btnDisable : cls.btnAction}
              isLoading={loading}
              success
              onClick={() => {
                handleOnSubmitClick();
              }}
            >
              Yenileme Linki Gönder
            </UikButton>
          </UikWidgetContent>
          <div className={cls.right}>
            <h3 className={cls.headline}>
              <Uikon>rocket</Uikon>
              Lorem Ipsum
            </h3>
            <p className={cls.desc}>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Fuga similique vitae labore quis reiciendis
              voluptate, saepe cumque perferendis.
            </p>
          </div>
        </div>
      </UikWidget>
    </div>
  );
}

export default PasswordReset;
