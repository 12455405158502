import 'react-image-lightbox/style.css';

import { DELETE_UPDATE, EDIT_UPDATE } from 'graphql/mutations';
import { UikAvatar, UikButton, UikDivider, UikDropdown, UikDropdownItem, UikInput, Uikon } from '@uik';

import CriticalMutation from 'utils/CriticalMutation';
import { GET_UPDATE_WITH_LIKES_AND_COMMENTS } from 'graphql/queries';
import { Grid } from '@material-ui/core';
import Lightbox from 'react-image-lightbox';
import { Link } from 'react-router-dom';
import ModifiableSnackbar from 'utils/ModifiableSnackbar';
import cls from './social-post-comment.module.scss';
import { duser } from '@exampleAssets';
import get from 'lodash/get';
import { getFormattedDate } from 'utils/helpers';
import { useMutation } from '@apollo/client';
import { useState } from 'react';

function PostComment({ currentUserId, comm, parentUpdateId }) {
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [beingEdited, setBeingEdited] = useState(false);
  const [editAlert, setEditAlert] = useState(false);
  const [newContent, setNewContent] = useState('');
  const [criticalMutation, setCriticalMutation] = useState(false);

  //gql
  const [editUpdate] = useMutation(EDIT_UPDATE, {
    variables: { id: comm?.id },
  });

  const [deletePost] = useMutation(DELETE_UPDATE, {
    refetchQueries: [{ query: GET_UPDATE_WITH_LIKES_AND_COMMENTS, variables: { id: parentUpdateId } }],
  });

  //handlers
  const handleClickImg = index => {
    setIsOpen(true);
    setPhotoIndex(index);
  };

  const handleKeyDown = async event => {
    if (event.key !== 'Enter') return;

    event.preventDefault();
    setBeingEdited(false);

    if (!newContent || newContent === '') {
      return;
    }

    const { data, error } = await editUpdate({ variables: { content: newContent } });
    if (data?.editUpdate) {
      setEditAlert('Success');
    } else if (error) {
      setEditAlert('Error');
      console.error(error);
    } else {
      console.error(data);
    }
  };

  return (
    <div className={cls.wrapper}>
      <Grid container>
        <Grid container key={comm?.id}>
          <Grid container item justify="space-between">
            <UikAvatar
              className={cls.avatar}
              imgUrl={comm?.user?.profilePhoto || duser}
              name={
                <span className={cls.nameText}>
                  <Link to={`/user/${comm?.user?.id}`}>
                    <strong>
                      {get(comm, 'user.firstName', '')} {get(comm, 'user.lastName', '')}
                    </strong>
                  </Link>
                </span>
              }
              textBottom={getFormattedDate(comm?.postedAt) || ''}
            />
            {comm?.user?.id === currentUserId && (
              <UikDropdown
                DisplayComponent={props => <UikButton clear icon={<Uikon>more</Uikon>} iconOnly {...props} />}
                position="bottomRight"
              >
                <UikDropdownItem
                  onClick={() => {
                    setNewContent(comm?.content);
                    setBeingEdited(true);
                  }}
                >
                  {'Yorumu Düzenle'}
                </UikDropdownItem>
                <UikDropdownItem onClick={() => setCriticalMutation(true)}>{'Yorumu Sil'}</UikDropdownItem>
              </UikDropdown>
            )}
            {criticalMutation && (
              <CriticalMutation
                title="Gönderi Silinecek..."
                mutation={deletePost}
                options={{ variables: { id: comm?.id } }}
                closeComponent={() => setCriticalMutation(false)}
              >
                <h4>Gönderiyi Silmek İstediğinize Emin Misiniz?</h4>
              </CriticalMutation>
            )}
          </Grid>

          <Grid container style={{ margin: '10px 50px' }}>
            {beingEdited ? (
              <UikInput
                value={newContent}
                onChange={() => setNewContent(event.target.value)}
                onKeyDown={event => handleKeyDown(event)}
              />
            ) : (
              <p style={{ margin: '0px' }}> {comm?.content}</p>
            )}
          </Grid>
          <Grid container style={{ marginLeft: '50px' }}>
            {comm?.images?.length > 0 &&
              comm.images.slice(0, 3).map((img, index) => (
                <Grid
                  key={index}
                  item
                  md={3}
                  onClick={() => handleClickImg(index)}
                  xs={6}
                  className={comm.images.length === 1 ? cls.singlePhoto : cls.multiPhoto}
                  style={{
                    backgroundImage: `${
                      index === 2 && comm.images.length > 3
                        ? `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${img})`
                        : `url(${img})`
                    }`,
                    cursor: 'pointer',
                    margin: '0px 10px 5px 0px',
                    width: '100%',
                  }}
                >
                  {index === 2 && (
                    <div className={cls.morePhoto}>
                      <Uikon
                        style={{
                          fontSize: '20px',
                        }}
                      >
                        add
                      </Uikon>
                      {` ${comm.images.length - 3}`}
                    </div>
                  )}
                </Grid>
              ))}
            {isOpen && comm?.images && (
              <Lightbox
                mainSrc={comm?.images[photoIndex]}
                nextSrc={comm?.images[(photoIndex + 1) % comm.images.length]}
                prevSrc={comm?.images[(photoIndex + comm.images.length - 1) % comm.images.length]}
                onCloseRequest={() => setIsOpen(false)}
                onMovePrevRequest={() => setPhotoIndex((photoIndex + comm.images.length - 1) % comm.images.length)}
                onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % comm.images.length)}
              />
            )}
          </Grid>

          <Grid container style={{ marginBottom: '20px', marginTop: '20px' }}>
            <UikDivider />
          </Grid>
          <ModifiableSnackbar condition={editAlert === 'Success'} setter={setEditAlert} severity="success">
            Yorumu başarıyla düzenlediniz.
          </ModifiableSnackbar>
          <ModifiableSnackbar condition={editAlert === 'Error'} setter={setEditAlert} severity="error">
            Yorumu düzenlerken bir hata oluştu.
          </ModifiableSnackbar>
        </Grid>
      </Grid>
    </div>
  );
}

export default PostComment;
