import { Link } from 'react-router-dom';
import React from 'react';
import { UikAvatar } from '@uik';

function Members({ array, photo }) {
  return (
    array?.map(item => (
      <React.Fragment key={item.id}>
        <Link
          to={`/user/${item?.id}`}
          style={{
            alignItems: 'center',
            border: '1px solid #EAEDF3',
            borderRadius: '4px',
            display: 'flex',
            justifyContent: 'space-between',
            padding: '20px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          <UikAvatar
            highlighted
            imgUrl={item?.profilePhoto || photo}
            name={<div style={{ color: '#3E3F42' }}>{`${item?.firstName} ${item?.lastName}`}</div>}
            size="large"
          />
        </Link>
      </React.Fragment>
    )) || null
  );
}

export default Members;
