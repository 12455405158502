import * as React from 'react';

import { FormLabel, Grid } from '@material-ui/core';
import { UikAvatar, UikButton, UikCheckbox, UikFormInputGroup, UikInput, Uikon } from '@uik';

import { Field } from 'formik';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import cls from '../../buildings-signup.module.scss';
import { fileChangedHandler } from 'utils/helpers';

export default function StudentForm({ isMasters, setIsMasters, setProfilePhoto, errors }) {
  const [select, setSelect] = React.useState(10);
  const [photoShowcase, setPhotoShowcase] = React.useState('');

  return (
    <>
      <h1 className={cls.title + ' ' + cls.title2}>Öğrenci</h1>
      <UikFormInputGroup direction="horizontal" className={cls.inputGroup} style={{ marginTop: '0', width: '95%' }}>
        <Grid item xs={12} sm={6}>
          <FormLabel className={cls.label1} component="legend">
            Öğrenci Numarası
          </FormLabel>

          <Field
            as={UikInput}
            className={cls.eposta}
            errorMessage={errors.studentNo}
            name="studentNo"
            type="text"
            placeholder="1021324354"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormLabel className={cls.label2} component="legend">
            Doğum Tarihi
          </FormLabel>

          <Field
            as={UikInput}
            className={cls.sifre}
            errorMessage={''}
            errorMessage={errors.birthDate}
            name="birthDate"
            placeholder="·····"
            type="date"
          />
        </Grid>
        <Grid item xs={12}>
          <FormLabel className={cls.label1} component="legend">
            Lisans Derecesi
          </FormLabel>
          <Select
            displayEmpty
            disableUnderline
            value={isMasters}
            className={cls.select}
            onChange={event => setIsMasters(event.target.value)}
          >
            <MenuItem value="" disabled>
              Seçiniz
            </MenuItem>
            <MenuItem value={false}>Lisans</MenuItem>
            <MenuItem value={true}>Yüksek Lisans</MenuItem>
          </Select>
          {isMasters && (
            <div>
              <UikFormInputGroup
                direction="horizontal"
                className={cls.inputGroup}
                style={{ marginTop: '0', width: '95%' }}
              >
                <Grid item xs={12}>
                  <FormLabel className={cls.label1} component="legend">
                    Önceki Lisanslar
                  </FormLabel>

                  <Select
                    displayEmpty
                    disableUnderline
                    value={select}
                    style={{ width: '52.5%' }}
                    className={cls.select}
                  >
                    <MenuItem value="" disabled>
                      Seçiniz
                    </MenuItem>
                    <MenuItem value={10}>2</MenuItem>
                    <MenuItem value={20}>1</MenuItem>
                  </Select>
                </Grid>
                <Grid item xs={12}>
                  <FormLabel className={cls.label1} component="legend">
                    Önceki Lisanslar
                  </FormLabel>

                  <Select
                    displayEmpty
                    disableUnderline
                    value={select}
                    style={{ width: '52.5%' }}
                    className={cls.select}
                  >
                    <MenuItem value="" disabled>
                      Seçiniz
                    </MenuItem>
                    <MenuItem value={10}>1</MenuItem>
                    <MenuItem value={20}>2</MenuItem>
                  </Select>
                </Grid>
              </UikFormInputGroup>
            </div>
          )}
        </Grid>
        <Grid container justify="center" xs={12}>
          <Grid item xs={12} sm={6}>
            <FormLabel className={cls.label1} component="legend">
              Bir Fotoğraf Ekle
            </FormLabel>

            <React.Fragment>
              <input
                type="file"
                name="avatar"
                onChange={input => fileChangedHandler(input, setPhotoShowcase, setProfilePhoto)}
                style={{ display: 'none' }}
                id="contained-button-file"
              />
              <label htmlFor="contained-button-file">
                <UikButton
                  variant="contained"
                  Component="span"
                  icon={<Uikon className={cls.iconPhoto}>camera</Uikon>}
                  transparent
                >
                  {'Dosya Seç'}
                </UikButton>
              </label>
            </React.Fragment>
          </Grid>
          <Grid item xs={12} style={{ marginTop: '10px' }} sm={6}>
            <UikAvatar
              style={{ justifyContent: 'center' }}
              avatarPlaceholder={{
                content: <Uikon className={cls.iconCamera}>camera</Uikon>,
              }}
              imgUrl={photoShowcase}
              size="jumbo"
            />
          </Grid>
        </Grid>
      </UikFormInputGroup>
      <div style={{ display: 'flex', alignItems: 'center', marginTop: '30px' }}>
        <Field as={UikCheckbox} errorMessage={errors.tos} name="tos" />
        <label htmlFor="tos" className={cls.tos}>
          <a>Kullanım Koşullarını</a>, <a>Site Koşullarını</a> ve <a>Gizlilik Politikasını</a> onaylıyorum.
        </label>
      </div>
      <div style={{ display: 'flex', alignItems: 'center', marginTop: '20px' }}>
        <span style={{ color: 'red', textAlign: 'center' }}>{errors.tos}</span>
      </div>
    </>
  );
}
