/* eslint-disable max-len */

import * as React from 'react';

import { Link, Redirect } from 'react-router-dom';
import {
    UikCheckbox,
    UikDivider,
    UikFormInputGroup,
    UikInput,
    UikWidget,
    UikWidgetContent,
    Uikon
} from '@uik';
import { apple, facebook, google, linkedin } from '@exampleAssets';

import { AUTHENTICATE_USER_MUTATION } from '../../graphql/mutations';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import cls from './buildings-signup.module.scss';
import { get } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import { useMutation } from '@apollo/client';

const useStyles = makeStyles(theme => ({
  root2: {
    flexGrow: 1,
    backgroundColor: '#000',
  },
}));

function Login2(props) {
  //States
  const [loading, setLoading] = React.useState(false);
  //Refs
  const emailInputEl = React.useRef(null);
  const passwordInputEl = React.useRef(null);
  //GQL
  const [authenticateUser, { data, error }] = useMutation(AUTHENTICATE_USER_MUTATION);
  //Handlers
  const handleOnClick = async () => {
    try {
      setLoading(true);
      const email = get(emailInputEl, 'current.input.value', null);
      const password = get(passwordInputEl, 'current.input.value', null);
      const json = await authenticateUser({ variables: { email: email, password: password } });
      const token = get(json, 'data.authenticate.token', null);
      const id = get(json, 'data.authenticate.user.id', null);
      if (token) {
        localStorage.setItem('login', true);
        localStorage.setItem('x-auth-token', token);
        localStorage.setItem('my-id', id);
        window.dispatchEvent(new Event('storage'));
      }

      /*
      window.setTimeout(() => {
        if (localStorage.getItem('login')) {
          props.history.push('/');
        } else {
          setLoading(false);
        }
      }, 8000);
      */
    } catch (err) {
      console.error(err);
    }
    setLoading(false);
  };
  const classes = useStyles();

  return (
    <div>
      {localStorage.getItem('x-auth-token') && <Redirect to="/" />}
      <UikWidget className={cls.widgetWrapper}>
        <div className={cls.content}>
          <div className={cls.right}>
            <div className={cls.top}>
              <div className={cls.logo} />
            </div>
            <h3 className={cls.headline}>Yaşar Üniversitesi </h3>
          </div>
          <UikWidgetContent className={cls.left}>
            <Grid container direction="row" justify="center" alignItems="center">
              <Container maxWidth="sm">
                <div className={cls.root2}>
                  <h1 className={cls.title}>Alumuni'a hoş geldin</h1>
                  <div className={cls.routerLink}>
                    Henüz hesabın yok mu?
                    <Link to="/register">
                      <span className={cls.forgotLink}>Kayıt ol</span>
                    </Link>
                  </div>
                  <Grid container spacing={0}>
                    <Grid item xs={12} sm={6} style={{ marginTop: 0, width: '100%' }}>
                      <Button className={cls.google + ' ' + cls.btn}>
                        <Uikon className={cls.uikon} mr>
                          <img src={google} alt="" srcset="" />
                        </Uikon>{' '}
                        Google ile giriş yap
                      </Button>
                    </Grid>

                    <Grid item xs={12} sm={6} style={{ marginTop: 0, width: '100%' }}>
                      <Button className={cls.apple + ' ' + cls.btn}>
                        <Uikon className={cls.uikon} mr>
                          <img src={apple} alt="" srcset="" />
                        </Uikon>
                        Apple ile giriş yap
                      </Button>
                    </Grid>

                    <Grid item xs={12} sm={6} style={{ marginTop: 24, width: '100%' }}>
                      <Button className={cls.facebook + ' ' + cls.btn}>
                        <Uikon className={cls.uikon} mr>
                          <img src={facebook} alt="" srcset="" />
                        </Uikon>
                        Facebook ile giriş yap
                      </Button>
                    </Grid>

                    <Grid item xs={12} sm={6} style={{ marginTop: 24, width: '100%' }}>
                      <Button className={cls.linkedin + ' ' + cls.btn}>
                        <Uikon className={cls.uikon} mr>
                          <img src={linkedin} alt="" srcset="" />
                        </Uikon>
                        Linkedin ile giriş yap
                      </Button>
                    </Grid>
                    <UikDivider margin style={{ marginTop: '46px', marginBottom: '46px' }} />

                    <UikFormInputGroup
                      direction="horizontal"
                      className={cls.inputGroup}
                      style={{ marginTop: '0', width: '95%' }}
                    >
                      <Grid item xs={12} sm={6}>
                        <FormLabel className={cls.label1} component="legend">
                          E-Posta
                        </FormLabel>

                        <UikInput
                          className={cls.eposta}
                          errorMessage={error == 'Error: No such user.' && `${error}`}
                          ref={emailInputEl}
                          //label="E-Posta"
                          name="email"
                          placeholder="your@emails.com"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormLabel className={cls.label2} component="legend">
                          Şifre
                        </FormLabel>

                        <UikInput
                          className={cls.sifre}
                          errorMessage={error == 'Error: Wrong password.' && `${error}`}
                          ref={passwordInputEl}
                          //label="Şifre"
                          name="password"
                          placeholder="·····"
                          type="password"
                        />
                      </Grid>
                    </UikFormInputGroup>

                    <UikCheckbox className={cls.checkbox} label="Beni hatırla" />

                    <Button
                      className={loading ? cls.btnDisable : cls.btnAction + ' ' + cls.giris}
                      isLoading={loading}
                      onClick={() => {
                        handleOnClick();
                      }}
                      style={{ textTransform: 'none' }}
                    >
                      Giriş Yap
                    </Button>

                    <div className={cls.forgotPassword}>
                      Şifreni mi unuttun?
                      <Link to="/password-reset">
                        <span className={cls.forgotLink}>Şifremi Unuttum</span>
                      </Link>
                    </div>
                  </Grid>
                </div>
              </Container>
            </Grid>
          </UikWidgetContent>
        </div>
      </UikWidget>
    </div>
  );
}

export default Login2;
