/* eslint-disable max-len */

import * as React from 'react';

import { Link, Redirect } from 'react-router-dom';
import { UikButton, UikDivider, UikFormInputGroup, UikInput, UikWidget, UikWidgetContent, Uikon } from '@uik';

import { AUTHENTICATE_USER_MUTATION } from '../../graphql/mutations';
import cls from './buildings-signup.module.scss';
import { get } from 'lodash';
import { useMutation } from '@apollo/client';

function Login(props) {
  //States
  const [loading, setLoading] = React.useState(false);
  //Refs
  const emailInputEl = React.useRef(null);
  const passwordInputEl = React.useRef(null);
  //GQL
  const [authenticateUser, { data, error }] = useMutation(AUTHENTICATE_USER_MUTATION);
  //Handlers
  const handleOnClick = async () => {
    try {
      setLoading(true);
      const email = get(emailInputEl, 'current.input.value', null);
      const password = get(passwordInputEl, 'current.input.value', null);
      const json = await authenticateUser({ variables: { email: email, password: password } });
      const token = get(json, 'data.authenticate.token', null);
      const id = get(json, 'data.authenticate.user.id', null);
      if (token) {
        localStorage.setItem('login', true);
        localStorage.setItem('x-auth-token', token);
        localStorage.setItem('my-id', id);
        window.dispatchEvent(new Event('storage'));
      }

      /*
      window.setTimeout(() => {
        if (localStorage.getItem('login')) {
          props.history.push('/');
        } else {
          setLoading(false);
        }
      }, 8000);
      */
    } catch (err) {
      console.error(err);
    }
    setLoading(false);
  };

  return (
    <div className={cls.pageWrapper}>
      {localStorage.getItem('x-auth-token') && <Redirect to="/" />}
      <UikWidget className={cls.widgetWrapper}>
        <div className={cls.content}>
          <UikWidgetContent className={cls.left}>
            <div className={cls.logo} />

            <UikFormInputGroup style={{ marginTop: 24 }}>
              <UikInput
                errorMessage={error == 'Error: No such user.' && `${error}`}
                ref={emailInputEl}
                label="E-mail"
                name="email"
                placeholder="ornek@email.com"
              />
              <UikInput
                errorMessage={error == 'Error: Wrong password.' && `${error}`}
                ref={passwordInputEl}
                label="Password"
                name="password"
                placeholder="·····"
                type="password"
              />
              <Link to="/password-reset">
                <p className={cls.desc}>Şifremi Unuttum</p>
              </Link>
            </UikFormInputGroup>

            <UikDivider margin />

            <UikButton
              className={loading ? cls.btnDisable : cls.btnAction}
              success
              isLoading={loading}
              onClick={() => {
                handleOnClick();
              }}
            >
              Giriş Yap
            </UikButton>
          </UikWidgetContent>
          <div className={cls.right}>
            <h3 className={cls.headline}>
              <Uikon>building</Uikon>
              Buildings
            </h3>
            <p className={cls.desc}>
              Build an online community for your alumni to increase engagement and drive more donations.
            </p>
          </div>
        </div>
      </UikWidget>
    </div>
  );
}

export default Login;
