import * as React from 'react';

import { Link } from 'react-router-dom';
import { UikAvatar } from '@uik';
import classnames from 'classnames';
import cls from './social-friend-item.module.scss';

// @flow





const UikSocialFriendList = ({
  className,
  Component,
  children,
  userID,
  data: { linkTo, name, imgUrl, textBottom },
  ...rest
}) => (
  <Link to={linkTo}>
    <Component className={classnames(cls.wrapper, className)} {...rest}>
      <UikAvatar
        highlighted
        imgUrl={imgUrl}
        name={<div style={{ color: '#3E3F42' }}>{name}</div>}
        size="large"
        textBottom={textBottom}
      />
    </Component>
  </Link>
);

UikSocialFriendList.defaultProps = {
  className: null,
  Component: 'div',
  children: null,
  userID: 1,
};

export default UikSocialFriendList;
