import * as images from '@exampleAssets';

import { GET_JOB_DETAİL, USER_DETAIL_MY_PROFILE_QUERY, GET_JOBS_INDUSTRY } from 'graphql/queries';
import { Link, useParams } from 'react-router-dom';
import {
  UikAvatar,
  UikButton,
  UikContainerHorizontal,
  UikContainerVertical,
  UikContent,
  UikContentTitle,
  UikDivider,
  UikFormInputGroup,
  UikInput,
  UikLayoutMain,
  UikTag,
  UikTagContainer,
  UikWidget,
  Uikon,
  UikonText,
} from '@uik';
import { dcompany, duser } from '@exampleAssets';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import React from 'react';
import cls from './buildings-users.module.scss';
import get from 'lodash/get';
import moment from 'moment';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';

//gql

// import Navigation from "../Home/components/Navigation.js";
function JobsDetail(props) {
  const { id: paramId } = useParams();
  const [apply, setApply] = React.useState(false);
  const [applyOpen, setApplyOpen] = React.useState(false);
  const [email, setEmail] = React.useState('');
  const [phone, setPhone] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [resume, setResume] = React.useState('');
  const [lastModified, setLastModified] = React.useState('');
  //var [listOfJobIndustry] = React.useState('');

  const similarJobs = [
    {
      title: 'Internship',
      companyName: 'UniverList',
      imgUrl: images.j01,
    },
    {
      title: 'Software Engineer',
      companyName: 'Google',
      imgUrl: images.j02,
    },
    {
      title: 'Frontend Developer',
      companyName: 'Izmir',
      imgUrl: images.j03,
    },
  ];
  const fileChangedHandler = event => {
    var reader = new FileReader();
    setResume(event.target.files[0].name);
    setLastModified(moment(event.target.files[0].lastModified).format('DD MMM YYYY hh:mm a'));

    reader.onloadend = function(event) {
      //setResume(event.target.files[0].name);
    };
    if (event.target.files[0]) {
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  const handleApply = () => {
    try {
      setLoading(true);
      window.setTimeout(() => {
        setApply(true);
        setApplyOpen(false);
      }, 3000);
    } catch (err) {
      console.log(err);
    }
  };

  const { t, i18n } = useTranslation();
  //const { id: paramId } = useParams();

  //gql
  const { data } = useQuery(GET_JOB_DETAİL, {
    variables: { id: paramId },
  });

  const jobsDetail = data?.getJobDetail;

  //My profile
  const { loading: MyProfileLoading, error: MyProfileError, data: MyProfileData } = useQuery(
    USER_DETAIL_MY_PROFILE_QUERY,
  );

  var listIndustry = data?.getJobDetail?.industry;

  // job industry
  const { loading: jobsIndustryLoading, error: jobsIndustryError, data: jobsIndustryData } = useQuery(
    GET_JOBS_INDUSTRY,
    {
      variables: { industry: listIndustry || '' },
    },
  );

  const jobsIndustry = jobsIndustryData?.filterJobsByIndustry;

  const defaultProfilePhoto = duser;
  const defaultCompanyLogo = dcompany;

  return (
    <UikContainerVertical>
      {console.log(data?.getJobDetail?.industry)}
      <UikContainerHorizontal>
        <UikLayoutMain className={cls.content} contentCenter>
          <UikWidget margin>
            <div>
              <UikContent>
                <React.Fragment>
                  <UikAvatar
                    highlighted
                    imgUrl={jobsDetail?.logo || defaultCompanyLogo}
                    name={get(jobsDetail, 'title', '')}
                    size="jumbo"
                    textBottom={
                      <React.Fragment>
                        {get(jobsDetail, 'companyName', '')}
                        <div className={cls.detailReviews}>
                          <UikButton success onClick={() => setApplyOpen(true)} disabled={apply}>
                            {t('Apply')}
                          </UikButton>
                        </div>
                        {applyOpen && (
                          <Dialog
                            onClose={() => setApplyOpen(false)}
                            aria-labelledby="customized-dialog-title"
                            open={applyOpen}
                            style={{ width: '100%' }}
                          >
                            <div
                              style={{
                                fontSize: '20px',
                                color: '#3E3F42',
                                lineHeight: '1.125rem',
                                fontWeight: '500',
                                padding: 20,
                              }}
                            >
                              {'Başvuru ' + get(jobsDetail, 'companyName', '')}
                            </div>

                            <DialogContent dividers>
                              <UikContentTitle> {'İletİşİm Bİlgİlerİnİz'}</UikContentTitle>
                              <div className={cls.wrapper}>
                                {/* LoggedIn user comes here !!!  */}
                                <UikAvatar
                                  className={cls.avatar}
                                  imgUrl={MyProfileData?.myProfile.profilePhoto || defaultProfilePhoto}
                                  name={
                                    <span className={cls.nameText}>
                                      <Link to={`/user/${MyProfileData?.myProfile.id}`}>
                                        <strong>
                                          {MyProfileData?.myProfile.firstName + ' ' + MyProfileData?.myProfile.lastName}
                                        </strong>
                                      </Link>{' '}
                                      {/* <span className={cls.text}>Yazılım Mühendisi, Alumni</span> */}
                                    </span>
                                  }
                                  textBottom={
                                    MyProfileData?.myProfile?.city?.title +
                                    '/' +
                                    MyProfileData?.myProfile.country?.title
                                  }
                                />
                              </div>
                              <div>
                                <UikFormInputGroup direction="horizontal">
                                  <UikInput label="Email" name="email" onChange={e => setEmail(e.target.value)} />
                                  <UikInput
                                    label={'Telefon Numaranız'}
                                    name="Phone Number"
                                    onChange={e => setPhone(e.target.value)}
                                  />
                                </UikFormInputGroup>
                              </div>
                              <div style={{ marginTop: '.5rem' }}>
                                <UikContentTitle> {'Özgeçmİşİnİz'}</UikContentTitle>
                              </div>
                              {resume && (
                                <UikWidget
                                  style={{
                                    position: 'relative',
                                    overflow: 'visible',
                                  }}
                                >
                                  <div
                                    className={cls.cross}
                                    style={{
                                      position: 'absolute',
                                      bottom: '51px',

                                      right: '-10px',
                                      cursor: 'pointer',
                                    }}
                                    onClick={() => setResume('')}
                                  >
                                    <Uikon
                                      style={{
                                        transform: 'rotate(45deg)',
                                      }}
                                    >
                                      add
                                    </Uikon>
                                  </div>
                                  <div
                                    style={{
                                      fontSize: 12,
                                      fontWeight: 500,
                                      padding: '10px 15px',
                                      marginTop: '.5rem',
                                    }}
                                  >
                                    {resume}
                                    <div className={cls.lastModified}>{lastModified}</div>
                                  </div>
                                </UikWidget>
                              )}

                              <div>
                                <input
                                  type="file"
                                  name="avatar"
                                  onChange={fileChangedHandler}
                                  style={{ display: 'none' }}
                                  id="resume-file"
                                />
                                <label htmlFor="resume-file">
                                  <UikButton
                                    variant="contained"
                                    Component="span"
                                    style={{ marginTop: '.5rem' }}
                                    icon={<Uikon>cloud_up</Uikon>}
                                    transparent
                                  >
                                    {t('Upload Resume')}
                                  </UikButton>
                                </label>
                              </div>
                            </DialogContent>
                            <DialogActions>
                              <UikButton success isLoading={loading} onClick={() => handleApply()}>
                                {'Başvur'}
                              </UikButton>
                            </DialogActions>
                          </Dialog>
                        )}
                      </React.Fragment>
                    }
                  />
                  <div className={cls.basicsWrapper}>
                    <UikContentTitle>{'Genel Bİlgİler'}</UikContentTitle>
                    <div className={cls.basics}>
                      <UikonText icon={<Uikon>message_2</Uikon>} textHighlight>
                        {get(jobsDetail, 'applyWithEmail', '')
                          ? get(jobsDetail, 'applyWithEmail', '')
                          : get(jobsDetail, 'applyWithWeb', '')}
                      </UikonText>
                      <UikonText icon={<Uikon>calendar</Uikon>} textHighlight>
                        {get(jobsDetail, 'endDate', '')}
                      </UikonText>
                      <UikonText icon={<Uikon>pin_rounded_circle</Uikon>} textHighlight>
                        {get(jobsDetail, 'city.title', '')}
                      </UikonText>
                    </div>
                  </div>
                  <div className={cls.bioWrapper}>
                    <UikContentTitle>{'Detaylı Açıklama'}</UikContentTitle>
                    <p style={{ wordBreak: 'break-all' }}>{get(jobsDetail, 'description', '')}</p>
                  </div>
                  <div className={cls.tagsWrapper}>
                    <UikContentTitle>{'İş Özellikleri'}</UikContentTitle>
                    <UikTagContainer className={cls.tags}>
                      {get(jobsDetail, 'jobFunction', []).map((jf, index) => (
                        <UikTag
                          key={index}
                          color={index % 3 === 0 ? 'green' : index % 3 === 1 ? 'orange' : index % 3 === 2 ? 'blue' : ''}
                          fill
                        >
                          {jf.label}
                        </UikTag>
                      ))}
                    </UikTagContainer>
                  </div>

                  <div className={cls.basics}>
                    <div>
                      <UikContentTitle>{'Deneyİm'}</UikContentTitle>
                      <p>{get(jobsDetail, 'level', 'Entry level')}</p>
                    </div>
                    <div>
                      <UikContentTitle>{'İstİhdam Tİpİ'}</UikContentTitle>
                      <p>{get(jobsDetail, 'workType', 'Full-time')}</p>
                    </div>
                    <div>
                      <UikContentTitle>{'EndÜstrİ'}</UikContentTitle>
                      <p>{get(jobsDetail, 'industry', 'Unspecified')}</p>
                    </div>
                  </div>

                  <UikDivider margin />

                  <div className={cls.associated}>
                    <div className={cls.sectionHeader}>
                      <UikContentTitle>{'Benzer İşler'}</UikContentTitle>
                      <Link to={'/jobs'}>
                        <span className={cls.seeAll}>{t('See All')}</span>
                      </Link>
                    </div>
                    <div className={cls.assocBuildingsList}>
                      {jobsIndustry?.slice(0, 9).map(b => (
                        <Link to={`/jobs/${b.id}`}>
                          <div className={cls.assocBuilding}>
                            <div
                              className={cls.image}
                              style={{ backgroundImage: `url(${b.logo || defaultCompanyLogo})` }}
                            />
                            <h5 className={cls.title}>{b.title}</h5>
                            <p className={cls.location}>{b.companyName}</p>
                          </div>
                        </Link>
                      ))}
                    </div>
                  </div>

                  <UikDivider margin />
                  {/* <div>
          <div className={cls.sectionHeader}>
            <UikContentTitle>Member of teams (5)</UikContentTitle>
            <span className={cls.seeAll}>See All</span>
          </div>
          <div className={cls.teams}>
            {teams.map(t => (
              <div className={cls.team}>
                <div
                  className={cls.image}
                  style={{ backgroundImage: `url(${t.imgUrl})` }}
                />
                <div className={cls.content}>
                  {t.isOwner && (
                    <UikTag color="white" fill>
                      OWNER
                    </UikTag>
                  )}
                  <h5 className={cls.title}>{t.name}</h5>
                  <p className={cls.membersCount}>
                    {t.membersCount}
                    {" members"}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
        <UikDivider margin />
        <div>
          <div className={cls.sectionHeader}>
            <UikContentTitle>Reviews (18)</UikContentTitle>
            <span className={cls.seeAll}>See All</span>
          </div>

          <UikAvatar
            highlighted
            imgUrl={images.a02}
            name="Alice Townsend"
            textBottom="I've had the pleasure to work with Angelina on a number of projects requiring quality check. It's not easy communicating project requirements, especially aesthetics, when your design work is being done remotely. "
          />
        </div>
      */}
                </React.Fragment>
              </UikContent>
            </div>
            {/* <div className={cls.menuWrapper}>
            <UikContent>
              <UikWidget>
                <UikWidgetHeader>Navigation</UikWidgetHeader>
                {sidebarNavigation.map(item => (
                  <UikWidgetLink key={item.title} {...item} />
                ))}
              </UikWidget>
            </UikContent>
          </div> */}
          </UikWidget>
        </UikLayoutMain>
      </UikContainerHorizontal>
    </UikContainerVertical>
  );
}

export default JobsDetail;
