import { DELETE_UPDATE, EDIT_UPDATE } from 'graphql/mutations';
import { UikAvatar, UikButton, UikDropdown, UikDropdownItem, UikInput, Uikon } from '@uik';

import CriticalMutation from 'utils/CriticalMutation';
import { GET_PERSONAL_FEED } from 'graphql/queries';
import { Link } from 'react-router-dom';
import ModifiableSnackbar from 'utils/ModifiableSnackbar';
import cls from './social-post-header.module.scss';
import { duser } from '@exampleAssets';
import { getFormattedDate } from 'utils/helpers';
import { useMutation } from '@apollo/client';
import { useState } from 'react';

const SocialPostHeader = ({ user, postDate, isMine, content, updateId }) => {
  const [beingEdited, setBeingEdited] = useState(false);
  const [editAlert, setEditAlert] = useState(false);
  const [newContent, setNewContent] = useState('');
  const [criticalMutation, setCriticalMutation] = useState(false);

  //gql
  const [editUpdate] = useMutation(EDIT_UPDATE, {
    variables: { id: updateId },
  });
  const [deletePost] = useMutation(DELETE_UPDATE, {
    variables: { id: updateId },
    refetchQueries: [{ query: GET_PERSONAL_FEED, variables: { limit: 50 } }],
  });

  //handlers
  const handleKeyDown = async event => {
    if (event.key !== 'Enter') return;

    event.preventDefault();
    setBeingEdited(false);

    if (!newContent || newContent === '') {
      return;
    }

    const { data, error } = await editUpdate({ variables: { content: newContent } });
    if (data?.editUpdate) {
      setEditAlert('Success');
    } else if (error) {
      setEditAlert('Error');
      console.error(error);
    } else {
      console.error(data);
    }
  };

  return (
    <>
      <div className={cls.wrapper}>
        <UikAvatar
          className={cls.avatar}
          imgUrl={user?.profilePhoto || duser}
          name={
            <span className={cls.nameText}>
              <Link to={'/user/' + user?.id}>
                <strong>
                  {user?.firstName || ''} {user?.lastName || ''}
                </strong>
              </Link>
              <span className={cls.text}>
                {` ${user?.career?.jobTitle || ''} `} {`${user?.career?.company || ''} `}
              </span>
            </span>
          }
          textBottom={getFormattedDate(postDate) || ''}
        />
        {isMine && (
          <UikDropdown
            DisplayComponent={props => <UikButton clear icon={<Uikon>more</Uikon>} iconOnly {...props} />}
            position="bottomRight"
          >
            <UikDropdownItem
              onClick={() => {
                setNewContent(content);
                setBeingEdited(true);
              }}
            >
              {'Düzenle'}
            </UikDropdownItem>
            <UikDropdownItem onClick={() => setCriticalMutation(true)}>{'Gönderiyi sil'}</UikDropdownItem>
          </UikDropdown>
        )}
        {criticalMutation && (
          <CriticalMutation
            title="Gönderi Silinecek..."
            mutation={deletePost}
            closeComponent={() => setCriticalMutation(false)}
          >
            <h4>Gönderiyi Silmek İstediğinize Emin Misiniz?</h4>
          </CriticalMutation>
        )}
      </div>
      {beingEdited ? (
        <UikInput
          value={newContent}
          onChange={() => setNewContent(event.target.value)}
          onKeyDown={event => handleKeyDown(event)}
        />
      ) : (
        <p> {content}</p>
      )}
      <ModifiableSnackbar condition={editAlert === 'Success'} setter={setEditAlert} severity="success">
        Gönderiyi başarıyla düzenlediniz.
      </ModifiableSnackbar>
      <ModifiableSnackbar condition={editAlert === 'Error'} setter={setEditAlert} severity="error">
        Gönderiyi düzenlerken bir hata oluştu.
      </ModifiableSnackbar>
    </>
  );
};

export default SocialPostHeader;
