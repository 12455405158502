import MuiAlert from '@material-ui/lab/Alert';
import React from 'react';
import { Snackbar } from '@material-ui/core';

function ModifiableSnackbar({ condition, setter, message, severity, children }) {
  function handleSnackbarClose(event, reason) {
    if (reason === 'clickaway') return;
    setter('');
  }

  return (
    <Snackbar open={condition} autoHideDuration={6000} onClose={handleSnackbarClose}>
      <MuiAlert onClose={handleSnackbarClose} severity={severity}>
        {children || message || ''}
      </MuiAlert>
    </Snackbar>
  );
}

export default ModifiableSnackbar;
