//gql

import { ACCEPT_USER_MUTATION, REJECT_USER_MUTATION } from '../../../graphql/mutations';
import { UikAvatar, UikButton, UikButtonGroup, Uikon } from '@uik';

import { GROUP_DETAIL_QUERY } from '../../../graphql/queries';
import { Link } from 'react-router-dom';
import React from 'react';
import { useMutation } from '@apollo/client';

function PendingMembers({ array, photo, groupId, setSuccessAlert, setErrorAlert }) {
  //gql
  //mutations
  const [acceptMember] = useMutation(ACCEPT_USER_MUTATION, {
    variables: { groupId: groupId },
    refetchQueries: [{ query: GROUP_DETAIL_QUERY, variables: { id: groupId } }],
  });
  const [rejectMember] = useMutation(REJECT_USER_MUTATION, {
    variables: { groupId: groupId },
    refetchQueries: [{ query: GROUP_DETAIL_QUERY, variables: { id: groupId } }],
  });
  return (
    array?.map(item => (
      <React.Fragment key={item.id}>
        <div
          style={{
            alignItems: 'center',
            border: '1px solid #EAEDF3',
            borderRadius: '4px',
            display: 'flex',
            justifyContent: 'space-between',
            padding: '20px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          <Link to={`/user/${item?.id}`}>
            <UikAvatar
              highlighted
              imgUrl={item?.profilePhoto || photo}
              name={<div style={{ color: '#3E3F42' }}>{`${item?.firstName} ${item?.lastName}`}</div>}
              size="large"
            />
          </Link>
          <UikButtonGroup>
            <UikButton
              onClick={async () => {
                const { data } = await acceptMember({ variables: { userId: item?.id } });
                if (data.acceptMember) {
                  setSuccessAlert(true);
                } else {
                  setErrorAlert(true);
                }
              }}
              square
              success
              style={{ minWidth: '60px' }}
            >
              <Uikon>check</Uikon>
            </UikButton>
            <UikButton
              onClick={async () => {
                const { data } = await rejectMember({ variables: { userId: item?.id } });
                if (data.rejectMember) {
                  setSuccessAlert(true);
                } else {
                  setErrorAlert(true);
                }
              }}
              square
              error
              style={{ minWidth: '60px' }}
            >
              <Uikon style={{ transform: 'rotate(45deg)' }}>add</Uikon>
            </UikButton>
          </UikButtonGroup>
        </div>
      </React.Fragment>
    )) || null
  );
}

export default PendingMembers;
