//gql

import * as React from 'react';

import { UikDropdownItem, UikTopBar, UikTopBarMenuDivider, UikTopBarSection, UikTopBarTitle } from '@uik';

import { Link } from 'react-router-dom';
import { USER_DETAIL_MY_PROFILE_QUERY } from '../../graphql/queries';
import UikTopBarUserDropwdown from './UikTopBarUserDropdown';
import cls from './social-header.module.scss';
import { duser } from '@exampleAssets';
import { useQuery } from '@apollo/client';

function Header({ client, checkAuth }) {
  function handleLogout() {
    localStorage.removeItem('login');
    localStorage.removeItem('x-auth-token');
    localStorage.removeItem('my-id');
    window.dispatchEvent(new Event('storage'));
    //checkAuth();
    client.clearStore();
  }

  //gql
  //My profile
  const { loading: MyProfileLoading, error: MyProfileError, data: MyProfileData } = useQuery(
    USER_DETAIL_MY_PROFILE_QUERY,
  );

  return (
    <React.Fragment>
      <UikTopBar>
        <UikTopBarSection>
          <UikTopBarTitle Component={Link} to={'/'} className={cls.logo} />
          <UikTopBarMenuDivider />
        </UikTopBarSection>
        <UikTopBarSection>
          {/* <Uikon className={cls.topBarIcon}>globe_network</Uikon> */}
          <UikTopBarUserDropwdown
            user={{
              imgUrl: MyProfileData?.myProfile?.profilePhoto || duser,
            }}
          >
            <UikDropdownItem Component={Link} to={`/user/${localStorage.getItem('my-id')}`}>
              {'Profil'}
            </UikDropdownItem>
            <Link to="/login">
              <UikDropdownItem onClick={handleLogout}>{'Çıkış Yap'}</UikDropdownItem>
            </Link>
          </UikTopBarUserDropwdown>
        </UikTopBarSection>
      </UikTopBar>
    </React.Fragment>
  );
}
export default Header;
