import { j01, pl01, dcompany } from '@exampleAssets';

import { Uikon } from '@uik';
import { getClickableLink } from 'utils/helpers';

export default function getAbout(data, isThisMyProfile) {
  let arr = [
    !data?.career?.jobTitle && !data?.career?.company
      ? null
      : {
          avatarPlaceholder: {
            color: 'lightblue',
            content: data?.career?.jobTitle
              ?.split(' ')
              ?.map((word, idx) => (idx < 2 ? word.charAt(0) : ''))
              ?.join(''),
          },

          name: (
            <span>
              <i>{data?.career?.jobTitle}</i>

              <strong> - {data?.career?.company}</strong>
            </span>
          ),
        },
    !data?.career?.company
      ? null
      : {
          imgUrl: data?.companyLogo || dcompany,
          textBottom: data?.career?.startDate || '',
          name: (
            <span>
              <strong>{data?.career?.company}</strong>
              <i>'de çalışıyor.</i>
            </span>
          ),
        },
    // {
    //   avatarPlaceholder: {
    //     color: 'lightblue',
    //     content: 'YU',
    //   },
    //   name: <i>Yasar Universitesi</i>,
    //   textBottom: 'Bilgisayar Mühendisliği',
    // },
    !data?.phoneNumber //very lame ternary operations, need to be simplified
      ? null
      : !isThisMyProfile
      ? null
      : {
          avatarPlaceholder: {
            content: <Uikon>phone</Uikon>,
          },
          name: '+' + data?.phoneNumber,
        },
    !data?.city?.title
      ? null
      : {
          imgUrl: pl01,
          textBottom: data?.city?.title,
          name: (
            <span>
              <strong>
                {data?.city?.title} / {data?.country?.title}
              </strong>{' '}
              <i>de yaşıyor.</i>
            </span>
          ),
        },
    !data?.website
      ? null
      : {
          avatarPlaceholder: {
            content: <Uikon>profile_round</Uikon>,
          },
          name: (
            <a href={getClickableLink(data?.website)} target="_blank" rel="noopener noreferrer">
              {data?.website}
            </a>
          ),
        },
  ];
  const addDetail = !isThisMyProfile
    ? null
    : {
        avatarPlaceholder: {
          content: <Uikon>edit</Uikon>,
        },
        name: <i>Profilinizi Detaylandırın</i>,
      };

  const canAddDetail = arr.filter(el => el !== null).length < arr.length;
  if (canAddDetail) arr.push(addDetail);
  return arr.filter(el => el !== null);
}
