// Libraries
//GQL

import { CHECK_ID_WITH_TOKEN_QUERY, GET_FRIENDSHIP_STATUS, GET_FRIENDS_QUERY, USER_DETAIL_MY_PROFILE_QUERY, USER_DETAIL_PUBLIC_PROFILE_QUERY } from '../../graphql/queries';
import { REMOVE_FRIEND_MUTATION, SEND_FRIEND_REQUEST_MUTATION } from '../../graphql/mutations';
import {
    UikButton,
    UikContainerHorizontal,
    UikContainerVertical,
    UikLayoutMain,
    UikSocialWidgetAbout
} from '@uik';
import { useMutation, useQuery } from '@apollo/client';

import CoverImage from './assets/images/cover.png';
import { EditMySettingsFormik } from 'utils/FormikForms/EditMySettingsFormik';
import FriendsTab from './components/Friends/FriendsTab';
import Groups from './components/Groups';
import MuiAlert from '@material-ui/lab/Alert';
import React from 'react';
import { Snackbar } from '@material-ui/core';
import Timeline from './components/TimeLine';
import UikSocialHeader from 'components/UikSocialHeader';
import classnames from 'classnames';
import cls from './socialio.module.scss';
import { duser as defaultProfilePhoto } from '@exampleAssets';
import { get } from 'lodash';
import getAbout from './getAbout';
import { useParams } from 'react-router-dom';

const UserDetail = () => {
  const [clickText, setClickText] = React.useState('');
  const [clickFriendTab, setClickFriendTab] = React.useState('');
  const [successAlert, setSuccessAlert] = React.useState(false);
  const [errorAlert, setErrorAlert] = React.useState(false);
  const { id: paramId } = useParams();
  //GQL
  //id check
  const { loading: CheckIdLoading, error: CheckIdError, data: CheckIdData } = useQuery(CHECK_ID_WITH_TOKEN_QUERY);
  const isThisMyProfile = get(CheckIdData, 'checkToken.id', null) == paramId;

  //Conditional querying!!! TODO: Revisit destructured vars
  //My profile
  const { loading: MyProfileLoading, error: MyProfileError, data: MyProfileData } = useQuery(
    USER_DETAIL_MY_PROFILE_QUERY,
    {
      skip: CheckIdLoading || !isThisMyProfile,
    },
  );
  //other user's profile
  const { loading: PublicProfileLoading, error: PublicProfileError, data: PublicProfileData } = useQuery(
    USER_DETAIL_PUBLIC_PROFILE_QUERY,
    {
      variables: { id: paramId },
      skip: CheckIdLoading || isThisMyProfile,
    },
  );

  //FRIENDS
  const { data: getFriendshipStatusData } = useQuery(GET_FRIENDSHIP_STATUS, {
    skip: CheckIdLoading || isThisMyProfile,
    variables: { friendId: paramId },
  });
  const [sendFriendRequest, { loading: sendRequestLoading }] = useMutation(SEND_FRIEND_REQUEST_MUTATION, {
    variables: { friendId: paramId },
    refetchQueries: [{ query: GET_FRIENDSHIP_STATUS, variables: { friendId: paramId } }],
  });
  const [removeFriend, { loading: removeFriendLoading }] = useMutation(REMOVE_FRIEND_MUTATION, {
    variables: { friendId: paramId },
    refetchQueries: [
      { query: GET_FRIENDSHIP_STATUS, variables: { friendId: paramId } },
      { query: GET_FRIENDS_QUERY, variables: { id: paramId } },
    ],
  });
  //handlers
  function handleClick(text) {
    setClickText(text);
  }

  function handleClickFriendTab(text) {
    setClickFriendTab(text);
  }

  function handleSnackbarClose(event, reason) {
    if (reason === 'clickaway') return;
    setSuccessAlert(false);
    setErrorAlert(false);
  }

  async function handleSendRequest() {
    const { error, data } = await sendFriendRequest();
    if (data.sendFriendRequest) {
      setSuccessAlert(true);
    } else {
      setErrorAlert(true);
    }
  }

  async function handleRemoveFriend() {
    const { error, data } = await removeFriend();
    if (data.removeFriend) {
      setSuccessAlert(true);
    } else {
      setErrorAlert(true);
    }
  }
  //helpers
  const data = MyProfileData ? MyProfileData.myProfile : PublicProfileData && PublicProfileData.getUserPublicProfile;
  const about = getAbout(data, isThisMyProfile);
  function amIPending() {
    return getFriendshipStatusData?.getFriendshipStatus === 'Waiting';
  }

  return (
    <UikContainerVertical>
      <UikContainerHorizontal
        className={classnames(cls.contentContainer, {
          [cls['Menu']]: 'Menu',
        })}
      >
        <div className={cls.content}>
          <UikLayoutMain contentCenterMedium>
            <UikSocialHeader
              canEdit={isThisMyProfile}
              formikComponent={EditMySettingsFormik}
              oldValues={data}
              editFormTitle="Kişisel Bilgilerini Düzenle"
              button={
                !isThisMyProfile &&
                (getFriendshipStatusData?.getFriendshipStatus === 'Approved' ? (
                  <UikButton
                    disabled={removeFriendLoading}
                    onClick={handleRemoveFriend}
                    disabled
                    className={cls.btnUpdateCover}
                    dark
                    Component="span"
                  >
                    {'Arkadaşlıktan Çıkar'}
                  </UikButton>
                ) : amIPending() ? (
                  <UikButton disabled className={cls.btnUpdateCover} dark Component="span">
                    {'Onay Bekleniyor'}
                  </UikButton>
                ) : (
                  <UikButton
                    disabled={sendRequestLoading}
                    onClick={handleSendRequest}
                    className={cls.btnUpdateCover}
                    success
                    Component="span"
                  >
                    {'Arkadaş Ekle'}
                  </UikButton>
                ))
              }
              handleClick={handleClick}
              avatar={{
                imgUrl: data?.profilePhoto || defaultProfilePhoto,
              }}
              imgUrl={data?.thumbnail || CoverImage}
              margin
              name={data?.firstName ? data?.firstName + ' ' + data?.lastName : ''}
            />
            {clickText === 'Özgeçmiş' ? (
              <Timeline />
            ) : clickText === 'Arkadaşlar' ? (
              <FriendsTab
                user_id={paramId}
                friendRequests={data?.getFriendRequests}
                isThisMyProfile={isThisMyProfile}
                setSuccessAlert={setSuccessAlert}
                setErrorAlert={setErrorAlert}
              />
            ) : clickText === 'Gruplar' ? (
              <Groups user_id={paramId} isThisMyProfile={isThisMyProfile} />
            ) : (
              <UikSocialWidgetAbout data={about} margin />
            )}
          </UikLayoutMain>
        </div>
        <Snackbar open={successAlert} autoHideDuration={6000} onClose={handleSnackbarClose}>
          <MuiAlert onClose={handleSnackbarClose} severity="success">
            İşlem Başarılı!
          </MuiAlert>
        </Snackbar>
        <Snackbar open={errorAlert} autoHideDuration={6000} onClose={handleSnackbarClose}>
          <MuiAlert onClose={handleSnackbarClose} severity="error">
            İşlem Başarısız!
          </MuiAlert>
        </Snackbar>
      </UikContainerHorizontal>
    </UikContainerVertical>
  );
};

export default UserDetail;
