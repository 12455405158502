// Libraries
//gql

import {
  UikContainerHorizontal,
  UikContainerVertical,
  UikContent,
  UikFormInputGroup,
  UikHeadline,
  UikInput,
  UikLayoutMain,
  UikSelect,
  UikTabContainer,
  UikWidget,
  Uikon,
} from '@uik';

import { ALL_JOBS_QUERY } from '../../graphql/queries';
import React from 'react';
import { Switch } from 'react-router-dom';
import UikKnowledgeTopic from '../../components/UikKnowledgeTopic';
import classnames from 'classnames';
import cls from './socialio.module.scss';
import { dcompany } from '@exampleAssets';
import { useLazyQuery } from '@apollo/client';

const turkeyCities = [
  { value: 'Ankara', label: 'Ankara' },
  { value: 'Istanbul', label: 'Istanbul' },
  { value: 'Izmir', label: 'Izmir' },
  { value: 'Bursa', label: 'Bursa' },
];
const germanyCities = [
  { value: 'Berlin', label: 'Berlin' },
  { value: 'Munich', label: 'Munich' },
  { value: 'Hamburg', label: 'Hamburg' },
  { value: 'Frankfurt', label: 'Frankfurt' },
];
const franceCities = [
  { value: 'Paris', label: 'Paris' },
  { value: 'Nice', label: 'Nice' },
  { value: 'Lyon', label: 'Lyon' },
  { value: 'Lille', label: 'Lille' },
];

const icons = {
  profile: 'smile',
  events: 'calendar',
};

const Jobs = () => {
  const searchInputEl = React.useRef(null);
  const [searchedTerm, setSearchTerm] = React.useState('');

  //initial query
  React.useEffect(() => {
    searchQuery();
  }, []);

  //handlers
  function handleSearch() {
    setSearchTerm(get(searchInputEl, 'current.input.value', '') || '');
    searchQuery();
  }

  //gql
  const [searchQuery, { loading, called, error, data }] = useLazyQuery(ALL_JOBS_QUERY, {
    variables: { filterTitle: searchedTerm },
  });

  const allJobs = data?.allJobs;

  const getIcon = page => icons[page] || 'home';
  let defaultJobPhoto = dcompany;
  return (
    <UikContainerVertical>
      <UikTabContainer className={cls.mobileNavigation} />

      <UikContainerHorizontal
        className={classnames(cls.contentContainer, {
          [cls['Menu']]: 'Menu',
        })}
      >
        <div className={cls.content}>
          <Switch>
            <UikContainerVertical>
              {/* <Header /> */}

              <UikContainerHorizontal>
                <UikLayoutMain className={cls.content} style={{ justifyContent: 'center' }} contentCenter>
                  <div className={cls.content}>
                    <UikContent>
                      <UikHeadline>{'Jobs'}</UikHeadline>
                      <UikWidget style={{ padding: 30 }}>
                        <UikInput
                          style={{ marginBottom: 16 }}
                          icon={<Uikon>search_left</Uikon>}
                          placeholder={'İş Ara'}
                        />

                        <UikFormInputGroup horizontal style={{ width: '56%' }}>
                          <UikSelect
                            selection={''}
                            name="level"
                            multi
                            options={[
                              {
                                value: 'Internship',
                                label: 'Internship',
                              },
                              {
                                value: 'Entry Level',
                                label: 'Entry Level',
                              },
                              {
                                value: 'Associate',
                                label: 'Associate',
                              },
                              {
                                value: 'Mid-Senior Level',
                                label: 'Mid-Senior Level',
                              },
                              {
                                value: 'Director',
                                label: 'Director',
                              },
                              {
                                value: 'Executive',
                                label: 'Executive',
                              },
                              {
                                value: 'Not Applicable',
                                label: 'Not Applicable',
                              },
                            ]}
                            placeholder={'Level'}
                            position="bottomRight"
                          />
                          <UikSelect
                            selection={''}
                            name="emp_type"
                            options={[
                              {
                                value: 'Full-Time',
                                label: 'Full-Time',
                              },
                              {
                                value: 'Part-Time',
                                label: 'Part-Time',
                              },
                              {
                                value: 'Contract',
                                label: 'Anlaşmalı',
                              },
                              {
                                value: 'Temporary',
                                label: 'Geçici',
                              },
                              {
                                value: 'Volunteer',
                                label: 'Gönüllü',
                              },
                              {
                                value: 'Internship',
                                label: 'Staj',
                              },
                            ]}
                            placeholder={'Type'}
                            position="bottomRight"
                          />
                          <UikSelect
                            defaultValue={['Turkey']}
                            options={[
                              {
                                value: 'Turkey',
                                label: <span>{'Turkey'}</span>,
                              },
                            ]}
                            placeholder={'Turkey'}
                            position="bottomRight"
                          />
                          <UikSelect
                            defaultValue={'Ankara'}
                            options={[
                              { value: 'Ankara', label: 'Ankara' },
                              { value: 'Istanbul', label: 'Istanbul' },
                              { value: 'Izmir', label: 'Izmir' },
                              { value: 'Bursa', label: 'Bursa' },
                            ]}
                            placeholder={'City'}
                            position="bottomRight"
                          />
                        </UikFormInputGroup>
                      </UikWidget>
                      {/* <UikHeadlineDesc>Home / All Tutorials</UikHeadlineDesc> */}
                    </UikContent>

                    {//typeof window !== 'undefined'
                    allJobs?.map((item, index) => (
                      <UikContent key={index}>
                        <UikKnowledgeTopic
                          tagTitle={'İş İlanı'}
                          to={`/jobs/${item.id}`}
                          title={item.title}
                          companyName={item.companyName}
                          description={item.description}
                          logo={item.logo || defaultJobPhoto}
                          key={index}
                          {...item.data}
                        />
                      </UikContent>
                    ))}
                  </div>
                </UikLayoutMain>
              </UikContainerHorizontal>
            </UikContainerVertical>
          </Switch>
        </div>
      </UikContainerHorizontal>
    </UikContainerVertical>
  );
};

export default Jobs;
