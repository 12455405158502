import './@uik/styles.css';

import { ApolloClient, ApolloConsumer, ApolloLink, ApolloProvider, InMemoryCache } from '@apollo/client';
import React, { Fragment } from 'react';
import { Redirect, Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { UikContainerHorizontal, UikContainerVertical, UikTabContainer, UikTabItem, Uikon } from '@uik';

import Header from 'layouts/Header';
import { Hidden } from '@material-ui/core';
import Navigation from 'layouts/Navigation';
import RouteMap from './routeMap.js';
import classnames from 'classnames';
import cls from './App.module.scss';
import { createUploadLink } from 'apollo-upload-client';
import { getTrNameFromSlug } from 'utils/helpers';
import { setContext } from '@apollo/client/link/context';

const uploadLink = createUploadLink({
  uri: `${process.env.REACT_APP_API_URL}`,
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('x-auth-token');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      'x-auth-token': token ? `Bearer ${token}` : '',
    },
  };
});

const client = new ApolloClient({
  link: ApolloLink.from([authLink, uploadLink]),
  cache: new InMemoryCache(),
});

const tabItems = [
  {
    className: 'Menu',
    icon: 'gallery_grid_view',
    content: <Uikon>gallery_grid_view</Uikon>,
  },
  {
    id: 'centerIcon',
    className: 'Home',
    icon: 'home',
    content: <Uikon>home</Uikon>,
  },
  {
    className: 'Chat',
    icon: 'message',
    content: <Uikon>message</Uikon>,
  },
];

function App(props) {
  const [activeContent, setActiveContent] = React.useState('Home');
  const [isAuth, setIsAuth] = React.useState(false);
  //Reactive cache check
  React.useEffect(() => {
    window.addEventListener('storage', checkAuth);
    checkAuth();
    return () => {
      window.removeEventListener('storage', checkAuth);
    };
  }, []);

  //handlers
  function checkAuth() {
    if (!localStorage.getItem('x-auth-token')) {
      setActiveContent('Home');
      setIsAuth(false);
    } else {
      setIsAuth(true);
    }
  }

  function tabTranslate(text) {
    const slug = window.location.pathname;
    switch (text) {
      case 'Menu':
        return 'Menü';
      case 'Home':
        return getTrNameFromSlug(slug);
      case 'Chat':
        return 'Mesajlar';
    }
  }
  return (
    <ApolloProvider client={client}>
      <Router>
        {!localStorage.getItem('x-auth-token') && <Redirect to="/login" />}
        <UikContainerVertical>
          {isAuth && <ApolloConsumer>{client => <Header checkAuth={checkAuth} client={client} />}</ApolloConsumer>}
          {isAuth && (
            <Hidden lgUp>
              <UikTabContainer className={cls.mobileNavigation}>
                {tabItems.map(item => (
                  <UikTabItem
                    key={classnames(cls.mobileItem, item.className)}
                    className={classnames(cls.mobileItem, {
                      active: activeContent === item.className,
                    })}
                    onClick={() => setActiveContent(item.className)}
                    size="smaller"
                    text={tabTranslate(item.className)}
                  />
                ))}
              </UikTabContainer>
            </Hidden>
          )}
          <UikContainerHorizontal
            className={classnames(cls.contentContainer, {
              [cls[!isAuth ? 'Menu' : activeContent]]: !isAuth ? 'Menu' : activeContent,
            })}
          >
            {isAuth && <Navigation setActiveContent={setActiveContent} />}
            <div className={cls.content} style={{ position: 'relative' }}>
              <Switch>
                <MyRoute
                  /*     footerComponent={<Footer />} */
                  headerComponent={<Header />}
                  path="/"
                  exact
                  component={RouteMap.Home}
                />
                <MyRoute
                  /*    footerComponent={<Footer />} */
                  headerComponent={<Header />}
                  path="/login"
                  exact
                  component={RouteMap.Login2}
                />
                <MyRoute
                  /*    footerComponent={<Footer />} */
                  headerComponent={<Header />}
                  path="/verify-account"
                  exact
                  component={RouteMap.VerifyAccount}
                />
                <MyRoute
                  /*    footerComponent={<Footer />} */
                  headerComponent={<Header />}
                  path="/register"
                  exact
                  component={RouteMap.Register}
                />

                <MyRoute
                  /*    footerComponent={<Footer />} */
                  headerComponent={<Header />}
                  path="/password-reset"
                  exact
                  component={RouteMap.PasswordReset2}
                />
                <MyRoute
                  /*    footerComponent={<Footer />} */
                  headerComponent={<Header />}
                  path="/groups"
                  exact
                  component={RouteMap.GroupList}
                />
                <MyRoute
                  /*    footerComponent={<Footer />} */
                  headerComponent={<Header />}
                  path="/group/:id"
                  exact
                  component={RouteMap.GroupDetail}
                />
                <MyRoute
                  /*    footerComponent={<Footer />} */
                  headerComponent={<Header />}
                  path="/mentor"
                  exact
                  component={RouteMap.Mentor}
                />
                <MyRoute
                  /*    footerComponent={<Footer />} */
                  headerComponent={<Header />}
                  path="/jobs"
                  exact
                  component={RouteMap.Jobs}
                />
                <MyRoute
                  /*    footerComponent={<Footer />} */
                  headerComponent={<Header />}
                  path="/jobs/:id"
                  exact
                  component={RouteMap.JobsDetail}
                />
                <MyRoute
                  /*    footerComponent={<Footer />} */
                  headerComponent={<Header />}
                  path="/users"
                  exact
                  component={RouteMap.Users}
                />
                <MyRoute
                  /*    footerComponent={<Footer />} */
                  headerComponent={<Header />}
                  path="/user/:id"
                  exact
                  component={RouteMap.UserDetail}
                />
              </Switch>
            </div>
          </UikContainerHorizontal>
        </UikContainerVertical>
      </Router>
    </ApolloProvider>
  );
}

const MyRoute = ({ children, childrenProps, headerComponent, footerComponent, component: MyComponent, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => {
        return (
          <Fragment>
            <MyComponent {...childrenProps} />
          </Fragment>
        );
      }}
    />
  );
};

export default App;
