import * as React from "react";

import { UikAvatar, Uikon } from "@uik";

import classnames from "classnames";
import cls from "./social-header-user.module.scss";

const UikTopBarUserDropdownDisplayComponent = ({
  className,
  Component,
  children,
  imgUrl,
  name,
  ...rest
}) => (
  <Component className={classnames(cls.wrapper, className)} {...rest}>
    <UikAvatar imgUrl={imgUrl} />
    <Uikon className={cls.dropdownArrow}>arrow_dropdown</Uikon>
  </Component>
);

UikTopBarUserDropdownDisplayComponent.defaultProps = {
  Component: "div",
  children: null,
  className: null,
  imgUrl: null,
  name: null
};

export default UikTopBarUserDropdownDisplayComponent;
