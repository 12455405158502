import * as React from "react";

import DisplayComponent from "./DisplayComponent";
import { UikDropdown } from "@uik";

const UikTopBarUserDropdown = ({
  className,
  Component,
  children,
  user,
  imgUrl,
  ...rest
}) => (
  <UikDropdown
    DisplayComponent={DisplayComponent}
    displayComponentProps={user}
    position="bottomRight"
    {...rest}
  >
    {children}
  </UikDropdown>
);

UikTopBarUserDropdown.defaultProps = {
  Component: "div",
  children: null,
  className: null,
  imgUrl: null
};

export default UikTopBarUserDropdown;
