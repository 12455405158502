export default [
  {
    avatar: {
      avatarPlaceholder: {
        content: '1',
        color: 'red',
      },
      name: 'Mühendislik',
    },
    value: '144 Mentor',
  },
  {
    avatar: {
      avatarPlaceholder: {
        content: '2',
        color: 'green',
      },
      name: 'Eğitim',
    },
    value: '58 Mentor',
  },
  {
    avatar: {
      avatarPlaceholder: {
        content: '3',
        color: 'blue',
      },
      name: 'Danışmanlık',
    },
    value: '46 Mentor',
  },
  {
    avatar: {
      avatarPlaceholder: {
        content: '4',
        color: 'orange',
      },
      name: 'Araştırma',
    },
    value: '43 Mentor',
  },
];
