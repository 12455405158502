export function getBaseInitials(isAlumni) {
  return isAlumni
    ? {
        firstName: '',
        lastName: '',
        tc: '',
        password: '',
        confirmPassword: '',
        email: '',
        gradLicense: '',
        gradDate: '',
        tos: false,
      }
    : {
        firstName: '',
        lastName: '',
        tc: '',
        password: '',
        confirmPassword: '',
        email: '',
        tos: false,
      };
}

export function getStudentInitials(isMasters) {
  return isMasters
    ? {
        lastName: '',
        studentNo: '',
        birthDate: '',
        currLicense: '',
        tos: false,
      }
    : { lastName: '', studentNo: '', birthDate: '', currLicense: '', tos: false };
}
