import * as yup from 'yup';
//Base
export const registerBaseYup = yup.object().shape({
  firstName: yup
    .string()
    .required('Zorunlu Alan')
    .max(63, 'İsminiz 63 karakteri geçmemeli'),

  lastName: yup
    .string()
    .required('Zorunlu Alan')
    .max(63, 'Soyisminiz 63 karakteri geçmemeli'),
  tc: yup
    .string()
    .required('Zorunlu Alan')
    .matches(/^[1-9]{1}[0-9]{9}[02468]{1}$/, 'Lütfen geçerli bir TC girin'),
  password: yup
    .string()
    .min(8, 'Şifreniz minimum 8 karakterden oluşmalı')
    .required('Zorunlu Alan'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Bu Alan Şifreniz ile Uyuşmalı')
    .required('Zorunlu Alan'),
  email: yup
    .string()
    .email('Lütfen geçerli bir email girin')
    .required('Zorunlu Alan'),
});

//Base Alumni
export const registerAlumniYup = yup.object().shape({
  firstName: yup
    .string()
    .required('Zorunlu Alan')
    .max(63, 'İsminiz 63 karakteri geçmemeli'),

  lastName: yup
    .string()
    .required('Zorunlu Alan')
    .max(63, 'Soyisminiz 63 karakteri geçmemeli'),
  tc: yup
    .string()
    .required('Zorunlu Alan')
    .matches(/^[1-9]{1}[0-9]{9}[02468]{1}$/, 'Lütfen geçerli bir TC girin'),
  password: yup
    .string()
    .min(8, 'Şifreniz minimum 8 karakterden oluşmalı')
    .required('Zorunlu Alan'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Bu Alan Şifreniz ile Uyuşmalı')
    .required('Zorunlu Alan'),
  email: yup
    .string()
    .email('Lütfen geçerli bir email girin')
    .required('Zorunlu Alan'),
  gradLicense: yup
    .string()
    .required('Zorunlu Alan')
    .max(100, 'Bölüm adı 100 karakteri geçmemeli'),
  gradDate: yup.date().required('Zorunlu Alan'),
  tos: yup.boolean().oneOf([true], "Alumuni'i kullanmak için Hizmet Koşullarımızı kabul edin."),
});

//Student
export const registerStudentYup = yup.object().shape({
  studentNo: yup
    .string()
    .required('Zorunlu Alan')
    .max(20, 'Öğrenci Numarası 20 karakteri geçmemeli'),
  birthDate: yup.date().required('Zorunlu Alan'),
  currLicense: yup.string().max(100, 'Bölüm adı 100 karakteri geçmemeli'),
  tos: yup.boolean().oneOf([true], "Alumuni'i kullanmak için Hizmet Koşullarımızı kabul edin."),
});
