import GroupDetail from 'views/GroupDetail';
import GroupList from 'views/GroupList';
import Home from 'views/Home';
import Jobs from 'views/Jobs';
import JobsDetail from 'views/JobsDetail';
import Login from 'views/Login';
import Login2 from 'views/Login2';
import Mentor from 'views/Mentor';
import PasswordReset from 'views/PasswordReset';
import PasswordReset2 from 'views/PasswordReset2';
import Register from 'views/Register';
import UserDetail from 'views/UserDetail';
import Users from 'views/Users';
import VerifyAccount from 'views/VerifyAccount';
import ChangePassword from 'views/ChangePassword';

const RouteMap = {
  Home,
  Login,
  Login2,
  VerifyAccount,
  ChangePassword,
  Mentor,
  Jobs,
  JobsDetail,
  GroupList,
  GroupDetail,
  Users,
  UserDetail,
  PasswordReset,
  PasswordReset2,
  Register,
};

export default RouteMap;
