import * as React from 'react';

import { UikAvatar, UikDropdown, UikDropdownItem, UikTag, UikTagContainer, UikWidget } from '@uik';

import DisplayComponentAction from './DisplayComponentAction';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import cls from './knowledge-topic.module.scss';

const UikKnowledgeTopic = ({
  children,
  Component,
  className,
  icon,
  title,
  description,
  tagTitle,
  companyName,
  logo,
  users,
  to,
  isAdmin,
  ...rest
}) => (
  <Component className={classnames(cls.wrapper, className)} style={{ alignItems: 'initial' }} padding {...rest}>
    {logo ? <img src={logo} width="62px" height="62px" style={{ borderRadius: '50%' }} /> : ''}

    <div className={cls.content}>
      <React.Fragment>
        <UikTagContainer style={{ marginBottom: 8 }}>
          <UikTag color={'blue'} fill>
            {tagTitle}
          </UikTag>
        </UikTagContainer>
        <Link to={to}>
          <h2 className={cls.title}>
            <a className={cls.link}>{title}</a>
            {isAdmin && (
              <UikTag className={cls.tag} color="green">
                Admin
              </UikTag>
            )}
          </h2>
        </Link>
        <p>
          <strong>{companyName}</strong>
        </p>
        <p
          style={{
            wordBreak: 'break-all',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitLineClamp: '3',
            WebkitBoxOrient: 'vertical',
          }}
        >
          {description}
        </p>
      </React.Fragment>
    </div>
    {rest.button && rest.button}
    {users && (
      <React.Fragment>
        <div className={cls.users}>
          {users.map(user => (
            <UikAvatar style={{ alignItems: 'initial' }} key={user.imgUrl} {...user} />
          ))}
        </div>
      </React.Fragment>
    )}
    {isAdmin && (
      <UikDropdown className={cls.action} DisplayComponent={DisplayComponentAction} position="bottomRight">
        <UikDropdownItem>Settings</UikDropdownItem>
        <UikDropdownItem>Your Profile</UikDropdownItem>
        <UikDropdownItem>Help & Support center</UikDropdownItem>
        <UikDropdownItem>Logout</UikDropdownItem>
      </UikDropdown>
    )}
  </Component>
);

UikKnowledgeTopic.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  Component: PropTypes.any, // eslint-disable-line
  companyName: PropTypes.string,
  description: PropTypes.string,
  icon: PropTypes.instanceOf(Object),
  isAdmin: PropTypes.bool,
  /* data */
  logo: PropTypes.string,
  title: PropTypes.string.isRequired,
  to: PropTypes.string,
  users: PropTypes.arrayOf(
    PropTypes.shape({
      action: PropTypes.oneOf(['like', 'comment']),
      imgUrl: PropTypes.string.isRequired,
    }),
  ),
};

UikKnowledgeTopic.defaultProps = {
  Component: UikWidget,
  children: null,
  className: null,
  companyName: null,
  description: null,
  isAdmin: false,
  tagTitle: 'Job Offer',
  to: '/',
  users: null,
};

export default UikKnowledgeTopic;
