//GQL

import { APPROVE_FRIEND_REQUEST_MUTATION, REJECT_FRIEND_REQUEST_MUTATION } from '../../../../graphql/mutations';
import { GET_FRIENDSHIP_STATUS, GET_FRIENDS_QUERY, USER_DETAIL_MY_PROFILE_QUERY } from '../../../../graphql/queries';
import { UikAvatar, UikButton, UikButtonGroup, Uikon } from '@uik';

import { Link } from 'react-router-dom';
import React from 'react';
import { useMutation } from '@apollo/client';

function PendingFriends({ user_id, friendRequests, photo, setSuccessAlert, setErrorAlert }) {
  //mutations
  const [approveFriendRequest] = useMutation(APPROVE_FRIEND_REQUEST_MUTATION, {
    refetchQueries: [
      { query: USER_DETAIL_MY_PROFILE_QUERY },
      { query: GET_FRIENDS_QUERY, variables: { id: user_id } },
      { query: GET_FRIENDSHIP_STATUS, variables: { friendId: user_id } },
    ],
  });

  const [rejectFriendRequest] = useMutation(REJECT_FRIEND_REQUEST_MUTATION, {
    refetchQueries: [
      { query: USER_DETAIL_MY_PROFILE_QUERY },
      { query: GET_FRIENDS_QUERY, variables: { id: user_id } },
      { query: GET_FRIENDSHIP_STATUS, variables: { friendId: user_id } },
    ],
  });
  return (
    friendRequests?.map(item => (
      <React.Fragment key={item.id}>
        <div
          style={{
            alignItems: 'center',
            border: '1px solid #EAEDF3',
            borderRadius: '4px',
            display: 'flex',
            justifyContent: 'space-between',
            padding: '20px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          <Link to={`/user/${item?.id}`}>
            <UikAvatar
              highlighted
              imgUrl={item?.profilePhoto || photo}
              name={<div style={{ color: '#3E3F42' }}>{`${item?.firstName} ${item?.lastName}`}</div>}
              size="large"
            />
          </Link>
          <UikButtonGroup>
            <UikButton
              onClick={async () => {
                const { data } = await approveFriendRequest({ variables: { friendId: item?.id } });
                if (data.approveFriendRequest) {
                  setSuccessAlert(true);
                } else {
                  setErrorAlert(true);
                }
              }}
              square
              success
              style={{ minWidth: '60px' }}
            >
              <Uikon>check</Uikon>
            </UikButton>
            <UikButton
              onClick={async () => {
                const { data } = await rejectFriendRequest({ variables: { friendId: item?.id } });
                if (data.rejectFriendRequest) {
                  setSuccessAlert(true);
                } else {
                  setErrorAlert(true);
                }
              }}
              square
              error
              style={{ minWidth: '60px' }}
            >
              <Uikon style={{ transform: 'rotate(45deg)' }}>add</Uikon>
            </UikButton>
          </UikButtonGroup>
        </div>
      </React.Fragment>
    )) || null
  );
}

export default PendingFriends;
