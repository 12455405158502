//GQL

import { GET_FRIENDS_QUERY } from '../../../../graphql/queries';
import { Link } from 'react-router-dom';
import React from 'react';
import { UikAvatar } from '@uik';
import { useQuery } from '@apollo/client';

function Friends({ user_id, photo }) {
  const { data: queryResult, error, loading } = useQuery(GET_FRIENDS_QUERY, { variables: { id: user_id } });
  const friends = queryResult?.getFriends;
  return (
    friends?.map(item => (
      <React.Fragment key={item.id}>
        <Link
          to={`/user/${item?.id}`}
          style={{
            alignItems: 'center',
            border: '1px solid #EAEDF3',
            borderRadius: '4px',
            display: 'flex',
            justifyContent: 'space-between',
            padding: '20px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          <UikAvatar
            highlighted
            imgUrl={item?.profilePhoto || photo}
            name={<div style={{ color: '#3E3F42' }}>{`${item?.firstName} ${item?.lastName}`}</div>}
            size="large"
          />
        </Link>
      </React.Fragment>
    )) || null
  );
}

export default Friends;
