import * as yup from 'yup';
const phoneRegExp = /^(\s*\(?0\d{4}\)?(\s*|-)\d{3}(\s*|-)\d{3}\s*)|(\s*\(?0\d{3}\)?(\s*|-)\d{3}(\s*|-)\d{4}\s*)|(\s*(7|8)(\d{7}|\d{3}(-|\s{1})\d{4})\s*)$/;

//ALUMUNI
//-post job
export const postJobFormYup = yup.object().shape({
  company: yup
    .string()
    .required('Zorunlu Alan')
    .max(63, 'Şirket ismi 63 karakteri geçmemeli'),
  title: yup
    .string()
    .required('Zorunlu Alan')
    .max(63, 'İş ünvanı 63 karakteri geçmemeli'),
  description: yup.string().max(750, 'Açıklama 750 karakteri geçmemeli'),
  email: yup
    .string()
    .email('Lütfen geçerli bir email girin.')
    .max(50, 'Email 50 karakteri geçmemeli'),
  website: yup
    .string()
    .matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      'Lütfen geçerli bir link girin',
    ),
  //phone: yup.string().matches(phoneRegExp, 'Phone number is not valid'),
});

//-editGroup
export const editGroupFormYup = yup.object().shape({
  company: yup
    .string()
    .required('Zorunlu Alan')
    .max(63, 'Okul ismi 63 karakteri geçmemeli'),
  title: yup
    .string()
    .required('Zorunlu Alan')
    .max(63, 'Grup İsmi 63 karakteri geçmemeli'),
  description: yup.string().max(500, 'Açıklama 500 karakteri geçmemeli'),
  website: yup
    .string()
    .matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      'Lütfen geçerli bir link girin',
    ),
  telephone: yup.string().matches(phoneRegExp, 'Lütfen geçerli bir telefon numarası girin!'),
});
//-mySettings
export const editMySettingsYup = yup.object().shape({
  firstName: yup
    .string()
    .required('Zorunlu Alan')
    .max(63, 'İsminiz 63 karakteri geçmemeli'),

  lastName: yup
    .string()
    .required('Zorunlu Alan')
    .max(63, 'Soyisminiz 63 karakteri geçmemeli'),

  biography: yup.string().max(255, 'Özgeçmişiniz 255 karakteri geçmemeli'),

  website: yup
    .string()
    .matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      'Lütfen geçerli bir link girin',
    ),

  phoneNumber: yup.string().matches(phoneRegExp, 'Lütfen geçerli bir telefon numarası girin!'),
});
//
/*
export const report = yup.object().shape({
  subject: yup.string().required(),
});
export const createUpdate = [
  null,
  yup.object().shape({
    roadmapStepId: yup.string().required(),
  }),
];

export const forgotPasswordSchema = yup.object().shape({
  email: yup
    .string()
    .email('Invalid email')
    .required('Required'),
});

export const contactUs = yup.object().shape({
  email: yup
    .string()
    .email('Invalid email')
    .required('Required'),

  message: yup
    .string()
    .min(2, 'Too Short!')

    .required('Required'),
  name: yup
    .string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
});

export const contactUsPlans = yup.object().shape({
  email: yup
    .string()
    .email('Invalid email')
    .required('Required'),

  message: yup
    .string()
    .min(2, 'Too Short!')

    .required('Required'),
});

export const signupSchema = yup.object().shape({
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], 'The password and confirmation password do not match')
    .required('Confirmation password cannot be blank'),
  email: yup
    .string()
    .email('Invalid email')
    .required('Required'),
  firstName: yup
    .string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  lastName: yup
    .string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  password: yup
    .string()
    .min(8, 'Password must be minimum 8 characters')
    .required('Required'),
  termsOfService: yup.boolean().oneOf([true], 'You must accept the terms and conditions'),
});

export const loginSchema = yup.object().shape({
  email: yup
    .string()
    .email('Invalid email')
    .required('Required'),

  password: yup
    .string()

    .required('Required'),
});

export const createChallenge = [
  null,
  yup.object().shape({
    challenges: yup
      .array()
      .max(3)
      .min(1),
  }),
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
];

export const createProject = [
  null,
  null,
  null,
  null,
  yup.object().shape({
    globalGoals: yup
      .array()
      .max(3)
      .min(1),
  }),
  yup.object().shape({
    city: yup
      .string()
      .required()
      .nullable(),
    district: yup
      .string()
      .required()
      .nullable(),
  }),
  yup.object().shape({
    description: yup.string().required(),
    title: yup
      .string()
      .required()
      .max(63, 'project title must be less then 63 characters.'),
  }),
];

export const userEditOptional = yup.object().shape({
  languages: yup.array(
    yup.object().shape({
      id: yup.string().required(),
      name: yup.string().required(),
    }),
  ),
  school: yup.string().nullable(),
  university: yup.string().nullable(),
  work: yup.array(yup.string()),
});

export const communityEdit = yup.object().shape({
  categories: yup.array(),
  city: yup.string().required(),
  district: yup.string().required(),
  globalGoals: yup.array(),
  mission: yup.string().max(500, 'last name must be less then 500 characters.'),
  name: yup
    .string()
    .max(63, 'community name must be less then 63 characters')
    .required(),
  products: yup.array().max(3),

  skills: yup.array().min(1),
  space: yup.array().max(3),
});

export const userEditRequired = yup.object().shape({
  email: yup
    .string()
    .email()
    .required()
    .max(50, 'email must be less then 50 characters.'),
  firstName: yup
    .string()
    .required()
    .max(63, 'first name must be less then 63 characters.'),

  lastName: yup
    .string()
    .required()
    .max(63, 'last name must be less then 63 characters.'),
  phone: yup.string().matches(phoneRegExp, 'Phone number is not valid'),
  //skillsIds: yup.array().min(1, 'skills field must have at least 1 items'),
});

export const organizationEditRequired = yup.object().shape({
  // name: yup
  //   .string()
  //   .required()
  //   .max(63, 'first name must be less then 63 characters.'),
  // skills: yup
  //   .array()
  //   .max(3)
  //   .min(1),
});

export const groupEdit = yup.object().shape({
  email: yup
    .string()
    .nullable()
    .email('Invalid email'),
  mission: yup
    .string()
    .nullable()
    .max(600, 'mission must be less then 600 characters.'),
  name: yup
    .string()
    .required()
    .max(63, 'first name must be less then 63 characters.'),
  privacySetting: yup.string().required(),
  wantCollaboration: yup.boolean().nullable(),
  wantHelp: yup.boolean().nullable(),
  wantSupport: yup.boolean().nullable(),

  website: yup
    .string()
    .nullable()
    .trim()
    .matches(
      /^(https?:\/\/)?((([a-z\d]([a-z\d-]*[a-z\d])*)\.)+[a-z]{2,}|((\d{1,3}\.){3}\d{1,3}))(\:\d+)?(\/[-a-z\d%_.~+]*)*(\?[;&a-z\d%_.~+=-]*)?(\#[-a-z\d_]*)?$/i,
      'Please add valid website link',
    ),
  phoneNumber: yup
    .string()
    .nullable()
    .trim()
    .matches(/^(?:(?:00)?44|0)7(?:[45789]\d{2}|624)\d{6}$/, 'Please add valid phone number'),
});

export const manualVolunteer = [
  yup.object().shape({
    projectId: yup.string().nullable(),
    isOutside: yup.boolean().when('projectId', {
      is: value => value === null,
      then: yup
        .boolean()
        .required()
        .oneOf([true], 'Field must be checked'),
    }),
  }),
  null,
  yup.object({
    links: yup.array().of(
      yup
        .string()
        .trim()
        .matches(
          /^(https?:\/\/)?((([a-z\d]([a-z\d-]*[a-z\d])*)\.)+[a-z]{2,}|((\d{1,3}\.){3}\d{1,3}))(\:\d+)?(\/[-a-z\d%_.~+]*)*(\?[;&a-z\d%_.~+=-]*)?(\#[-a-z\d_]*)?$/i,
          'Please add valid website link',
        ),
    ),
  }),
];

export const userOnboarding = [
  yup.object({
    reasonForEngaging: yup.string().required('Type selection is required'),
  }),
  yup.object({
    latitude: yup.string().nullable(),
    address: yup.string().nullable(),
    longitude: yup.string().nullable(),
  }),
  yup.object({
    categories: yup
      .array()
      .min(1)
      .max(5, 'causes field must have at least 1 items max 5 items'),
  }),
  yup.object({
    skillsIds: yup
      .array()
      .min(1)
      .max(5, 'skills field must have at least 1 items max 5 items'),
  }),
];

export const groupCreate = [
  yup.object().shape({
    website: yup
      .string()
      .trim()
      .matches(
        /^(https?:\/\/)?((([a-z\d]([a-z\d-]*[a-z\d])*)\.)+[a-z]{2,}|((\d{1,3}\.){3}\d{1,3}))(\:\d+)?(\/[-a-z\d%_.~+]*)*(\?[;&a-z\d%_.~+=-]*)?(\#[-a-z\d_]*)?$/i,
        'Please add valid website link',
      ),
  }),
  null,
];

export const organizationCreate = isRegisteredOrganisation => [
  yup.object().shape({
    //aimingChallenges: yup.string().required('This field is required'),
    isRegisteredOrganisation: yup
      .boolean()
      .nullable()
      .required('This field is required'),
    teamRelated: yup.string().required('This field is required'),
  }),
  isRegisteredOrganisation
    ? yup.object().shape({
        address: yup.string().required(),
        city: yup
          .string()
          .nullable()
          .required(),
        district: yup
          .string()
          .nullable()
          .required(),
        email: yup
          .string()
          .email()
          .required(),
        name: yup.string().required(),
        registration: yup.string().required(),
        //type: yup.string().required('This field is required'),
        type: yup.string(),
      })
    : yup.object().shape({
        city: yup
          .string()
          .nullable()
          .required(),
        district: yup
          .string()
          .nullable()
          .required(),
        name: yup.string().required(),
      }),
];
*/
