import * as React from "react";

import { UikWidget } from "@uik";
import cls from "./social-post-post.module.scss";

const SocialPostHeader = props => (
  <UikWidget className={cls.wrapper} margin {...props} />
);

export default SocialPostHeader;
