import * as images from '@exampleAssets';

import {
  ALL_JOBS_QUERY,
  CHECK_ID_WITH_TOKEN_QUERY,
  GET_GROUP_CATEGORY,
  GET_PERSONAL_FEED,
  GROUP_DETAIL_QUERY,
  IS_USER_JOINED_QUERY,
  USER_DETAIL_MY_PROFILE_QUERY,
} from '../../graphql/queries';
import { JOIN_GROUP_MUTATION, LEAVE_GROUP_MUTATION } from '../../graphql/mutations';
import {
  UikAvatar,
  UikButton,
  UikContainerHorizontal,
  UikContent,
  UikContentTitle,
  UikDivider,
  UikFormInputGroup,
  UikInput,
  UikScrollArea,
  UikTag,
  UikWidget,
  UikWidgetHeader,
  Uikon,
} from '@uik';
import { dcompany, duser } from '@exampleAssets';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { Link } from 'react-router-dom';
import PostCreate from './components/PostCreate';
import PostFooter from 'components/PostFooter';
import PostHeader from './components/PostHeader';
import PostImageGroup from './components/PostImageGroup';
import PostWrapper from './components/PostWrapper';
import React from 'react';
import UikKnowledgeTopic from 'components/UikKnowledgeTopic';
import cls from './social-home.module.scss';
import get from 'lodash/get';

function SocialProfile(props) {
  const [successAlert, setSuccessAlert] = React.useState(false);
  const [errorAlert, setErrorAlert] = React.useState(false);
  const [popupCompanyName, setPopupCompanyName] = React.useState('');
  const [popupId, setPopupId] = React.useState(null);
  const [applyOpen, setApplyOpen] = React.useState(false);
  const [resume, setResume] = React.useState('');

  const fileChangedHandler = event => {
    var reader = new FileReader();
    setResume(event.target.files[0].name);
    setLastModified(moment(event.target.files[0].lastModified).format('DD MMM YYYY hh:mm a'));

    reader.onloadend = function(event) {
      //setResume(event.target.files[0].name);
    };
    if (event.target.files[0]) {
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  const { loading, called, error, data } = useQuery(ALL_JOBS_QUERY, {
    variables: { filterTitle: '' },
  });

  const { data: categoryData } = useQuery(GET_GROUP_CATEGORY, {
    variables: { category: '' },
  });

  //using lazy query to make user refetch feed when needed
  const [getPersonalFeed, { data: rawFeedArrayData }] = useLazyQuery(GET_PERSONAL_FEED, { variables: { limit: 50 } });

  React.useEffect(() => {
    getPersonalFeed();
  }, []);

  // Requiring the lodash library
  const _ = require('lodash');

  const randomGroup = _.random(
    categoryData?.filterGroupsByCategory[0],
    categoryData?.filterGroupsByCategory.length - 1,
  );
  const filterGroupsByCategory = categoryData?.filterGroupsByCategory;
  let defaultGroupPhoto = images.dgroup2;

  //const lastGroup = categoryData?.filterGroupsByCategory.length - 1;
  const recommendedGroupId = categoryData?.filterGroupsByCategory[randomGroup]?.id; //changed lastgroup => randomGroup

  const allJobs = data?.allJobs;
  let defaultJobPhoto = dcompany;

  //fetch my_id
  const { data: CheckIdData } = useQuery(CHECK_ID_WITH_TOKEN_QUERY);

  //MY_ID
  const myId = get(CheckIdData, 'checkToken.id', null);

  //MUTATIONS
  const [joinGroup, { loading: joinLoading }] = useMutation(JOIN_GROUP_MUTATION, {
    variables: { groupId: recommendedGroupId },
    refetchQueries: [
      { query: IS_USER_JOINED_QUERY, variables: { groupId: recommendedGroupId } },
      { query: GROUP_DETAIL_QUERY, variables: { id: recommendedGroupId } },
      { query: USER_DETAIL_MY_PROFILE_QUERY },
    ],
  });

  const [leaveGroup, { loading: leaveLoading }] = useMutation(LEAVE_GROUP_MUTATION, {
    variables: { groupId: recommendedGroupId },
    refetchQueries: [
      { query: IS_USER_JOINED_QUERY, variables: { groupId: recommendedGroupId } },
      { query: GROUP_DETAIL_QUERY, variables: { id: recommendedGroupId } },
      { query: USER_DETAIL_MY_PROFILE_QUERY },
    ],
  });

  const { data: isUserJoinedData } = useQuery(IS_USER_JOINED_QUERY, {
    skip: !myId || !recommendedGroupId,
    variables: { groupId: recommendedGroupId },
  });
  const isPublic = data?.getGroupProfile?.isPublic;

  //My profile
  const { loading: MyProfileLoading, error: MyProfileError, data: MyProfileData } = useQuery(
    USER_DETAIL_MY_PROFILE_QUERY,
  );
  const defaultProfilePhoto = duser;

  //handlers
  function handleClick(text) {
    setClickText(text);
  }

  function handlePopupOpen(id, name) {
    setPopupCompanyName(name);
    setPopupId(id);

    setApplyOpen(true);
  }

  function handleSnackbarClose(event, reason) {
    if (reason === 'clickaway') return;
    setSuccessAlert(false);
    setErrorAlert(false);
  }

  //helpers
  function amIPending() {
    return data?.getGroupProfile?.getPendingMembers?.filter(m => m.id == myId).length === 1;
  }

  const handleApply = () => {
    try {
      setLoading(true);
      window.setTimeout(() => {
        setApplyOpen(false);
      }, 3000);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <UikContainerHorizontal className={cls.page}>
      <UikScrollArea className={cls.mainContent}>
        <PostCreate profilePhoto={CheckIdData?.checkToken?.profilePhoto} />
        {rawFeedArrayData &&
          rawFeedArrayData?.getPersonalFeed?.map((element, index) =>
            element.isJobPost ? (
              //JOB POST

              <UikContent key={index}>
                <UikKnowledgeTopic
                  tagTitle={'İş İlanı'}
                  to={`/jobs/${element?.jobPost?.id}`}
                  key={index}
                  {...element?.jobPost}
                  logo={element?.jobPost?.logo || defaultJobPhoto}
                  title={element?.jobPost?.title}
                  companyName={element?.jobPost?.companyName}
                  button={
                    <div className={cls.basvurBg}>
                      <UikButton
                        className={cls.basvurBtn}
                        Component="span"
                        onClick={() => handlePopupOpen(element?.jobPost?.id, element?.jobPost?.companyName)}
                        //disabled={apply}
                        clear
                      >
                        <strong className={cls.text}>hızlı başvur</strong>
                      </UikButton>
                    </div>
                  }
                />
                {applyOpen && (
                  <Dialog
                    onClose={() => setApplyOpen(false)}
                    aria-labelledby="customized-dialog-title"
                    open={applyOpen}
                    style={{ width: '100%' }}
                  >
                    <div
                      style={{
                        fontSize: '20px',
                        color: '#3E3F42',
                        lineHeight: '1.125rem',
                        fontWeight: '500',
                        padding: 20,
                      }}
                    >
                      {'Başvuru ' + popupCompanyName}
                    </div>

                    <DialogContent dividers>
                      <UikContentTitle> {'İletİşİm Bİlgİlerİnİz'}</UikContentTitle>
                      <div className={cls.wrapper}>
                        {/* LoggedIn user comes here !!!  */}
                        <UikAvatar
                          className={cls.avatar}
                          imgUrl={MyProfileData?.myProfile?.profilePhoto || defaultProfilePhoto}
                          name={
                            <span className={cls.nameText}>
                              <Link to={`/user/${CheckIdData?.checkToken?.id}`}>
                                <strong>
                                  {MyProfileData?.myProfile?.firstName + ' ' + MyProfileData?.myProfile?.lastName}
                                </strong>
                              </Link>{' '}
                              {/* <span className={cls.text}>Yazılım Mühendisi, Alumni</span> */}
                            </span>
                          }
                          textBottom={MyProfileData?.myProfile?.city + ' ' + MyProfileData?.myProfile?.country}
                        />
                      </div>
                      <div>
                        <UikFormInputGroup direction="horizontal">
                          <UikInput
                            label="Email"
                            name="email"
                            onChange={e => setEmail(e.target.value)}
                            defaultValue={MyProfileData?.myProfile?.email}
                          />
                          <UikInput
                            label={'Telefon Numaranız'}
                            name="Phone Number"
                            onChange={e => setPhone(e.target.value)}
                            defaultValue={MyProfileData?.myProfile?.phoneNumber}
                          />
                        </UikFormInputGroup>
                      </div>
                      <div style={{ marginTop: '.5rem' }}>
                        <UikContentTitle> {'Özgeçmİşİnİz'}</UikContentTitle>
                      </div>
                      {resume && (
                        <UikWidget
                          style={{
                            position: 'relative',
                            overflow: 'visible',
                          }}
                        >
                          <div
                            className={cls.cross}
                            style={{
                              position: 'absolute',
                              bottom: '51px',

                              right: '-10px',
                              cursor: 'pointer',
                            }}
                            onClick={() => setResume('')}
                          >
                            <Uikon
                              style={{
                                transform: 'rotate(45deg)',
                              }}
                            >
                              add
                            </Uikon>
                          </div>
                          <div
                            style={{
                              fontSize: 12,
                              fontWeight: 500,
                              padding: '10px 15px',
                              marginTop: '.5rem',
                            }}
                          >
                            {resume}
                            <div className={cls.lastModified}>{lastModified}</div>
                          </div>
                        </UikWidget>
                      )}

                      <div>
                        <input
                          type="file"
                          name="avatar"
                          onChange={fileChangedHandler}
                          style={{ display: 'none' }}
                          id="resume-file"
                        />
                        <label htmlFor="resume-file">
                          <UikButton
                            variant="contained"
                            Component="span"
                            style={{ marginTop: '.5rem' }}
                            icon={<Uikon>cloud_up</Uikon>}
                            transparent
                          >
                            {'Özgeçmişinizi Yükleyin'}
                          </UikButton>
                        </label>
                      </div>
                    </DialogContent>
                    <DialogActions>
                      <UikButton success isLoading={loading} onClick={() => handleApply()}>
                        {'Başvur'}
                      </UikButton>
                    </DialogActions>
                  </Dialog>
                )}
              </UikContent>
            ) : (
              //ORDINARY POST
              <PostWrapper key={index}>
                <PostHeader
                  updateId={element.id}
                  content={element?.content}
                  isMine={myId === element?.user?.id}
                  postDate={element.postedAt}
                  user={element.user}
                />

                {element?.images?.length > 0 ? <PostImageGroup images={element?.images || []} /> : null}

                <UikDivider />
                <PostFooter me={MyProfileData?.myProfile} update={element} />
              </PostWrapper>
            ),
          )}
      </UikScrollArea>

      {/*RIGHT SIDE BAR*/}
      <UikScrollArea className={cls.extraContent}>
        <UikWidget margin>
          <UikWidgetHeader className={cls.smallWidgetHeader}>
            Yeni İşler
            {/*<UikTag className={cls.tag} color={'blue'} fill>
              {data?.allJobs.slice(allJobs.length - 5, allJobs.length - 0).length + ' ' + 'yeni'}
            </UikTag>*/}
          </UikWidgetHeader>
          <div style={{ padding: '20px' }}>
            {allJobs?.slice(0, 5).map((item, index) =>
              Math.floor((new Date() - item.createdAt) / 1000 / 60 / 60 / 24) < 11117 ? (
                <UikAvatar
                  key={index}
                  className={cls.newJob}
                  imgUrl={item.logo || defaultJobPhoto}
                  name={
                    <Link className={cls.newJobsTitle} to={`/jobs/${item.id}`}>
                      {item.title}
                    </Link>
                  }
                  textBottom={item.companyName + (item.city?.title ? ', ' + item.city?.title : '')}
                />
              ) : null,
            )}
          </div>
          <div className={cls.suggestedCta}>
            <Link to={`/jobs`}>
              <UikButton style={{ color: '#fff' }} className={cls.newJobsBtn} clear>
                <strong className={cls.text}>İşleri Görüntüle</strong>
              </UikButton>
            </Link>
          </div>
          {/* <Stories /> */}
        </UikWidget>
        <UikWidget className={cls.eventsWidget} margin>
          <UikButton clear icon={<Uikon>calendar</Uikon>}>
            <Link to="/events">
              <strong className={cls.eventText}>Yeni Yıl Kutlaması</strong>
            </Link>
          </UikButton>
        </UikWidget>

        <UikWidget margin>
          <UikWidgetHeader className={cls.smallWidgetHeader}>{'Önerilen Gruplar'}</UikWidgetHeader>

          <div style={{ padding: '20px' }}>
            <UikAvatar
              imgUrl={categoryData?.filterGroupsByCategory[randomGroup]?.profilePhoto || defaultGroupPhoto}
              name={categoryData?.filterGroupsByCategory[randomGroup]?.title}
              textBottom={categoryData?.filterGroupsByCategory[randomGroup]?.category}
            />
            <div
              className={cls.suggestedPage}
              style={{
                backgroundImage: `url("${categoryData?.filterGroupsByCategory[randomGroup]?.thumbnail ||
                  defaultGroupPhoto}")`,
              }}
            />
          </div>
          <div className={cls.suggestedCta}>
            <Link to={`/group/${categoryData?.filterGroupsByCategory[randomGroup]?.id}`}>
              <UikButton style={{ color: '#fff' }} className={cls.newJobsBtn} clear>
                <strong className={cls.text}>Grubu Görüntüle</strong>
              </UikButton>
            </Link>
          </div>
        </UikWidget>
      </UikScrollArea>
    </UikContainerHorizontal>
  );
}

export default SocialProfile;
