// Libraries

import React, { Component } from 'react';
import {
    UikAvatar,
    UikContainerHorizontal,
    UikContainerVertical,
    UikLayoutMain,
    UikWidget,
    UikWidgetHeader,
    Uikon
} from '@uik';

import { Link } from 'react-router-dom';
import Map from './components/Map/Map';
import Stories from './components/Stories';
import classnames from 'classnames';
import cls from './socialio.module.scss';
import clsHome from './buildings-performance.module.scss';
import performanceProfit from './assets/performanceProfit';

const tabItems = [
  {
    className: 'Menu',
    icon: 'gallery_grid_view',
    content: <Uikon>gallery_grid_view</Uikon>,
  },
  {
    id: 'centerIcon',
    className: 'Home',
    icon: 'home',
    content: <Uikon>home</Uikon>,
  },
  {
    className: 'Chat',
    icon: 'message',
    content: <Uikon>message</Uikon>,
  },
];

class Mentor extends Component {
  render() {
    return (
      <UikContainerVertical>
        <UikContainerHorizontal
          className={classnames(cls.contentContainer, {
            [cls['Menu']]: 'Menu',
          })}
        >
          <div className={cls.content}>
            <UikLayoutMain contentCenterMedium>
              <UikWidget margin>
                <UikWidgetHeader>{'Mentörler'}</UikWidgetHeader>
                <Stories />
              </UikWidget>
              <UikWidget margin>
                <UikWidgetHeader>{'Alanınızdaki Mentorlar'}</UikWidgetHeader>
                <div style={{ padding: 20 }}>
                  <Map />
                </div>
              </UikWidget>

              <UikWidget className={clsHome.widgetOverflow} margin>
                <UikWidgetHeader>{'İş Fonksiyonuna Göre Ara'}</UikWidgetHeader>
                <div className={clsHome.tableWrapper}>
                  <table cellPadding="0" cellSpacing="0" className={clsHome.tableProfit}>
                    <tbody>
                      {performanceProfit.map(item => (
                        <tr key={item.value}>
                          <td>
                            <UikAvatar Component={Link} to="/users" {...item.avatar} />
                          </td>

                          <td>{`${item.value}`}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </UikWidget>
            </UikLayoutMain>
          </div>
        </UikContainerHorizontal>
      </UikContainerVertical>
    );
  }
}

export default Mentor;
