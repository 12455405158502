import BaseForm from './Forms/BaseForm';
import StudentForm from './Forms/StudentForm';

export default function MultiStepForm({ step, ...rest }) {
  switch (step) {
    case 'Base':
      return <BaseForm {...rest} />;
    case 'Student':
      return <StudentForm {...rest} />;
    case 'MailApproval':
      return null;
    default:
      return null;
  }
}
