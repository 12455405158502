import { UikAvatar, UikButton, UikDivider, UikWidget, Uikon } from '@uik';

import { CREATE_UPDATE } from 'graphql/mutations';
import { GET_PERSONAL_FEED } from 'graphql/queries';
import { Grid } from '@material-ui/core';
import ModifiableSnackbar from 'utils/ModifiableSnackbar';
import PostJob from '../PostJob';
import cls from './social-post-create.module.scss';
import { duser } from '@exampleAssets';
import { fileChangedHandlerArray } from 'utils/helpers';
import { useMutation } from '@apollo/client';
import { useState } from 'react';

const SocialPostCreate = props => {
  const [selection, setSelection] = useState(0);

  const [content, setContent] = useState('');
  const [imagePreviews, setImagePreviews] = useState([]);
  const [imageFiles, setImageFiles] = useState([]);

  const [jobAlert, setJobAlert] = useState('');
  const [postAlert, setPostAlert] = useState('');

  //gql
  const [createUpdate] = useMutation(CREATE_UPDATE, {
    refetchQueries: [{ query: GET_PERSONAL_FEED, variables: { limit: 50 } }],
  });

  const handleShare = async () => {
    if (content === '') return;

    const { data, error } = await createUpdate({ variables: { content: content, images: imageFiles } });
    if (data?.createUpdate) {
      setPostAlert('Success');
    } else if (error) {
      setPostAlert('Error');
      console.error(error);
    } else {
      console.error(data);
    }

    setContent('');
    setImagePreviews([]);
    setImageFiles([]);
  };

  //handlers
  function handleImageUpload(input) {
    if (imagePreviews.length >= 10) {
      setPostAlert('Max');
      return;
    }
    fileChangedHandlerArray(input, imagePreviews, setImagePreviews, imageFiles, setImageFiles);
  }

  const handleImageDelete = index => {
    setImagePreviews(imagePreviews.filter((el, idx) => idx !== index));
    setImageFiles(imageFiles.filter((el, idx) => idx !== index));
  };

  return (
    <UikWidget margin>
      <div className={cls.btns}>
        <UikButton icon={<Uikon className={cls.iconPost}>building</Uikon>} transparent onClick={() => setSelection(0)}>
          <span className={cls.actionDesc}>{'Yeni bir gönderi paylaş'}</span>
        </UikButton>

        <UikButton icon={<Uikon className={cls.iconCamera}>add</Uikon>} transparent onClick={() => setSelection(1)}>
          <span className={cls.actionDesc}> {'İş ilanı paylaş'}</span>
        </UikButton>
      </div>
      <UikDivider />
      {selection === 1 ? (
        <PostJob alert={jobAlert} setAlert={setJobAlert} setSelection={setSelection} handleJob={props.handleJob} />
      ) : (
        ''
      )}
      {selection === 0 && (
        <>
          <div className={cls.message}>
            <UikAvatar imgUrl={props.profilePhoto || duser} />
            <textarea
              value={content}
              placeholder={'Nereleri ziyaret ettin? Neler yapıyorsun? Bir paylaşım ile anlat!'}
              rows="2"
              id="commentArea"
              onChange={e => setContent(e.target.value)}
            />
          </div>
          <Grid container style={{ paddingLeft: 70, paddingRight: '20px' }}>
            {imagePreviews.map((a, index) => (
              <Grid key={index} item md={3} xs={7} style={{ padding: 5, position: 'relative' }}>
                <Grid
                  className={cls.photo}
                  style={{
                    backgroundImage: `url(${a})`,
                    opacity: '0.4',
                    width: '100%',
                  }}
                />

                <span
                  className={cls.cross}
                  style={{
                    cursor: 'pointer',

                    position: 'absolute',
                  }}
                  onClick={() => handleImageDelete(index)}
                >
                  <Uikon
                    style={{
                      transform: 'rotate(45deg)',
                    }}
                  >
                    add
                  </Uikon>
                </span>
              </Grid>
            ))}
          </Grid>
          <div className={cls.footer}>
            <>
              <div>
                <input
                  type="file"
                  name="avatar"
                  onChange={input => handleImageUpload(input)}
                  style={{ display: 'none' }}
                  id="contained-button-file"
                />
                <label htmlFor="contained-button-file">
                  <UikButton
                    variant="contained"
                    Component="span"
                    icon={<Uikon className={cls.iconPhoto}>camera</Uikon>}
                    transparent
                  >
                    {'Fotoğraf/Video'}
                  </UikButton>
                </label>
              </div>

              <UikButton
                icon={<Uikon className={cls.iconPhoto}>cloud_up</Uikon>}
                transparent
                onClick={() => handleShare()}
              >
                {'Paylaş'}
              </UikButton>
            </>
          </div>
        </>
      )}

      <ModifiableSnackbar condition={jobAlert === 'Success'} setter={setJobAlert} severity="success">
        İş İlanınız Yayına Alınmıştır!
      </ModifiableSnackbar>
      <ModifiableSnackbar condition={jobAlert === 'Error'} setter={setJobAlert} severity="error">
        İş İlanınız Maalesef Yayınlanamadı.
      </ModifiableSnackbar>
      <ModifiableSnackbar condition={postAlert === 'Success'} setter={setPostAlert} severity="success">
        Paylaşımınız Yayına Alınmıştır!
      </ModifiableSnackbar>
      <ModifiableSnackbar condition={postAlert === 'Error'} setter={setPostAlert} severity="error">
        Paylaşımınız Maalesef Yayınlanamadı.
      </ModifiableSnackbar>
      <ModifiableSnackbar condition={postAlert === 'Max'} setter={setPostAlert} severity="error">
        En fazla 10 fotoğraf yükleyebilirsiniz.
      </ModifiableSnackbar>
    </UikWidget>
  );
};

export default SocialPostCreate;
