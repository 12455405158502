import { gql } from '@apollo/client';

//-Auth
export const AUTHENTICATE_USER_MUTATION = gql`
  mutation authenticateUser($email: String!, $password: String!) {
    authenticate(email: $email, password: $password) {
      token
      user {
        id
      }
    }
  }
`;
export const FORGOT_PASSWORD_MUTATION = gql`
  mutation startForgotPassword($email: String!) {
    startForgotPassword(email: $email)
  }
`;
export const REGISTER_USER_MUTATION = gql`
  mutation register(
    $email: String!
    $password: String!
    $firstName: String!
    $lastName: String!
    $isAlumni: Boolean!
    $isMasters: Boolean!
    $identityNum: String!
    $dateOfBirth: String!
    $studentID: String
    $currLicense: String
    $pastLicenses: [String]
    $profilePhoto: Upload
    $phoneNumber: String
    $country: ID
    $city: ID
    $code: String
  ) {
    register(
      email: $email
      password: $password
      firstName: $firstName
      lastName: $lastName
      isAlumni: $isAlumni
      isMasters: $isMasters
      identityNum: $identityNum
      dateOfBirth: $dateOfBirth

      studentID: $studentID
      currLicense: $currLicense
      pastLicenses: $pastLicenses
      profilePhoto: $profilePhoto

      phoneNumber: $phoneNumber
      country: $country
      city: $city
      code: $code
    ) {
      user {
        id
      }
      token
    }
  }
`;

//-GroupDetail
export const JOIN_GROUP_MUTATION = gql`
  mutation joinGroup($groupId: ID!) {
    joinGroup(groupId: $groupId)
  }
`;
export const LEAVE_GROUP_MUTATION = gql`
  mutation leaveGroup($groupId: ID!) {
    leaveGroup(groupId: $groupId)
  }
`;
export const ACCEPT_USER_MUTATION = gql`
  mutation acceptMember($groupId: ID!, $userId: ID!) {
    acceptMember(groupId: $groupId, userId: $userId)
  }
`;
export const REJECT_USER_MUTATION = gql`
  mutation rejectMember($groupId: ID!, $userId: ID!) {
    rejectMember(groupId: $groupId, userId: $userId)
  }
`;
export const EDIT_GROUP_MUTATION = gql`
  mutation editGroup(
    $groupId: ID!
    $title: String
    $description: String
    $company: String
    $phoneNumber: String
    $website: String
    $profilePhoto: Upload
    $thumbnail: Upload
    $isPublic: Boolean
  ) {
    editGroup(
      groupId: $groupId
      title: $title
      description: $description
      company: $company
      phoneNumber: $phoneNumber
      website: $website
      profilePhoto: $profilePhoto
      thumbnail: $thumbnail
      isPublic: $isPublic
    ) {
      id
      title
      company
      phoneNumber
      profilePhoto
      thumbnail
      website
    }
  }
`;
export const CREATE_GROUP_MUTATION = gql`
  mutation createGroup(
    $title: String!
    $description: String
    $company: String
    $phoneNumber: String
    $website: String
    $profilePhoto: Upload
    $thumbnail: Upload
    $isPublic: Boolean
    $category: String
  ) {
    createGroup(
      title: $title
      description: $description
      company: $company
      phoneNumber: $phoneNumber
      website: $website
      profilePhoto: $profilePhoto
      thumbnail: $thumbnail
      isPublic: $isPublic
      category: $category
    )
  }
`;

//-Friends
export const SEND_FRIEND_REQUEST_MUTATION = gql`
  mutation sendFriendRequest($friendId: ID) {
    sendFriendRequest(friendId: $friendId)
  }
`;
export const REMOVE_FRIEND_MUTATION = gql`
  mutation removeFriend($friendId: ID) {
    removeFriend(friendId: $friendId)
  }
`;
export const APPROVE_FRIEND_REQUEST_MUTATION = gql`
  mutation approveFriendRequest($friendId: ID) {
    approveFriendRequest(friendId: $friendId)
  }
`;
export const REJECT_FRIEND_REQUEST_MUTATION = gql`
  mutation rejectFriendRequest($friendId: ID) {
    rejectFriendRequest(friendId: $friendId)
  }
`;

//-Jobs
export const CREATE_JOB_MUTATION = gql`
  mutation createJob(
    $applyWithEmail: String
    $applyWithWeb: String
    $city: ID
    $companyName: String!
    $country: ID
    $description: String
    $endDate: String
    $industry: String
    $level: String
    $logo: Upload
    $startDate: String
    $title: String!
    $workType: String
    $type: String
    $thumbnail: Upload
  ) {
    createJob(
      applyWithEmail: $applyWithEmail
      applyWithWeb: $applyWithWeb
      city: $city
      companyName: $companyName
      country: $country
      description: $description
      endDate: $endDate
      industry: $industry
      level: $level
      logo: $logo
      startDate: $startDate
      title: $title
      workType: $workType
      type: $type
      thumbnail: $thumbnail
    )
  }
`;

//-User
export const EDIT_MY_SETTINGS_MUTATION = gql`
  mutation mySettings(
    $firstName: String
    $lastName: String
    $profilePhoto: Upload
    $thumbnail: Upload
    $biography: String
    $website: String
    $company: String
    $jobTitle: String
    $startDate: String
    $endDate: String
    $isPresent: Boolean
    $companyLogo: Upload
    $phoneNumber: String
  ) {
    mySettings(
      firstName: $firstName
      lastName: $lastName
      profilePhoto: $profilePhoto
      thumbnail: $thumbnail
      biography: $biography
      website: $website
      company: $company
      jobTitle: $jobTitle
      startDate: $startDate
      endDate: $endDate
      isPresent: $isPresent
      companyLogo: $companyLogo
      phoneNumber: $phoneNumber
    ) {
      id
      firstName
      lastName
      phoneNumber
      companyLogo
      career {
        company
        jobTitle
        startDate
        endDate
        isPresent
      }

      profilePhoto
      website
      socialLinkedIn
    }
  }
`;
//Feed
export const CREATE_UPDATE = gql`
  mutation createUpdate($content: String, $images: [Upload], $parentUpdateId: ID) {
    createUpdate(content: $content, images: $images, parentUpdateId: $parentUpdateId) {
      id
    }
  }
`;

export const DELETE_UPDATE = gql`
  mutation deleteUpdate($id: ID!) {
    deleteUpdate(id: $id)
  }
`;

export const LIKE_UPDATE = gql`
  mutation likeUpdate($id: ID!) {
    likeUpdate(id: $id) {
      id
      likes {
        id
      }
    }
  }
`;

export const UNLIKE_UPDATE = gql`
  mutation unlikeUpdate($id: ID!) {
    unlikeUpdate(id: $id) {
      id
      likes {
        id
      }
    }
  }
`;

export const EDIT_UPDATE = gql`
  mutation editUpdate($id: ID!, $content: String) {
    editUpdate(id: $id, content: $content) {
      id
      content
    }
  }
`;
