import { Field, Form, Formik } from 'formik';
import { Grid, Snackbar } from '@material-ui/core';
import {
  UikAvatar,
  UikButton,
  UikCheckbox,
  UikContentTitle,
  UikDivider,
  UikFormInputGroup,
  UikInput,
  UikWidgetContent,
  Uikon,
} from '@uik';
import { fFrance2, fGermany, fTurkey } from '@exampleAssets';

import { ALL_JOBS_QUERY } from '../../../../graphql/queries';
import { CREATE_JOB_MUTATION } from '../../../../graphql/mutations';
import MuiAlert from '@material-ui/lab/Alert';
import React from 'react';
import UikSelect from 'components/UikSelect';
import cls from './buildings-user.module.scss';
import { fileChangedHandler } from 'utils/helpers';
import { postJobFormYup } from 'utils/yupSchemas';
import { useMutation } from '@apollo/client';

const PostJob = props => {
  const { setSelection } = props;
  const { alert, setAlert } = props;
  const [selectedCountry, setSelectedCountry] = React.useState('Turkey');
  const [emailCheck, setEmailCheck] = React.useState(false);
  const [websiteCheck, setWebsiteCheck] = React.useState(false);
  const [industry, setIndustry] = React.useState('');
  const [experience, setExperience] = React.useState('');
  const [workType, setWorkType] = React.useState('');
  const [city, setCity] = React.useState('');
  const [country, setCountry] = React.useState('Turkey');
  const [logo, setLogo] = React.useState('');
  const [thumbnail, setThumbnail] = React.useState('');
  const [logoFile, setLogoFile] = React.useState('');
  const [thumbnailFile, setThumbnailFile] = React.useState('');

  //gql
  const [createJob, { data, error }] = useMutation(CREATE_JOB_MUTATION, {
    refetchQueries: [{ query: ALL_JOBS_QUERY, variables: { filterTitle: '' } }],
  });

  //const datas
  const turkeyCities = [
    { label: 'Ankara', value: 'Ankara' },
    { label: 'İstanbul', value: 'İstanbul' },
    { label: 'İzmir', value: 'İzmir' },
    { label: 'Bursa', value: 'Bursa' },
  ];
  const germanyCities = [
    { label: 'Berlin', value: 'Berlin' },
    { label: 'Munich', value: 'Munich' },
    { label: 'Hamburg', value: 'Hamburg' },
    { label: 'Frankfurt', value: 'Frankfurt' },
  ];
  const franceCities = [
    { label: 'Paris', value: 'Paris' },
    { label: 'Nice', value: 'Nice' },
    { label: 'Lyon', value: 'Lyon' },
    { label: 'Lille', value: 'Lille' },
  ];

  function handleSnackbarClose(event, reason) {
    if (reason === 'clickaway') return;
    setAlert('');
  }
  return (
    <React.Fragment>
      <Formik
        initialValues={{
          company: '',
          title: '',
          startDate: '',
          endDate: '',
          description: '',
          byEmail: false,
          email: '',
          byWebsite: false,
          website: '',
        }}
        validationSchema={postJobFormYup}
        onSubmit={(values, { setSubmitting }) => {
          createJob({
            variables: {
              applyWithEmail: emailCheck ? values.email : '',
              applyWithWeb: websiteCheck ? values.website : '',
              //city: city,
              companyName: values.company,
              //country: country,
              description: values.description,
              endDate: values.endDate,
              industry: industry,
              level: experience,
              logo: logoFile,
              startDate: values.startDate,
              title: values.title,
              workType: workType,
              type: '',
              // thumbnail: thumbnailFile,
            },
          })
            .then(response => {
              setAlert(response?.data?.createJob ? 'Success' : 'Error');
              setSubmitting(false);
              setSelection(0);
            })
            .catch(e => {
              console.log(e);
              setAlert('Error');
              setSubmitting(false);
            });
        }}
      >
        {formik => (
          <Form>
            <UikWidgetContent>
              <Grid container style={{ justifyContent: 'center' }}>
                <Grid item md={6}>
                  <h3>{'İş İlanı Yayınla'}</h3>
                  <p>{'Ne tür bir iş yayınlamak isterdiniz?'}</p>
                </Grid>

                <Grid item md={6}>
                  <input
                    type="file"
                    name="avatar"
                    onChange={input => fileChangedHandler(input, setLogo, setLogoFile)}
                    style={{ display: 'none' }}
                    id="company-logo"
                  />
                  <label htmlFor="company-logo">
                    {
                      <UikAvatar
                        style={{ cursor: 'pointer', justifyContent: 'center' }}
                        imgUrl={logo || null}
                        avatarPlaceholder={{
                          content: <Uikon className={cls.iconCamera}>camera</Uikon>,
                        }}
                        size="jumbo"
                      />
                    }
                  </label>
                </Grid>
              </Grid>
            </UikWidgetContent>
            <UikDivider />

            <UikWidgetContent>
              <UikFormInputGroup>
                <UikFormInputGroup direction="horizontal">
                  <Field errorMessage={formik.errors.company} as={UikInput} label={'FİRMA İSMİ'} name="company" />
                  <Field errorMessage={formik.errors.title} as={UikInput} label={'ÜNVAN'} name="title" />
                </UikFormInputGroup>
                <UikFormInputGroup horizontal>
                  <UikSelect
                    selection={setWorkType}
                    label={'İSTİHDAM TİPİ'}
                    name="workType"
                    options={[
                      {
                        label: 'Tam Zamanlı',
                        value: 'Tam Zamanlı',
                      },
                      {
                        label: 'Yarı Zamanlı',
                        value: 'Yarı Zamanlı',
                      },
                      {
                        label: 'Sözleşmeli',
                        value: 'Sözleşmeli',
                      },
                      {
                        label: 'Geçici',
                        value: 'Geçici',
                      },
                      {
                        label: 'Gönüllü',
                        value: 'Gönüllü',
                      },
                      {
                        label: 'Staj',
                        value: 'Staj',
                      },
                    ]}
                    placeholder={'Tip'}
                    position="bottomRight"
                  >
                    <option value="asdasd">asdasd</option>
                  </UikSelect>
                  <UikSelect
                    selection={setExperience}
                    label={'Deneyİm'}
                    name="experience"
                    options={[
                      {
                        label: 'Staj',
                        value: 'Staj',
                      },
                      {
                        label: 'Başlangıç Seviyesi',
                        value: 'Başlangıç Seviyesi',
                      },
                      {
                        label: 'Associate',
                        value: 'Associate',
                      },
                      {
                        label: 'Orta Düzey',
                        value: 'Orta Düzey',
                      },
                      {
                        label: 'Müdür',
                        value: 'Müdür',
                      },
                      {
                        label: 'Yönetici',
                        value: 'Yönetici',
                      },
                      {
                        label: 'Belirsiz',
                        value: 'Belirsiz',
                      },
                    ]}
                    placeholder={'Deneyim'}
                    position="bottomRight"
                  />
                </UikFormInputGroup>
                <UikSelect
                  selection={setIndustry}
                  label={'Endüstri'}
                  name="industry"
                  options={[
                    {
                      label: 'Pazarlama',
                      value: 'Pazarlama',
                    },
                    {
                      label: 'Sosyal Servisler',
                      value: 'Sosyal Servisler',
                    },
                    {
                      label: 'Eğitim ve Danışmanlık',
                      value: 'Eğitim ve Danışmanlık',
                    },
                    {
                      label: 'Mühendislik',
                      value: 'Mühendislik',
                    },
                    {
                      label: 'Ürün Yönetimi',
                      value: 'Ürün Yönetimi',
                    },
                    {
                      label: 'Satış',
                      value: 'Satış',
                    },
                    {
                      label: 'Muhasebe',
                      value: 'Muhasebe',
                    },
                    {
                      label: 'Finans',
                      value: 'Finans',
                    },
                    {
                      label: 'Yönetim',
                      value: 'Yönetim',
                    },
                    {
                      label: 'Sanat ve Tasarım',
                      value: 'Sanat ve Tasarım',
                    },
                    {
                      label: 'İş Geliştirme',
                      value: 'İş Geliştirme',
                    },
                    {
                      label: 'İK',
                      value: 'HR',
                    },
                    {
                      label: 'IT',
                      value: 'IT',
                    },
                  ]}
                  placeholder={'Düzey'}
                  position="bottomRight"
                />
                <UikFormInputGroup horizontal>
                  <UikSelect
                    selection={setCountry}
                    defaultValue={['Turkey']}
                    label={'Ülke'}
                    options={[
                      {
                        label: (
                          <span>
                            <img alt="turkey" width="20px" height="14px" className={cls.selectFlag} src={fTurkey} />
                            {'Türkiye'}
                          </span>
                        ),
                        value: 'Turkey',
                      },
                      {
                        label: (
                          <span>
                            <img alt="france" width="20px" height="14px" className={cls.selectFlag} src={fFrance2} />
                            {'France'}
                          </span>
                        ),
                        value: 'France',
                      },

                      {
                        label: (
                          <span>
                            <img alt="germany" width="20px" height="14px" className={cls.selectFlag} src={fGermany} />
                            {'Germany'}
                          </span>
                        ),
                        value: 'Germany',
                      },
                    ]}
                    placeholder="Türkiye"
                    position="bottomRight"
                  />

                  <UikSelect
                    selection={setCity}
                    label={'Şehİr'}
                    options={
                      country === 'Turkey'
                        ? turkeyCities
                        : country === 'France'
                        ? franceCities
                        : country === 'Germany'
                        ? germanyCities
                        : ''
                    }
                    placeholder="Şehir"
                    position="bottomRight"
                  />
                </UikFormInputGroup>

                <UikFormInputGroup direction="horizontal">
                  <Field as={UikInput} type="date" label={'Başlangıç Tarİhİ'} name="startDate" />
                  <Field as={UikInput} type="date" label={'Bİtİş Tarİhİ'} name="endDate" />
                </UikFormInputGroup>
                <UikFormInputGroup>
                  <UikContentTitle style={{ marginTop: 4 }}>{'Detaylı Açıklama (750 karakter)'}</UikContentTitle>
                  <div className={cls.message}>
                    <Field
                      errorMessage={formik.errors.description}
                      maxLength="750"
                      as="textarea"
                      name="description"
                      style={{ minHeight: '150px' }}
                      rows="2"
                      id="commentArea"
                    />
                  </div>
                </UikFormInputGroup>
                <UikContentTitle style={{ marginTop: 4 }}>{'Başvuruları nasıl toplamak istersiniz?'}</UikContentTitle>

                <UikFormInputGroup direction="horizontal">
                  <UikFormInputGroup>
                    <Field
                      as={UikCheckbox}
                      onChange={() => setEmailCheck(!emailCheck)}
                      defaultValue={false}
                      label={'Email üzerinden başvuru al'}
                      name="byEmail"
                    />
                    <Field
                      as={UikInput}
                      errorMessage={formik.errors.email}
                      disabled={!emailCheck}
                      name="email"
                      type="email"
                      placeholder="email@alumni.com"
                    />
                  </UikFormInputGroup>
                  <UikFormInputGroup>
                    <Field
                      as={UikCheckbox}
                      onChange={() => setWebsiteCheck(!websiteCheck)}
                      defaultValue={false}
                      label={'Link üzerinden başvuru al'}
                      name="byWebSite"
                    />
                    <Field
                      as={UikInput}
                      errorMessage={formik.errors.website}
                      disabled={!websiteCheck}
                      name="website"
                      type="website"
                      placeholder="alumni.com"
                    />
                  </UikFormInputGroup>
                </UikFormInputGroup>
              </UikFormInputGroup>
            </UikWidgetContent>
            <UikWidgetContent style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <UikButton success type="submit" disabled={formik.isSubmitting}>
                {'Yayınla'}
              </UikButton>
            </UikWidgetContent>
          </Form>
        )}
      </Formik>
      <Snackbar open={alert === 'Success'} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <MuiAlert onClose={handleSnackbarClose} severity="success">
          İlanınız Yayına Alınmıştır!
        </MuiAlert>
      </Snackbar>
      <Snackbar open={alert === 'Error'} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <MuiAlert onClose={handleSnackbarClose} severity="error">
          İlanınız Maalesef Yayınlanamadı.
        </MuiAlert>
      </Snackbar>
    </React.Fragment>
  );
};

export default PostJob;
