import { LIKE_UPDATE, UNLIKE_UPDATE } from 'graphql/mutations';
import { UikButton, Uikon } from '@uik';

import { GET_UPDATE_WITH_LIKES_AND_COMMENTS } from 'graphql/queries';
import React from 'react';
import cls from './social-post-actions.module.scss';
import { update } from 'lodash';
import { useMutation } from '@apollo/client';

const SocialPostActions = ({ updateId, myId, myLike, likes, commentCount }) => {
  const likeCount = likes?.length;
  const haveILiked = !!myLike;
  //gql
  const [likeUpdate] = useMutation(LIKE_UPDATE, {
    variables: { id: updateId },
    // optimisticResponse: {
    //   likeUpdate: {
    //     id: updateId,
    //     __typename: 'Update',
    //     likes: [
    //       ...likes,
    //       {
    //         user: {
    //           id: myId,
    //         },
    //       },
    //     ],
    //   },
    // },
    refetchQueries: [{ query: GET_UPDATE_WITH_LIKES_AND_COMMENTS, variables: { id: updateId } }],
  });
  const [unlikeUpdate] = useMutation(UNLIKE_UPDATE, {
    variables: { id: updateId },
    // optimisticResponse: {
    //   likeUpdate: {
    //     __typename: 'Update',
    //     likes: [...likes.filter(like => like.id !== myLike.id)],
    //   },
    // },
    refetchQueries: [{ query: GET_UPDATE_WITH_LIKES_AND_COMMENTS, variables: { id: updateId } }],
  });

  //handlers
  const handleClick = async () => {
    if (!haveILiked) {
      const { data, error } = await likeUpdate();
      if (error) console.error(error);
    } else {
      const { data, error } = await unlikeUpdate();
      if (error) console.error(error);
    }
  };

  //jsx
  return (
    <div className={cls.btns}>
      <UikButton
        icon={
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span style={{ color: `${haveILiked ? '#E6492D' : '#6B6C6F'}` }}>
              {likeCount && likeCount !== 0 ? likeCount : ''}
            </span>
            <Uikon style={{ color: `${haveILiked ? '#E6492D' : '#6B6C6F'}` }}>love</Uikon>
          </div>
        }
        transparent
        onClick={() => handleClick()}
      >
        {'Beğeni'}
      </UikButton>

      <UikButton
        icon={
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {commentCount && commentCount !== 0 ? commentCount : ''}
            <Uikon>message_2</Uikon>
          </div>
        }
        transparent
      >
        Yorum
      </UikButton>
      {/* <UikButton className={cls.hide} icon={<Uikon>send_round</Uikon>} transparent>
        {'Paylaş'}
      </UikButton> */}
    </div>
  );
};

export default SocialPostActions;
