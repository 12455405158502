import React from "react";
import classnames from "classnames";
import cls from "./timeline.module.scss";

function TimeLine(props) {
  const tempData = [
    {
      date: "Aralık 15, 2019",
      job: "Yazılım Mühendisi",
      companyName: "Univerlist"
    },
    {
      date: "April 13, 2017",
      job: "Araşturma Görevlisi",
      companyName: "Yaşar Üniversitesi"
    },
    {
      date: "Haziran 1, 2014",
      job: "Öğrenci",
      companyName: "Yaşar Üniversitesi"
    }
  ];
  return (
    <div className={cls.timeline}>
      {tempData.map((datum, index) => (
        <div
          className={classnames(
            cls.container,
            index % 2 === 0 ? cls.left : cls.right
          )}
        >
          <div className={cls.content}>
            <h2>{datum.date}</h2>
            <p>{datum.job + " " + datum.companyName}</p>
          </div>
        </div>
      ))}
    </div>
  );
}

export default TimeLine;
