export default [
  {
    exact: true,
    icon: '🗂️',
    text: 'Panel',
    to: '/dashboard',
  },
  {
    icon: '👥',
    text: 'Kullanıcılar',
    to: '/user-info',
  },
];
