/* components */

import * as React from 'react';

import { UikAvatar, UikAvatarProps, UikButton, UikTabContainer, UikTabItem, UikWidget } from '@uik';

import FormikModal from 'components/FormikModal';
import classnames from 'classnames';
import cls from './social-header.module.scss';

// @flow




type UikSocialHeaderProps = {
  className?: ?String,
  Component?: React.ElementType,
  imgUrl?: String,
  buttonText: String,
  avatar: UikAvatarProps,
  button: UikButton,
  name: String,
  tabs: Object,
};

const UikSocialHeader = ({
  className,
  Component,
  imgUrl,
  avatar,
  handleClick,
  handleButtonClick,
  buttonText,
  button,
  name,
  tabs,
  canEdit,
  coverImg,
  formikComponent,
  oldValues,
  editFormTitle,
  ...rest
}: UikSocialHeaderProps) => {
  const [text, setText] = React.useState('Hakkında'); //default selected
  const [openModal, setOpenModal] = React.useState(false);

  const handleClickText = text => {
    setText(text);
    handleClick(text);
  };

  return (
    <Component className={classnames(cls.wrapper, className)} {...rest}>
      <div
        className={cls.cover}
        style={{
          backgroundImage: `url(${imgUrl})`,
        }}
      >
        {canEdit ? (
          <>
            <UikButton onClick={() => setOpenModal(true)} className={cls.btnUpdateCover} dark Component="span">
              Bilgileri Düzenle
            </UikButton>
            <FormikModal
              open={openModal}
              setOpen={setOpenModal}
              formikComponent={formikComponent}
              oldValues={oldValues}
              title={editFormTitle}
            />
          </>
        ) : null}

        <UikAvatar className={cls.avatar} size="jumbo" {...avatar} />
        <h1 className={cls.name}>{name}</h1>
      </div>
      <div className={cls.content}>
        <div className={cls.tabWrapper}>
          <UikTabContainer>
            {(tabs
              ? tabs
              : [
                  {
                    text: 'Özgeçmiş',
                    extra: null,
                  },
                  {
                    text: 'Hakkında',
                    extra: null,
                  },
                  {
                    text: 'Arkadaşlar',
                    extra: null,
                  },
                  {
                    text: 'Gruplar',
                    extra: null,
                  },
                ]
            ).map(item => (
              <UikTabItem
                key={item.text}
                onClick={() => handleClickText(item.text)}
                className={item.text === text && 'active'}
                {...item}
              />
            ))}
            {button ? button : null}
          </UikTabContainer>
        </div>
      </div>
    </Component>
  );
};

UikSocialHeader.defaultProps = {
  className: null,
  Component: UikWidget,
  handleClick: null,
  handleButtonClick: null,
  imgUrl: null,
};

export default UikSocialHeader;
