import React from 'react';

import { Field, Form, Formik } from 'formik';
import {
  UikAvatar,
  UikButton,
  UikCheckbox,
  UikContentTitle,
  UikFormInputGroup,
  UikInput,
  UikWidgetContent,
  Uikon,
} from '@uik';

import UikSelect from 'components/UikSelect';
import { ALL_GROUPS_QUERY } from '../../graphql/queries';
import { CREATE_GROUP_MUTATION } from '../../graphql/mutations';
import cls from './forms.module.scss';
import { editGroupFormYup } from 'utils/yupSchemas';
import { fileChangedHandler } from 'utils/helpers';
import { useMutation } from '@apollo/client';
import { useState } from 'react';

const groupCategories = [
  { label: 'Bilişim', value: 'Bilişim' },
  { label: 'Eğitim', value: 'Eğitim' },
  { label: 'Sağlık', value: 'Sağlık' },
  { label: 'Turizm', value: 'Turizm' },
];

export function CreateGroupFormik({ setOpen }) {
  const [pp, setPp] = useState('');
  const [ppFile, setPpFile] = useState('');
  const [thumbnail, setThumbnail] = useState('');
  const [thumbnailFile, setThumbnailFile] = useState('');
  const [category, setCategory] = React.useState('');

  const [createGroup] = useMutation(CREATE_GROUP_MUTATION, {
    refetchQueries: [{ query: ALL_GROUPS_QUERY, variables: { filterTitle: '' } }],
  });

  const initialValues = {
    title: '',
    company: '',
    phoneNumber: '',
    website: '',
    profilePhoto: '',
    thumbnail: '',
    isPublic: true,
    category: '',
  };

  const submitFunction = async (values, { setSubmitting }) => {
    const { error, data } = await createGroup({
      variables: {
        title: values.title || '',
        company: values.company || '',
        phoneNumber: values.telephone || '',
        website: values.website || '',
        profilePhoto: ppFile || '',
        thumbnail: thumbnailFile || '',
        description: values.description || '',
        isPublic: values.isPublic,
        category: category || '',
      },
    });
    if (error) {
      console.log(error);
      alert('Lütfen tekrar deneyin!');
    } else {
      setOpen(false);
    }
    setSubmitting(false);
  };

  return (
    <Formik initialValues={initialValues} validationSchema={editGroupFormYup} onSubmit={submitFunction}>
      {formik => (
        <Form>
          <UikWidgetContent>
            <UikFormInputGroup>
              <UikFormInputGroup direction="horizontal">
                <input
                  type="file"
                  name="avatar"
                  onChange={input => fileChangedHandler(input, setPp, setPpFile)}
                  style={{ display: 'none' }}
                  id="profilePhoto"
                />
                <label htmlFor="profilePhoto">
                  {
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      <span className="uik-content-title__wrapper">Profİl Fotoğrafı</span>
                      <UikAvatar
                        style={{ cursor: 'pointer', justifyContent: 'center' }}
                        imgUrl={pp || null}
                        avatarPlaceholder={{
                          content: <Uikon className={cls.iconCamera}>camera</Uikon>,
                        }}
                        size="jumbo"
                      />
                    </div>
                  }
                </label>
                <input
                  type="file"
                  name="avatar"
                  onChange={input => fileChangedHandler(input, setThumbnail, setThumbnailFile)}
                  style={{ display: 'none' }}
                  id="thumbnail"
                />
                <label htmlFor="thumbnail">
                  {
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      <span className="uik-content-title__wrapper">Kapak Fotoğrafı</span>
                      <UikAvatar
                        style={{ cursor: 'pointer', justifyContent: 'center' }}
                        imgUrl={thumbnail || null}
                        avatarPlaceholder={{
                          content: <Uikon className={cls.iconCamera}>camera</Uikon>,
                        }}
                        size="jumbo"
                      />
                    </div>
                  }
                </label>
              </UikFormInputGroup>
              <UikFormInputGroup direction="horizontal">
                <Field errorMessage={formik.errors.title} as={UikInput} label={'Grup Adı'} name="title" />
                <Field errorMessage={formik.errors.company} as={UikInput} label={'Şirket/Okul Adı'} name="company" />
              </UikFormInputGroup>
              <UikFormInputGroup direction="horizontal">
                <Field
                  errorMessage={formik.errors.telephone}
                  as={UikInput}
                  label={'Telefon Numarası'}
                  name="telephone"
                />
                <Field errorMessage={formik.errors.website} as={UikInput} label={'Websitesi'} name="website" />
              </UikFormInputGroup>
              <UikFormInputGroup direction="horizontal">
                <UikSelect
                  selection={setCategory}
                  label={'Grup Kategorisi'}
                  options={groupCategories}
                  placeholder="kategori"
                  position="bottomRight"
                />
              </UikFormInputGroup>
              <UikFormInputGroup>
                <UikContentTitle style={{ marginTop: 4 }}>{'Detaylı Açıklama (500 karakter)'}</UikContentTitle>
                <div className={cls.message}>
                  <Field
                    errorMessage={formik.errors.description}
                    maxLength="500"
                    as="textarea"
                    name="description"
                    style={{ minHeight: '150px' }}
                    rows="2"
                    id="commentArea"
                  />
                </div>
              </UikFormInputGroup>
              <UikFormInputGroup direction="horizontal">
                <Field as={UikCheckbox} label={'Grup Herkese Açık Mı?'} name="isPublic" />
              </UikFormInputGroup>
            </UikFormInputGroup>
          </UikWidgetContent>
          <UikWidgetContent style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <UikButton success type="submit" disabled={formik.isSubmitting}>
              Kaydet
            </UikButton>
          </UikWidgetContent>
        </Form>
      )}
    </Formik>
  );
}
