import { Link } from 'react-router-dom';
import { Uikon } from '@uik';
import { duser as defaultProfilePhoto } from '@exampleAssets';
import { getClickableLink } from 'utils/helpers';

export default function getAbout(data, isAdmin) {
  let arr = [
    !data?.getGroupProfile?.company
      ? null
      : {
          avatarPlaceholder: {
            color: 'lightblue',
            content: data?.getGroupProfile?.company
              ?.split(' ')
              ?.map((word, idx) => (idx < 2 ? word.charAt(0) : ''))
              ?.join(''),
          },
          name: <i>{data?.getGroupProfile?.company}</i>,
        },
    {
      imgUrl: defaultProfilePhoto,
      name: (
        <Link to={`/user/${data?.getGroupProfile?.admin?.id}`}>
          <span>
            <i>{data?.getGroupProfile?.admin?.firstName + ' ' + data?.getGroupProfile?.admin?.lastName}</i>
          </span>
        </Link>
      ),
    },
    !data?.getGroupProfile?.phoneNumber
      ? null
      : {
          avatarPlaceholder: {
            content: <Uikon>phone</Uikon>,
          },
          name: data?.getGroupProfile?.phoneNumber,
        },

    !data?.getGroupProfile?.website
      ? null
      : {
          avatarPlaceholder: {
            content: <Uikon>profile_round</Uikon>,
          },
          name: (
            <a href={getClickableLink(data?.getGroupProfile?.website)} target="_blank" rel="noopener noreferrer">
              {data?.getGroupProfile?.website}
            </a>
          ),
        },
  ];

  const addDetail = !isAdmin
    ? null
    : {
        avatarPlaceholder: {
          content: <Uikon>edit</Uikon>,
        },
        name: <i>Grubunuzu Detaylandırın</i>,
      };

  const canAddDetail = arr.filter(el => el !== null).length < arr.length;
  if (canAddDetail) arr.push(addDetail);
  return arr.filter(el => el !== null);
}
