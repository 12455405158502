/* eslint-disable max-len */

import * as React from 'react';

import type { UikSelectOptionType, UikSelectOptionValueType } from './flowTypes';

import { Translation } from 'react-i18next';
import { UikButton } from '@uik';
import classnames from 'classnames';
import cls from './select.module.scss';
import get from 'lodash/get';

// @flow




// cls



type OptionListProps = {
  selected: Array<UikSelectOptionType>,
  options: Array<UikSelectOptionType>,
  excluded?: Array<UikSelectOptionValueType>,
  optionClick: Function,
  position?: 'topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight',
  optionProps?: Object,
};

class OptionList extends React.Component<OptionListProps> {
  static defaultProps = {
    excluded: [],
    position: 'bottomLeft',
    optionProps: {},
  };

  renderOptionWrapper = ({ label }, t) => (
    <div className={cls.label}>
      {/* Countries are span field so couldnt translate */}
      {get(label, 'type', '') === 'span' ? label : t(`${label}`)}
    </div>
  );

  renderOptions(t) {
    const {
      options,
      optionClick,
      selected,
      excluded,
      optionProps: { className: optionClassName, ...optionPropsRest },
    } = this.props;

    const filteredOptions = options.filter(option => {
      if (excluded.indexOf(option.value) > -1) {
        return false;
      }
      return true;
    });

    return filteredOptions.map(i => {
      const indexIsSelected = selected.find(k => i.value === k.value);
      const onClick = key => () => {
        optionClick(key);
      };
      return (
        <UikButton
          key={i.value}
          className={classnames(cls.option, optionClassName)}
          onClick={onClick(i)}
          type="UikButton"
          {...optionPropsRest}
        >
          <div className={cls.optionContent}>
            {this.renderOptionWrapper(i, t)}
            {indexIsSelected ? <div className={cls.check} /> : null}
          </div>
        </UikButton>
      );
    });
  }

  render() {
    const { position } = this.props;
    return (
      <div
        className={classnames(cls.optionListWrapper, {
          [cls[position]]: position,
        })}
      >
        <Translation>{(t, { i18n }) => <div className={cls.optionList}>{this.renderOptions(t)}</div>}</Translation>
      </div>
    );
  }
}

export default OptionList;
