import 'react-image-lightbox/style.css';

import { Brightness1 } from '@material-ui/icons';
import Lightbox from 'react-image-lightbox';
import React from 'react';
import { Uikon } from '@uik';
import cls from './social-post-image-group.module.scss';
import get from 'lodash/get';

const SocialPostImageGroup = props => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [photoIndex, setPhotoIndex] = React.useState(0);
  const images = get(props, 'images', []);

  const handleClickImg = index => {
    setIsOpen(true);
    setPhotoIndex(index);
  };

  return (
    <React.Fragment>
      <div className={cls.wrapper}>
        {images.slice(0, 3).map((img, index) => (
          <div
            className={images.length === 1 ? cls.singlePhoto : cls.multiPhoto}
            key={index}
            style={{
              backgroundImage: `${
                index === 2 && images.length > 3
                  ? `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${img})`
                  : `url(${img})`
              }`,
              cursor: 'pointer',
            }}
            onClick={() => handleClickImg(index)}
          >
            {index === 2 && (
              <div className={cls.morePhoto}>
                <Uikon
                  style={{
                    fontSize: '20px',
                  }}
                >
                  add
                </Uikon>
                {` ${images.length - 3}`}
              </div>
            )}
          </div>
        ))}
      </div>
      {isOpen && (
        <Lightbox
          mainSrc={images[photoIndex]}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() => setPhotoIndex((photoIndex + images.length - 1) % images.length)}
          onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % images.length)}
        />
      )}
    </React.Fragment>
  );
};

export default SocialPostImageGroup;
