import * as React from 'react';
import * as images from '@exampleAssets';

import { UikAvatar, UikButton } from '@uik';

import cls from './social-stories.module.scss';
import { useTranslation } from 'react-i18next';

function SocialStories() {
  const { t, i18n } = useTranslation();
  return (
    <div className={cls.wrapper}>
      <div className={cls.content} style={{ width: '100%', paddingRight: 0 }}>
        <div
          className={cls.story}
          style={{
            height: '220px',
            width: '187px',
            backgroundImage: `url("${images.s01}")`,
          }}
        >
          <div className={cls.avatar}>
            <UikAvatar imgUrl={images.a02} />
            <span className={cls.name}>Ayşe</span>
            <span className={cls.name}>Univerlist</span>
          </div>
          <div className={cls.button}>
            <UikButton Component="span"> {'İletişime Geç'}</UikButton>
          </div>
        </div>
        <div
          className={cls.story}
          style={{
            height: '220px',
            width: '187px',
            backgroundImage: `url("${images.s07}")`,
          }}
        >
          <div className={cls.avatar}>
            <UikAvatar imgUrl={images.a19} />
            <span className={cls.name}>Ahmet</span>
            <span className={cls.name}>Havelsan</span>
          </div>
          <div className={cls.button}>
            <UikButton Component="span">{'İletişime Geç'}</UikButton>
          </div>
        </div>
        <div
          className={cls.story}
          style={{
            height: '220px',
            width: '187px',
            backgroundImage: `url("${images.s06}")`,
          }}
        >
          <div className={cls.avatar}>
            <UikAvatar imgUrl={images.a25} />
            <span className={cls.name}>Deniz</span>
            <span className={cls.name}>Alumuni</span>
          </div>
          <div className={cls.button}>
            <UikButton Component="span"> {'İletişime Geç'}</UikButton>
          </div>
        </div>
        <div
          className={cls.story}
          style={{
            height: '220px',
            width: '187px',
            backgroundImage: `url("${images.s02}")`,
          }}
        >
          <div className={cls.avatar}>
            <UikAvatar imgUrl={images.a14} />
            <span className={cls.name}>Cenk</span>
            <span className={cls.name}>Aselsan</span>
          </div>
          <div className={cls.button}>
            <UikButton Component="span"> {'İletişime Geç'}</UikButton>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SocialStories;
